import { makeStyles } from '@material-ui/styles';
import headerBackground from '../assets/img/landing/headerBackground.jpg';
import headerBackgroundDesktop from 'src/assets/img/landing/headerBackgroundDesktop.jpeg';
import tncMentee from 'src/assets/img/tnc_mentee.jpg';

export const useStyles = makeStyles((theme) => ({
  header: {
    background: `url(${headerBackground})`,
    backgroundSize: 'cover',
    width: '100%',
  },
  headerDesktop: {
    background: `url(${headerBackgroundDesktop})`,
    backgroundSize: 'cover',
    width: '100%',
    // minHeight: 713,
    paddingLeft: 133.3,
  },
  layer: {
    background: 'rgba(29, 45, 94, 0.7)',
    zIndex: -1,
  },
  headerMentoring: {
    backgroundColor: '#FACD11',
  },
  card: {
    width: '193px',
    height: '211px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
    marginRight: '15px',
    border: '1px solid #5BC0EB',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  cardLong: {
    width: '193px',
    height: '246px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
    marginRight: '15px',
    border: '1px solid #5BC0EB',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  cardNoBorder: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 16px rgba(29, 45, 94, 0.08)',
    borderRadius: '16px',
    padding: '32px 28px',
    width: '273px',
    position: 'relative',
  },
  container1280: {
    maxWidth: '1280px',
    margin: 'auto',
  },
  image: {
    width: '100%',
    borderRadius: '6px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.09))',
    objectFit: 'contain',
  },
  containerVideo: {
    width: '295px',
    height: '250px',
    overflowY: '-moz-hidden-unscrollable',
    marginRight: '16px',
    borderRadius: '8px',
    position: 'relative',
  },
  video: {
    width: '295px',
    height: '100%',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.09))',
    objectFit: 'cover',
  },
  videoTitle: {
    color: '#fff',
    position: 'absolute',
    bottom: 22,
    left: 24,
  },
  mediaEvent: {
    width: '214px',
    height: '129px',
    borderRadius: '8px 8px 0 0',
    '@media (min-width: 1280px)': {
      width: '428px',
      height: '245px',
      borderRadius: '16px 16px 0px 0px',
    },
  },
  contentEvent: {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    width: '214px',
    '@media (min-width: 1280px)': {
      width: '100%',
      padding: '28px 21.3px !important',
    },
  },
  backgroundTnCMentee: {
    background: `url(${tncMentee})`,
    backgroundSize: 'cover',
    width: '100%',
  },
}));
