import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import firebase from 'firebase/app';
import { useLocation, useNavigate } from 'react-router';
import { Alert, Box, Container, Grid, Typography } from '@material-ui/core';
import reactGA from 'react-ga';
import { logout, setNavbarTitle } from 'src/redux/actions';
import PasswordInput from 'src/components/forms/PasswordInput';
import CustomButton from 'src/components/button';
import MainLayout from 'src/components/MainLayout';
import { FormProvider, useForm } from 'react-hook-form';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});

const Icon = () => (
  <svg
    width='96'
    height='95'
    viewBox='0 0 96 95'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='48' cy='47.5' r='47.5' fill='#5BC0EB' fill-opacity='0.08' />
    <circle
      cx='47.9375'
      cy='47.4346'
      r='32.6562'
      fill='#5BC0EB'
      fill-opacity='0.1'
    />
    <path
      d='M59.1077 34.5497C55.341 31.5676 49.7743 32.9288 46.9714 35.381C43.8334 38.1241 43.7451 42.4596 45.379 46.0002L34.0585 57.1104C33.5987 57.5624 33.1259 60.7965 33.1493 61.4329L34.6819 62.5031C34.6819 62.5031 37.2769 63.2798 38.4069 62.3966C39.5369 61.5134 40.5292 59.9133 40.4123 59.1937C40.3448 58.7807 40.4331 58.3962 40.7007 58.1339C41.0332 57.8066 41.5631 57.752 42.1008 57.9313C42.5138 58.0689 42.9736 57.952 43.2827 57.6481L43.3918 57.5416C43.8308 57.1312 43.8802 56.6532 43.7945 56.2532C43.662 55.6323 43.888 55.118 44.2361 54.6634C44.5374 54.2686 45.1089 54.1023 45.6076 54.0945C46.5064 54.0815 47.0883 53.8893 47.7247 53.2659L49.382 51.6449C53.1123 53.3074 57.6867 52.5567 60.6559 49.3902C64.464 45.3274 63.4899 38.0202 59.1077 34.5497V34.5497ZM56.8633 43.2129C55.8242 44.2312 54.1409 44.2312 53.1019 43.2129C52.0628 42.1947 52.0628 40.5399 53.1019 39.5217C54.1409 38.5034 55.8242 38.5034 56.8633 39.5217C57.9023 40.5399 57.9023 42.1947 56.8633 43.2129Z'
      fill='#9E740B'
    />
    <path
      d='M59.1012 34.5494C55.2359 30.6841 48.9704 30.6841 45.1051 34.5494C42.1463 37.5081 41.4554 41.8696 43.0243 45.4881L33.1403 55.3098C32.9103 55.5393 32.7301 55.8136 32.6108 56.1157C32.4915 56.4178 32.4356 56.7412 32.4467 57.0659L32.561 60.1181C33.1247 60.435 33.1429 61.4351 33.1429 61.4351L35.7145 61.6325C36.3718 61.6533 37.0082 61.4013 37.4706 60.939L38.2862 60.1233C38.7954 59.6142 39.0837 58.8946 38.9564 58.188C38.8759 57.749 38.9642 57.3386 39.2422 57.0607C39.5695 56.7334 40.0812 56.671 40.6085 56.8347C41.0475 56.9723 41.5255 56.884 41.8528 56.5593L41.9645 56.4476C42.4087 56.0242 42.4581 55.5332 42.3723 55.1228C42.2399 54.4838 42.4685 53.9565 42.8165 53.4889C43.1205 53.0811 43.6997 52.9122 44.2011 52.9044C45.1077 52.8914 45.6973 52.694 46.339 52.0524L47.8924 50.5068C51.5628 52.2342 56.0724 51.5822 59.1064 48.5481C62.9666 44.6802 62.9666 38.4147 59.1012 34.5494V34.5494ZM55.5684 41.88C54.519 42.9294 52.8175 42.9294 51.7707 41.88C50.7212 40.8305 50.7212 39.129 51.7707 38.0822C52.8201 37.0327 54.5216 37.0327 55.5684 38.0822C56.6179 39.129 56.6179 40.8305 55.5684 41.88Z'
      fill='#FFCA28'
    />
    <path
      d='M32.5625 60.1182C32.5625 60.1182 32.8846 60.3364 33.1444 61.4352L45.4781 48.8963C45.8807 48.4547 44.0753 48.6833 43.535 49.221L32.5625 60.1182Z'
      fill='#DBA010'
    />
    <path
      d='M46.8032 35.7859C45.7902 36.3366 44.6472 37.8121 44.3329 39.9422C44.0471 41.8748 44.1692 43.2178 44.8186 44.3478C45.5538 45.6232 46.5539 44.9478 46.1512 43.7062C45.8863 42.8879 45.8187 42.5788 45.7824 41.7112C45.7538 41.0332 45.8239 40.35 46.0162 39.7006C46.4941 38.09 47.6033 37.0328 47.928 36.5107C48.3411 35.8431 47.689 35.3002 46.8032 35.7859V35.7859Z'
      fill='#FFF59D'
    />
    <path
      d='M39.6951 51.3195C38.9574 51.9481 39.7211 49.9921 40.2874 49.3322C41.0329 48.4594 42.7993 46.6073 43.1111 46.4489C43.5371 46.2332 43.5111 47.423 43.3371 47.6412C42.7188 48.4049 40.4355 50.6908 39.6951 51.3195Z'
      fill='#FFF59D'
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    width='101'
    height='101'
    viewBox='0 0 101 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='50.5' cy='50.5' r='50' fill='#00E08F' fill-opacity='0.3' />
    <circle cx='51.028' cy='51.026' r='33.6842' fill='#00E08F' />
    <path
      d='M63.8938 44.3164L46.2744 61.9358L38.2656 53.927'
      stroke='white'
      stroke-width='7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

const ChangePassword = () => {
  const [error, setError] = useState(null);

  const methods = useForm({
    mode: 'onTouched',
  });
  const { getValues, handleSubmit } = methods;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setNavbarTitle('Ubah Password');
  }, []);

  useEffect(() => {
    if (location.search === '?success=true') {
      setTimeout(() => {
        logout();
        navigate('/login', { replace: true });
      }, 3000);
    }
  }, [location, navigate]);

  const handleChangePassword = async (data) => {
    try {
      const user = firebase.auth().currentUser;

      await user.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          firebase.auth().currentUser.email,
          data.currentPassword,
        ),
      );

      await user.updatePassword(data.newPassword);

      navigate('/app/profile/change-password?success=true', { replace: true });
    } catch (err) {
      setError(err.message);
    }
  };

  if (location.search === '?success=true') {
    return (
      <MainLayout>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3,
          }}
        >
          <Container
            maxWidth='lg'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '32px 0',
            }}
          >
            <SuccessIcon />
            <Typography
              variant='header'
              sx={{ fontWeight: 700, color: '#1D2D5E', mt: '32px' }}
            >
              Password Diubah
            </Typography>
            <Typography variant='p20' sx={{ textAlign: 'center', mt: '16px' }}>
              Password akun Aksel kamu telah berhasil diubah. Sekarang kamu bisa
              menggunakan akunmu kembali!
            </Typography>
          </Container>
        </Box>
      </MainLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Account - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container
          maxWidth='lg'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 0',
          }}
        >
          <Icon />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <Grid container spacing={3} sx={{ marginTop: '32px' }}>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity='error' sx={{ mt: '16px' }}>
                      {error}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PasswordInput
                    name='currentPassword'
                    label='Current Password'
                    placeholder='Masukkan kata sandi sekarang'
                    validation={{
                      required: 'Password harus diisi',
                      pattern: {
                        value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/i',
                        message:
                          'Minimal 6 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka',
                      },
                    }}
                    style={{ marginTop: '16px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <PasswordInput
                    name='newPassword'
                    label='New Password'
                    placeholder='Masukkan kata sandi baru'
                    validation={{
                      required: 'Password harus diisi',
                      pattern: {
                        value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/i',
                        message:
                          'Minimal 6 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka',
                      },
                    }}
                    style={{ marginTop: '16px' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#EFF9FD',
                      borderRadius: '8px',
                      padding: '12px',
                    }}
                  >
                    <Typography variant='p'>
                      Pastikan password baru Anda mengikuti kriteria berikut:
                    </Typography>
                    <ul style={{ listStylePosition: 'inside' }}>
                      <li>
                        <Typography variant='p' sx={{ display: 'inline' }}>
                          Minimal 6 karakter
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='p' sx={{ display: 'inline' }}>
                          Mengandung kombinasi huruf kecil, huruf besar, dan
                          angka
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='p' sx={{ display: 'inline' }}>
                          Berbeda dengan password lama
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    name='confirmPassword'
                    label='Repeat New Password'
                    placeholder='Ulangi kata sandi baru'
                    validation={{
                      required: 'Konfirmasi Alamat Email harus diisi',
                      validate: {
                        confirmEmail: (v) =>
                          v === getValues('newPassword') ||
                          'Password baru tidak cocok',
                      },
                    }}
                    style={{ marginTop: '16px' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton type='submit' fullWidth>
                    Ubah Password
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
