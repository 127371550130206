const serviceLine = [
  {
    title: 'CV Preparation & Feedback',
    desc: 'Dapatkan masukan langsung dari si mentor untuk cek kesiapan CV kamu dalam melamar posisi impianmu!',
  },
  {
    title: 'Interview Preparation & Mock-up',
    desc: 'Siapkan interview kamu dengan si mentor profesional untuk hasil yang maksimal!',
  },
  {
    title: "Mentor's Career Path Exploration",
    desc: 'eksplorasikan profesi dan perusahaan si mentor untuk mengetahui pilihan terbaikmu!',
  },
  {
    title: 'Opportunity and Network Sourcing',
    desc: 'Dapatkan koneksi, informasi, job referral, dan bimbingan terkait job opportunity yang dapat kamu butuh!',
  },
  {
    title: 'Knowledge and Skill Preparation',
    desc: 'Gali pengetahuan dan kemampuan spesifik yang kamu butuhkan dari si mentor untuk persiapan kariermu!',
  },
  {
    title: 'Internship Preparation',
    desc: 'Maksimalkan persiapan magangmu dengan si mentor keren untuk dapatkan posisi impianmu!',
  },
  {
    title: 'Professional Life Sharing',
    desc: 'Belajar dari si mentor sukses dan dapatkan bimbingan karier sesuai cita-citamu!',
  },
  {
    title: 'College Life Sharing',
    desc: 'Belajar dari si high achiever dan dapatkan bocoran untuk menjadi salah satu dari mereka!',
  },
];

export default serviceLine;
