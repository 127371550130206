import React from 'react';
import { Dialog, DialogActions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CustomButton from '../button';

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
    '.css-hgttjy': {
      height: '220px !important',
    },
    '.css-1qudts3-MuiPaper-root-MuiDialog-paper': {
      borderRadius: '8px !important',
    },
    '.css-hlj6pa-MuiDialogActions-root > :not(:first-of-type)': {
      marginLeft: '0 !important',
    },
    '.css-zw3mfo-MuiModal-root-MuiDialog-root': {
      zIndex: '1500 !important',
    },
  },
}));

const CustomDialog = ({
  title,
  description,
  open,
  onClose,
  isReverseButton,
  btnSubmitTxt,
  btnCancelTxt,
  handleSubmit,
  children,
  isCustomComponent,
  ...props
}) => {
  useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
      {...props}
    >
      {isCustomComponent && <div style={{ padding: '0 16px' }}>{children}</div>}

      {!isCustomComponent && (
        <div style={{ padding: '0 16px' }}>
          <Typography
            variant='sectionHeader'
            sx={{ mt: '32px', textAlign: 'center' }}
          >
            {title}
          </Typography>
          {description && (
            <Typography variant='p' sx={{ textAlign: 'center', mb: '16px' }}>
              {description}
            </Typography>
          )}

          {children}

          {!description && <div style={{ marginBottom: '16px' }} />}

          {isReverseButton ? (
            <DialogActions
              style={{
                justifyContent: 'center',
                marginBottom: '16px',
                flexDirection: 'column',
              }}
            >
              <CustomButton
                sx={{ padding: '6px 0', width: '240px' }}
                onClick={onClose}
              >
                {btnCancelTxt}
              </CustomButton>
              <CustomButton
                variant='outlined'
                color='secondary'
                sx={{
                  padding: '6px 0',
                  width: '240px',
                  borderColor: '#fff',
                  marginTop: '8px',
                }}
                onClick={handleSubmit}
              >
                {btnSubmitTxt}
              </CustomButton>
            </DialogActions>
          ) : (
            <DialogActions
              style={{
                justifyContent: 'center',
                marginBottom: '16px',
                flexDirection: 'column',
              }}
            >
              <CustomButton
                sx={{ padding: '6px 0', width: '240px' }}
                onClick={handleSubmit}
              >
                {btnSubmitTxt || 'save'}
              </CustomButton>
              <CustomButton
                variant='outlined'
                color='secondary'
                sx={
                  btnCancelTxt
                    ? {
                        padding: '6px 0',
                        width: '240px',
                        marginTop: '8px',
                        borderColor: '#fff',
                      }
                    : { display: 'none' }
                }
                onClick={onClose}
              >
                {btnCancelTxt}
              </CustomButton>
            </DialogActions>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default CustomDialog;
