import {
  SHOW_MODAL,
  CLOSE_MODAL,
  DEAUTHENTICATED,
  ASSESSMENT_RESULT,
  SET_NAVBAR_TITLE,
  SET_FILTER_MENTOR,
} from '../events';

const initialState = {
  modal: {
    show: false,
    type: '',
    title: '',
    content: '',
    footer: '',
  },
  data: { assessmentResult: {} },
  navbar: {
    title: '',
  },
  mentor: { filter: false },
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_RESULT:
      return { ...state, data: { assessmentResult: action.payload } };
    case SHOW_MODAL:
      return { ...state, modal: { show: true, ...action.payload } };
    case CLOSE_MODAL:
      return { ...state, modal: { show: false, ...initialState.modal } };
    case SET_NAVBAR_TITLE:
      return { ...state, navbar: { title: action.payload } };
    case SET_FILTER_MENTOR:
      return { ...state, mentor: { filter: action.payload } };
    case DEAUTHENTICATED:
      return initialState;
    default:
      return state;
  }
};

export default app;
