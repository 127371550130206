import firebase from 'firebase/app';
import 'firebase/auth';
import { config } from '../../firebase.config';
import store from '../store';
import { BOOK_DRAFT, BOOK_DETAIL } from '../events';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const { dispatch } = store;

export const bookDraft = async (product) => {
  dispatch({
    type: BOOK_DRAFT,
    payload: {
      productId: product.id,
      ...product,
    },
  });
};

export const bookDetail = async (book) => {
  dispatch({
    type: BOOK_DETAIL,
    payload: {
      ...book,
    },
  });
};
