import { Box, Grid, Typography } from '@material-ui/core';

const AccountButton = ({ icon, onClick, text }) => {
  return (
    <Grid item xs={6} onClick={onClick}>
      <Box
        sx={{
          cursor: 'pointer',
          padding: '16px 12px',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.16)',
          borderRadius: '6px',
        }}
      >
        {icon}
        <Typography
          variant='button'
          sx={{ display: 'block', textTransform: 'capitalize', mt: '10px' }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default AccountButton;
