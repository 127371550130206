import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';
import { cvReviewPackage } from 'src/templates/cvReviewPackage';
// import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import BookingDetail from 'src/pages/BookingDetail';
const numberWithCommas = (x) => {
  if (x) {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  } else {
    return 0;
  }
};

const dateTO10pm = (timestamp, duration) => {
  const totalHours = 24 * duration + 22; // 22 -> jam 10 malem
  const dateTo00 = new Date(timestamp).setHours(0, 0, 0, 0);
  const date = new Date(dateTo00).setHours(totalHours, 0, 0, 0);
  const newDate = new Date(date);
  return newDate.toLocaleString('en-GB', {
    timeZone: 'Asia/Jakarta',
  });
};

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  bookingCard: {
    width: '100%',
    padding: '0 !important',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
  list: {
    width: '100vw',
    height: '100vh',
  },
  '@global': {
    '.MuiDrawer-root': {
      zIndex: '99999 !important',
    },
  },
});

const BookingCardMentor = ({ booking, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  cvReviewPackage.forEach((item) => {
    if (item.id === booking.packageId) {
      booking.packageName = item.title;
    }
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '16px',
      }}
      {...rest}
    >
      <CardActionArea>
        <CardContent className={classes.bookingCard}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 9,
            }}
          >
            <Typography variant='subHeader14'>{booking.packageName}</Typography>
            <Typography variant='pBold'>
              {booking.bookStatus === 'PROCESSING_PAYMENT' &&
                'Menunggu Pembayaran'}
              {booking.bookStatus === 'FAILED_PAYMENT' && 'Pembayaran Gagal'}
              {booking.bookStatus === 'settlement' && 'Sedang Direview'}
              {booking.bookStatus === 'UNDER_REVIEW' && 'Sedang Direview'}
              {booking.bookStatus === 'COMPLETED_REVIEW' && 'Review Selesai'}
              {booking.bookStatus === 'PROBLEM_REVIEW' && 'Review Bermasalah'}
            </Typography>
          </div>
        </CardContent>
        <div style={{ marginTop: '16px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '4px',
            }}
          >
            <Typography variant='sm'>Total Bayar</Typography>
            <Typography variant='sm'>
              Rp {numberWithCommas(booking.productPrice)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='sm'>Metode Pembayaran</Typography>
            <Typography variant='sm'>{booking.paymentMethod}</Typography>
          </div>
        </div>
      </CardActionArea>
      <CardActions sx={{ padding: '16px 0px' }}>
        {(booking.bookStatus === 'settlement' ||
          booking.bookStatus === 'UNDER_REVIEW' ||
          booking.bookStatus === 'COMPLETED_REVIEW' ||
          booking.bookStatus === 'PROBLEM_REVIEW') && (
          <Typography variant='sm' sx={{ color: '#6D6D6D' }}>
            Hasil review dapat dilihat melalui email terdaftar paling lambat
            pada <b>{dateTO10pm(booking.createdAt, booking.bookTime)}</b>
          </Typography>
        )}
        {/* {
          booking.bookStatus==="UNDER_REVIEW"?
            <div>
              <Typography variant="p" sx={{margin:"0px 10px 10px 10px",color:"#6D6D6D"}}>Hasil review dapat dilihat jika review telah selesai </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled
                disableElevation
                target="_blank" href={`${booking.linkCV}`}
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  textTransform: "capitalize",
                }}
              >
                Lihat Hasil Review
              </Button>
            </div> : 
            <div>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                target="_blank" href={`${booking.linkCV}`}
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  textTransform: "capitalize",
                }}
              >
                Lihat Hasil Review
              </Button>
            </div>
        } */}
      </CardActions>
      <Drawer anchor={'left'} open={open} onClose={handleOpen}>
        <BookingDetail handleClose={handleOpen} />
      </Drawer>
    </Card>
  );
};

BookingCardMentor.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default BookingCardMentor;
