import React from 'react';
import {
  Container,
  Typography,
  CardMedia,
  Grid,
  CardContent,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import events from 'src/templates/akselEvents';
import { CalenderIcon, ClockIcon } from 'src/assets/icons/Landing';

const Events = ({ classes }) => {
  const navigate = useNavigate();
  const handleClickEvent = (d) => {
    if (d.pushType === 'app') {
      navigate(d.pushTo, { replace: false });
    } else {
      window.open(d.pushTo, '_blank');
    }
  };

  return (
    <Container style={!events.length ? { display: 'none' } : null}>
      <Typography variant='sectionHeader' sx={{ pt: '48px' }}>
        Events
      </Typography>
      <div
        style={{
          display: 'flex',
          overflowX: 'scroll',
          height: '276px',
        }}
      >
        {events.map((event) => (
          <div
            key={event.id}
            style={{
              width: '214px',
              height: '247px',
              borderRadius: '8px',
              boxShadow: '0px 4px 16px rgba(37, 37, 37, 0.08)',
              margin: '16px 16px 0 0',
              cursor: 'pointer',
            }}
            onClick={() => handleClickEvent(event)}
          >
            <CardMedia image={event.photoUrl} className={classes.mediaEvent} />
            <CardContent className={classes.contentEvent}>
              <Typography variant='pBold' color='#252525' gutterBottom>
                {event.title}
              </Typography>
              <Grid display='flex' marginBottom='4px'>
                <CalenderIcon />
                <Typography variant='sm' sx={{ ml: '8px', color: '#8D8D8D' }}>
                  {event.date}
                </Typography>
              </Grid>
              <Grid display='flex'>
                <ClockIcon />
                <Typography variant='sm' sx={{ ml: '8px', color: '#8D8D8D' }}>
                  {event.time}
                </Typography>
              </Grid>
            </CardContent>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Events;
