import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Button from 'src/components/button';
import { logout } from 'src/redux/actions';

const NavMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavList = styled.ul`
  padding: 0 0.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavLink = styled(motion.li)`
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: start;
  margin-bottom: 10px;
`;

const variants = {
  show: {
    transform: 'translateX(0em)',
    opacity: 1,
  },
  hide: {
    transform: 'translateX(5em)',
    opacity: 0,
  },
};

export function NavMenu({ isOpen, isAuth, handleClick }) {
  const navigate = useNavigate();
  const [layananOpen, setLayananOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/', { replace: true });
    handleClick();
  };

  const handleClickHome = () => {
    navigate('/', { replace: true });
    handleClick();
  };

  const handleClickProfile = () => {
    navigate('/app/profile', { replace: false });
    handleClick();
  };

  const handleMentoring = () => {
    navigate('/app/home', { replace: false });
    handleClick();
  };

  const handleCvMaker = () => {
    localStorage.setItem('to', 'cvMaker');
    navigate('/app/cv/dashboard', { replace: false });
    handleClick();
  };

  const handleAtsChecker = () => {
    localStorage.setItem('to', 'cvMaker');
    navigate('/app/cv/dashboard?tab=ats', { replace: false });
    handleClick();
  };

  const handleBooking = () => {
    navigate('/app/booking', { replace: false });
    handleClick();
  };

  // const handleCvReview = () => {
  //   window.open('https://bit.ly/CVreviewAksel', '_blank');
  //   handleClick();
  // };

  return (
    <NavMenuContainer>
      <NavList>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.3, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.05, duration: 0.05 },
            },
          }}
        >
          <div onClick={handleClickHome}>
            <Typography
              variant='subHeader20'
              sx={{ color: layananOpen ? '#BDBDBD' : '#252525' }}
            >
              Home
            </Typography>
          </div>
        </NavLink>
        {isAuth && (
          <NavLink
            initial={false}
            animate={isOpen ? 'show' : 'hide'}
            variants={{
              show: {
                ...variants.show,
                transition: { delay: 0.3, duration: 0.2 },
              },
              hide: {
                ...variants.hide,
                transition: { delay: 0.05, duration: 0.05 },
              },
            }}
          >
            <div onClick={handleClickProfile}>
              <Typography variant='subHeader20' sx={{ color: '#BDBDBD' }}>
                Profil
              </Typography>
            </div>
          </NavLink>
        )}
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.3, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.05, duration: 0.05 },
            },
          }}
        >
          <div onClick={() => setLayananOpen(!layananOpen)}>
            <Typography
              variant='subHeader20'
              sx={{
                color: layananOpen ? '#252525' : '#BDBDBD',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Layanan{' '}
              {layananOpen ? (
                <KeyboardArrowUp style={{ marginLeft: '8px' }} />
              ) : (
                <KeyboardArrowDown style={{ marginLeft: '8px' }} />
              )}
            </Typography>
          </div>
        </NavLink>
        {layananOpen && (
          <>
            {[
              {
                id: 'mentoring',
                name: 'Career Mentoring',
                handle: handleMentoring,
              },
              {
                id: 'cvMakerReview',
                name: 'CV Maker & CV Review',
                handle: handleCvMaker,
              },
              {
                id: 'atsChecker',
                name: 'ATS Checker',
                handle: handleAtsChecker,
              },
            ].map((layanan) => (
              <NavLink
                id={layanan.id}
                initial={false}
                animate={isOpen ? 'show' : 'hide'}
                variants={{
                  show: {
                    ...variants.show,
                    transition: { delay: 0.4, duration: 0.2 },
                  },
                  hide: {
                    ...variants.hide,
                    transition: { delay: 0.1, duration: 0.05 },
                  },
                }}
              >
                <div onClick={layanan.handle}>
                  <Typography
                    variant='subHeader20'
                    sx={{
                      color: '#252525',
                      '&:hover': { color: '#1D2D5E' },
                      fontWeight: '600 !important',
                    }}
                  >
                    {layanan.name}
                  </Typography>
                </div>
              </NavLink>
            ))}
          </>
        )}
        {isAuth && (
          <>
            <NavLink
              initial={false}
              animate={isOpen ? 'show' : 'hide'}
              variants={{
                show: {
                  ...variants.show,
                  transition: { delay: 0.6, duration: 0.2 },
                },
                hide: {
                  ...variants.hide,
                  transition: { delay: 0.2, duration: 0.05 },
                },
              }}
            >
              <div onClick={handleBooking}>
                <Typography variant='subHeader20' sx={{ color: '#BDBDBD' }}>
                  Pesanan Saya
                </Typography>
              </div>
            </NavLink>
          </>
        )}
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.6, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.2, duration: 0.05 },
            },
          }}
        >
          <a
            href='https://akselofficial.medium.com/'
            target='_blank'
            rel='noreferrer'
          >
            <Typography variant='subHeader20' sx={{ color: '#BDBDBD' }}>
              Blog
            </Typography>
          </a>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.7, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.25, duration: 0.05 },
            },
          }}
          style={{ marginTop: '30px' }}
        >
          {isAuth ? (
            <Button
              variant='contained'
              onClick={handleLogout}
              sx={{ width: '100%' }}
            >
              <Typography
                variant='button'
                sx={{ textTransform: 'capitalize', padding: '0 45px' }}
              >
                Keluar
              </Typography>
            </Button>
          ) : (
            <Button
              variant='contained'
              to={'/login'}
              component={RouterLink}
              sx={{ width: '100%' }}
              onClick={() => localStorage.setItem('to', 'home')}
            >
              <Typography
                variant='button'
                sx={{ textTransform: 'capitalize', padding: '0 45px' }}
              >
                Masuk
              </Typography>
            </Button>
          )}
        </NavLink>
      </NavList>
    </NavMenuContainer>
  );
}
