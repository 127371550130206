import { Typography } from '@material-ui/core';
import tnc from 'src/templates/tnc';

export default function TnC() {
  const { header, contents } = tnc;
  return (
    <div style={{ padding: '36px 24px 24px 24px', textAlign: 'center' }}>
      <Typography variant='header' gutterBottom>
        Syarat & Ketentuan
      </Typography>
      <Typography variant='p'>{header}</Typography>
      {contents.map((content) => (
        <div
          key={content.title}
          style={{ marginTop: '24px', textAlign: 'left' }}
        >
          <Typography variant='subHeader16' gutterBottom>
            {content.title}
          </Typography>
          <Typography variant='p' gutterBottom textAlign='justify'>
            {content.description}
          </Typography>
          {content.bodies &&
            content.bodies.map((body) => (
              <>
                {body.subHeader ? (
                  <>
                    <Typography variant='subHeader14' gutterBottom>
                      {body.subHeader}
                    </Typography>
                    <Typography
                      variant='p'
                      gutterBottom
                      textAlign='justify'
                      sx={{ ml: '16px' }}
                    >
                      {body.content}
                    </Typography>
                    {body.subContents &&
                      body.subContents.map((subContent) => (
                        <Typography
                          variant='p'
                          gutterBottom
                          textAlign='justify'
                          sx={{ ml: '16px' }}
                        >
                          {subContent}
                        </Typography>
                      ))}
                  </>
                ) : (
                  <>
                    <Typography variant='p' gutterBottom textAlign='justify'>
                      {body.content}
                    </Typography>
                    {body.subContents &&
                      body.subContents.map((subContent, index) => (
                        <Typography
                          key={index}
                          variant='p'
                          gutterBottom
                          textAlign='justify'
                          sx={{ ml: '16px' }}
                        >
                          {subContent}
                        </Typography>
                      ))}
                  </>
                )}
              </>
            ))}
        </div>
      ))}
    </div>
  );
}
