import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  bookingPreview: {
    display: 'flex',
    justifyContent: 'left',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
});

const BookingPreview = ({ booking, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.bookingPreview}>
      <Avatar
        src={booking.photoUrl}
        sx={{
          height: 65,
          width: 65,
          marginRight: 2,
        }}
      />
      <div sx={{ float: 'right' }}>
        <h3 className={classes.heading}>{booking.name}</h3>
        <Typography gutterBottom variant='p' component='p'>
          {booking.title}
        </Typography>
      </div>
    </div>
  );
};

BookingPreview.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default BookingPreview;
