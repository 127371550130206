import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import serviceLineTemplate from 'src/templates/serviceLine';

import {
  ArrowIcon,
  CareerPathIcon,
  CollegeLifeIcon,
  CvPreparationIcon,
  InternshipPreparationIcon,
  InterviewPreparationIcon,
  KnowledgeSkillIcon,
  NetworkSourcingIcon,
  ProfessionalLifeIcon,
} from 'src/assets/icons/Mentoring';

const iconType = {
  'College Life Sharing': CollegeLifeIcon,
  'Professional Life Sharing': ProfessionalLifeIcon,
  'Internship Preparation': InternshipPreparationIcon,
  "Mentor's Career Path Exploration": CareerPathIcon,
  'Knowledge and Skill Preparation': KnowledgeSkillIcon,
  'CV Preparation & Feedback': CvPreparationIcon,
  'Interview Preparation & Mock-up': InterviewPreparationIcon,
  'Opportunity and Network Sourcing': NetworkSourcingIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAccordion-root': {
      marginTop: '8px',
      marginBottom: '8px',
      borderRadius: '8px',
      border: '1px solid #BDBDBD',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '0',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '0',
    },
    '& .MuiAccordionSummary-content': {
      marginTop: '8px',
      marginBottom: '8px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: '16px',
      marginBottom: '8px',
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
      paddingBottom: '16px',
      paddingLeft: '40px',
      paddingRight: '24px',
    },
  },
}));

export default function ServicesAccordion({ serviceLine }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {serviceLine &&
        serviceLineTemplate
          .filter((t) => serviceLine.includes(t.title))
          .map((d) => {
            const Icon = iconType[d.title] || CollegeLifeIcon;

            return (
              <Accordion key={d.title}>
                <AccordionSummary expandIcon={<ArrowIcon />}>
                  <Icon />
                  <Typography variant='p' pl={1}>
                    {d.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant='p'>{d.desc}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
    </div>
  );
}
