import React from 'react';
import { Typography, IconButton, Container, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import '../pages/cvMaker/preview/preview.css';

const useStyles = makeStyles(() => ({
  '@global': {
    '.css-1u8c07-MuiPaper-root-MuiDrawer-paper': {
      borderRadius: '16px 16px 0 0',
    },
  },
  root: {
    '& .MuiDrawer-paper': {
      maxWidth: '768px',
      margin: '0 auto',
    },
  },
}));

const CustomDrawer = ({ title, open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={onClose}
      className={classes.root}
      sx={{ position: 'relative' }}
    >
      <Container sx={{ pb: '72px' }}>
        <div className='header-menu'>
          <Typography variant='sectionHeader'>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </Container>
    </Drawer>
  );
};

export default CustomDrawer;
