import { motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { MenuToggle } from './menuToggle';
import { NavMenu } from './navMenu';

const HamburgerMenuContainer = styled.div`
  display: flex;
`;

const MenuContainer = styled(motion.div)`
  width: 100vw;
  margin-top: 47px;
  height: 100%;
  background-color: #fff;
  z-index: 90;
  position: fixed;
  top: 0;
  right: 0;
  user-select: none;
  padding: 1em 2.5em;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

const menuVariants = {
  open: {
    transform: 'translateX(0%)',
  },
  closed: {
    transform: 'translateX(100%)',
  },
};

const menuTransition = {
  duration: 0.2,
  stiffness: 100,
};

export function HamburgerMenu() {
  const [isOpen, setOpen] = useState(false);
  const isAuth = JSON.parse(localStorage.getItem('user'))?.uid;

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <HamburgerMenuContainer>
      <MenuToggle toggle={toggleMenu} isOpen={isOpen} />
      <MenuContainer
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
        transition={menuTransition}
      >
        <ContentContainer>
          <NavMenu isOpen={isOpen} isAuth={isAuth} handleClick={toggleMenu} />
        </ContentContainer>
      </MenuContainer>
    </HamburgerMenuContainer>
  );
}
