import { Helmet } from 'react-helmet';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import reactGA from 'react-ga';
import { setNavbarTitle } from 'src/redux/actions';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed

const useStyles = makeStyles({
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
});

const Successs = () => {
  setNavbarTitle('');
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Successs - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Container maxWidth='sm'>
          <Box sx={{ mb: 3 }}>
            <h2 className={classes.heading}>Booking Success!</h2>
            <Typography color='textSecondary' gutterBottom variant='body2'>
              Mentormu sudah berhasil dipesan, selanjutnya kamu bisa chat
              langsung degan mentor
            </Typography>
            <br />
            <div>
              <Button
                variant='contained'
                component={RouterLink}
                to='/app/home'
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                Kirim Pesan
              </Button>
            </div>
            <br />
            <div>
              <Button
                variant='outlined'
                component={RouterLink}
                to='/app/home'
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                Kembali
              </Button>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Successs;
