import { useState } from 'react';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Button,
  DialogActions,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { EmptyRatingIcon, FullRatingIcon } from 'src/assets/icons/Landing';

import CustomButton from 'src/components/button';
import { closeATSRating } from 'src/redux/actions/rating-actions';

function Logo({ ...rest }) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <circle cx='25' cy='25' r='25' fill='#E58800' fill-opacity='0.15' />
      <path
        d='M37.4099 37.7296C37.4099 38.6648 36.6509 39.4238 35.7157 39.4238H15.3856C14.4504 39.4238 13.6914 38.6648 13.6914 37.7296V14.8582C13.6914 13.9231 14.4504 13.1641 15.3856 13.1641H35.7157C36.6509 13.1641 37.4099 13.9231 37.4099 14.8582V37.7296Z'
        fill='#E58800'
      />
      <path
        d='M34.8703 36.0352C34.8703 36.5036 34.4916 36.8823 34.0232 36.8823H17.0815C16.6139 36.8823 16.2344 36.5036 16.2344 36.0352V16.5522C16.2344 16.0846 16.6139 15.7051 17.0815 15.7051H34.0232C34.4916 15.7051 34.8703 16.0846 34.8703 16.5522V36.0352Z'
        fill='white'
      />
      <path
        d='M31.4792 11.47H28.0908C28.0908 10.0663 26.9532 8.92871 25.5496 8.92871C24.1459 8.92871 23.0083 10.0663 23.0083 11.47H19.62C18.6848 11.47 17.9258 12.229 17.9258 13.1641V17.3996H33.1734V13.1641C33.1734 12.229 32.4144 11.47 31.4792 11.47Z'
        fill='#BDBDBD'
      />
      <path
        d='M25.5496 13.1628C26.4853 13.1628 27.2438 12.4043 27.2438 11.4686C27.2438 10.5329 26.4853 9.77441 25.5496 9.77441C24.614 9.77441 23.8555 10.5329 23.8555 11.4686C23.8555 12.4043 24.614 13.1628 25.5496 13.1628Z'
        fill='#292F33'
      />
      <path
        d='M27.2476 20.7895C27.2476 21.2571 26.869 21.6366 26.4006 21.6366H18.7768C18.3092 21.6366 17.9297 21.2571 17.9297 20.7895C17.9297 20.3219 18.3092 19.9424 18.7768 19.9424H26.4006C26.869 19.9424 27.2476 20.3219 27.2476 20.7895ZM33.1773 24.1778C33.1773 24.6454 32.7986 25.0249 32.3302 25.0249H18.7768C18.3092 25.0249 17.9297 24.6454 17.9297 24.1778C17.9297 23.7102 18.3092 23.3307 18.7768 23.3307H32.3302C32.7986 23.3307 33.1773 23.7102 33.1773 24.1778ZM33.1773 27.5662C33.1773 28.0346 32.7986 28.4133 32.3302 28.4133H18.7768C18.3092 28.4133 17.9297 28.0346 17.9297 27.5662C17.9297 27.0977 18.3092 26.7191 18.7768 26.7191H32.3302C32.7986 26.7191 33.1773 27.0977 33.1773 27.5662ZM33.1773 30.9545C33.1773 31.423 32.7986 31.8016 32.3302 31.8016H18.7768C18.3092 31.8016 17.9297 31.423 17.9297 30.9545C17.9297 30.4861 18.3092 30.1074 18.7768 30.1074H32.3302C32.7986 30.1074 33.1773 30.4861 33.1773 30.9545ZM33.1773 34.3429C33.1773 34.8113 32.7986 35.19 32.3302 35.19H24.7064C24.2388 35.19 23.8593 34.8113 23.8593 34.3429C23.8593 33.8744 24.2388 33.4958 24.7064 33.4958H32.3302C32.7986 33.4958 33.1773 33.8744 33.1773 34.3429Z'
        fill='#A3A3A3'
      />
      <mask
        id='mask0_6360_7952'
        maskUnits='userSpaceOnUse'
        x='23'
        y='24'
        width='19'
        height='19'
      >
        <path
          d='M40.7524 24.4703C39.9051 23.9177 38.7705 24.1598 38.2179 25.0118L30.2389 37.3573L26.5528 33.936C25.8096 33.2458 24.6501 33.2922 23.9633 34.0375C23.2765 34.7836 23.322 35.9476 24.0652 36.637L29.3491 41.5417C29.7009 41.8691 30.148 42.031 30.5922 42.031C31.0855 42.031 31.7165 41.8235 32.1306 41.1929C32.374 40.8206 41.2926 27.0146 41.2926 27.0146C41.8431 26.1618 41.6012 25.0228 40.7524 24.4703Z'
          fill='#66757F'
        />
      </mask>
      <g mask='url(#mask0_6360_7952)'>
        <path
          d='M15.625 13.4551C15.625 12.9028 16.0727 12.4551 16.625 12.4551H36.4107C36.963 12.4551 37.4107 12.9028 37.4107 13.4551V38.3436C37.4107 38.8959 36.963 39.3436 36.4107 39.3436H16.625C16.0727 39.3436 15.625 38.8959 15.625 38.3436V13.4551Z'
          fill='#66757F'
        />
      </g>
      <path
        d='M39.8579 21.0375C39.0106 20.4871 37.876 20.7282 37.3233 21.577L29.3444 33.8753L25.6583 30.467C24.9151 29.7795 23.7555 29.8257 23.0688 30.5682C22.382 31.3114 22.4274 32.4709 23.1706 33.1577L28.4545 38.0436C28.8064 38.3698 29.2535 38.531 29.6976 38.531C30.1909 38.531 30.822 38.3243 31.2361 37.6962C31.4795 37.3253 40.3981 23.5721 40.3981 23.5721C40.9485 22.7226 40.7067 21.588 39.8579 21.0375Z'
        fill='#77B255'
      />
      <path
        d='M39.8579 21.0375C39.0106 20.4871 37.876 20.7282 37.3233 21.577L29.3444 33.8753L25.6583 30.467C24.9151 29.7795 23.7555 29.8257 23.0688 30.5682C22.382 31.3114 22.4274 32.4709 23.1706 33.1577L28.4545 38.0436C28.8064 38.3698 29.2535 38.531 29.6976 38.531C30.1909 38.531 30.822 38.3243 31.2361 37.6962C31.4795 37.3253 40.3981 23.5721 40.3981 23.5721C40.9485 22.7226 40.7067 21.588 39.8579 21.0375Z'
        fill='#77B255'
      />
    </svg>
  );
}

function IconContainer(props) {
  const { value, className, children, ...other } = props;
  const isDesktop = useMediaQuery('(min-width:1280px)');

  return (
    <span {...other} style={{ padding: isDesktop ? '0 8px' : '0 4px' }}>
      {children}
    </span>
  );
}

const ATSRating = ({ onClose }) => {
  const [value, setValue] = useState(null);
  const [description, setDescription] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const isDesktop = useMediaQuery('(min-width:1280px)');

  const handleSubmit = async () => {
    const feedbackRef = firebase.database().ref(`feedbacks/${user.uid}`);
    let oldValues;

    await feedbackRef.once('value', (snapshot) => {
      oldValues = snapshot.val() || [];
    });

    await feedbackRef.set(
      [
        ...oldValues,
        {
          ratingId: uuid(),
          rating: value,
          description: description,
          type: 'atsc',
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        },
      ],
      (err) => {
        if (err) {
          console.error(err);
        }
      },
    );

    closeATSRating();
  };

  return (
    <Box margin={'32px 0'} padding={'0 24px'}>
      <Logo style={{ display: 'block', margin: 'auto' }} />
      <Typography
        variant={isDesktop ? 'sectionHeader' : 'subHeader18'}
        sx={{
          textAlign: 'center',
          margin: isDesktop ? '12px 48px 0 48px' : '12px 0 0 0',
        }}
      >
        Apakah kamu menyukai penggunaan ATS Checker?
      </Typography>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop={3}
      >
        <Rating
          name='highlight-selected-only'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          IconContainerComponent={IconContainer}
          icon={
            <FullRatingIcon
              fontSize='inherit'
              style={{ height: isDesktop ? '60px' : '40px' }}
            />
          }
          emptyIcon={
            <EmptyRatingIcon
              fontSize='inherit'
              style={{ height: isDesktop ? '60px' : '40px' }}
            />
          }
        />
        {value !== null && (
          <TextField
            size='small'
            fullWidth
            placeholder={
              isDesktop
                ? 'Ceritakan pengalaman kamu menggunakan ATS Checker?'
                : 'Bagaimana pengalaman kamu menggunakan ATS Checker?'
            }
            margin='normal'
            variant='outlined'
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        )}
      </Box>
      <DialogActions
        style={{
          justifyContent: value ? 'center' : 'flex-end',
          marginTop: '24px',
          gap: '12px',
        }}
      >
        {value ? (
          <>
            <CustomButton
              variant='outlined'
              color='secondary'
              onClick={onClose}
              sx={{
                padding: '6px 0',
                width: '240px',
                borderColor: '#fff',
              }}
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              sx={{ padding: '6px 0', width: '240px' }}
            >
              Kirim
            </CustomButton>
          </>
        ) : (
          <Button
            onClick={onClose}
            sx={{
              color: '#1D2D5E',
              padding: 0,
              borderColor: '#fff',
              textTransform: 'none',
            }}
          >
            Ingatkan lagi nanti
          </Button>
        )}
      </DialogActions>
    </Box>
  );
};

export default ATSRating;
