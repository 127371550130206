import firebase from 'firebase/app';

export function checkUndefined({ ...args }) {
  Object.keys(args).forEach((arg) => {
    if (typeof args[arg] === 'undefined' || args[arg] === '')
      throw new Error(`Missing this value ${arg}`);
  });
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function idGenerator() {
  return Number(new Date()).toString(36);
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const unsplashPhotoRequestUrl =
  'https://source.unsplash.com/featured/400x600';

export const getUnsplashPhoto = async () => {
  const response = await fetch(unsplashPhotoRequestUrl);
  return response.url;
};

export const checkIsFeedbackExist = async (type) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const feedbackRef = firebase.database().ref(`feedbacks/${user.uid}`);

  let isExist = false;

  await feedbackRef.once('value', (snapshot) => {
    snapshot.forEach((s) => {
      const feedback = s.val();
      if (feedback.type === type) {
        isExist = true;
      }
    });
  });

  return isExist;
};

export const isFilePDF = (file) => {
  const acceptedPdfTypes = ['application/pdf'];
  return file && acceptedPdfTypes.includes(file.type);
};

export const voucherCounter = ({
  originalPrice,
  discount,
  minimumOrder = 0,
  maximumDiscount = 99999999999,
}) => {
  if (originalPrice < minimumOrder) {
    return 'Belum mencapai batas minimum order';
  }

  let discountValue = originalPrice * discount;
  if (discountValue >= maximumDiscount) {
    discountValue = maximumDiscount;
  }

  const finalPrice = originalPrice - discountValue;
  return finalPrice;
};

export const numberWithCommas = (x) => {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
