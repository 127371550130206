import React from 'react';
import { Typography, Popover, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    '.css-spy0fc-MuiPaper-root-MuiPopover-paper': {
      borderRadius: '8px !important',
    },
  },
}));

const CustomPopover = ({
  listMenu = [],
  open,
  anchorEl,
  handleClose,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  ...props
}) => {
  useStyles();
  return (
    <Popover
      id='popover'
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      // onMouseLeave={handleClose}
      {...props}
    >
      <Card sx={{ borderRadius: '8px !important' }}>
        <div style={{ margin: '16px 24px' }}>
          {listMenu.map((menu, idx) => (
            <div
              style={{
                display: 'flex',
                marginBottom: '16px',
                cursor: 'pointer',
              }}
              onClick={menu.handle && menu.handle}
              key={idx}
            >
              {menu.icon && (
                <img
                  src={menu.icon}
                  alt={menu.label}
                  style={{ width: 48, height: 48 }}
                />
              )}

              {menu.button && menu.button}

              {!menu.button && (
                <Typography
                  variant='menu'
                  gutterBottom
                  sx={{
                    color: '#252525',
                    margin: menu.icon ? 'auto 16px' : 0,
                    '&:hover': {
                      color: '#1D2D5E',
                    },
                  }}
                >
                  {menu.label}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Card>
    </Popover>
  );
};

export default CustomPopover;
