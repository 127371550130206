import CustomSelect from '../forms/CustomSelect';

const EmployedForm = ({ jobType }) => {
  return (
    <>
      <CustomSelect
        label='Tipe Pekerjaan'
        name='jobType'
        placeholder='Pilih tipe pekerjaan'
        validation={{ required: 'Tipe Pekerjaan harus diisi' }}
        options={jobType}
      />
      <CustomSelect
        label='Lama Tahun Kerja'
        name='jobType'
        placeholder='Pilih lama tahun bekerja'
        validation={{ required: 'Lama Tahun Kerja harus diisi' }}
        options={['< 2 tahun', '2 - 5 tahun', '> 5 tahun']}
      />
    </>
  );
};

export default EmployedForm;
