const serviceLine = [
  {
    title: 'Apa yang dimaksud dengan sesi trial?',
    desc: 'Sesi trial membantu memastikan kalau kamu cocok dengan mentor yang kamu pilih sebelum komitmen yang lebih besar. Kami memberikan garansi uang 100% kembali di sesi trial jika kamu merasa kurang cocok dengan mentor yang kamu pilih.',
  },
  {
    title: 'Bagaimana cara menentukan jadwal mentoring?',
    desc: 'Setelah melakukan booking, kamu bisa langsung chat mentor kamu dan menjadwalkan sesi dengan mentor kamu yah!',
  },
  {
    title:
      'Apakah aku harus menyelesaikan waktu yang sudah aku booking dalam satu sesi?',
    desc: 'Tidak, kamu bisa membagi waktu yang kamu booking ke beberapa sesi. Misalkan, booking 3 jam sesi mentoring bisa dipecah menjadi 2 sesi berdurasi 1 jam dan 2 sesi berdurasi 30 menit.',
  },
  {
    title: 'Apa yang harus aku lakukan setelah sesi mentoring sudah dilakukan?',
    desc: "Silahkan membuka kembali website Aksel, cek menu 'Pesanan Saya' dan berikan penilaian untuk mentor kamu ya!",
  },
  {
    title:
      'Apakah aku akan mendapatkan semua layanan mentoring di satu kali pemesanan?',
    desc: 'Untuk layanan mentoring, silahkan disesuaikan dengan kebutuhan kamu ya! Setelah melakukan pemesanan, kamu dapat mendiskusikan dengan mentor kamu mengenai topik yang ingin dibahas.',
  },
  {
    title:
      'Bagaimana jika aku sudah melakukan pemesanan namun mentor tidak dapat melakukan mentoring karena jadwalnya sudah full?',
    desc: 'Tenang, kami menjamin ketersediaan jadwal mentor ketika kamu sudah melakukan pemesanan karena sesi mentoring wajib diselesaikan maksimal 7 hari setelah pemesanan.',
  },
  {
    title: 'Apa yang akan saya dapatkan dari sesi mentoring ini?',
    desc: 'Kamu akan mendapatkan informasi, saran, dan perspektif mengenai dunia kerja yang lebih realistis dari mentor-mentor terpilih.',
  },
];

export default serviceLine;
