import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../../history';
import auth from './auth-reducer';
import app from './app-reducer';
import book from './book-reducer';
import cv from './cv-reducer';
import rating from './rating-reducer';

const reducers = combineReducers({
  router: connectRouter(history),
  auth,
  app,
  book,
  cv,
  rating,
});

export default reducers;
