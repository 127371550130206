import { Box, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  ConsultIcon,
  FinanceIcon,
  FmcgIcon,
  NgoIcon,
  TechIcon,
} from 'src/assets/icons/Mentoring';

const iconType = {
  'Teknologi/Startup': TechIcon,
  FMCG: FmcgIcon,
  'Consulting/Advisory Services': ConsultIcon,
  NGO: NgoIcon,
  'Financial Services': FinanceIcon,
};

const useStyles = makeStyles({
  list: {
    marginTop: '16px',
    listStyle: 'none',
  },
});

const ChipList = ({ data, type = '' }) => {
  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      {data.map((e) => {
        const Icon = iconType[e] || TechIcon;

        return (
          <Grid item key={e}>
            {type === 'industry' ? (
              <Chip
                icon={<Icon />}
                label={e}
                variant='outlined'
                sx={{ borderColor: '#BDBDBD', paddingLeft: '10px' }}
              />
            ) : (
              <Chip
                label={e}
                variant='outlined'
                sx={{ borderColor: '#BDBDBD' }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const ProfileTab = ({ mentor }) => {
  const classes = useStyles();

  return (
    <>
      {mentor.description && (
        <>
          <Box py={3}>
            <Typography variant='subHeader16'>Tentang Mentor</Typography>
            <Typography variant='p' sx={{ mt: 2, whiteSpace: 'pre-line' }}>
              {mentor.description}
            </Typography>
          </Box>
          <Divider sx={{ color: '#E8E8E8' }} />
        </>
      )}
      <Box py={3}>
        <Typography variant='subHeader16'>Pengalaman</Typography>
        <ul className={classes.list}>
          {mentor.experiences.split(',').map((e) => (
            <li key={e}>
              <Typography variant='p' component='p' sx={{ color: 'black' }}>
                {e}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider sx={{ color: '#E8E8E8' }} />
      <Box py={3}>
        <Typography variant='subHeader16'>Industri</Typography>
        <ChipList data={mentor.industry.split(',')} type='industry' />
      </Box>
      <Divider sx={{ color: '#E8E8E8' }} />
      <Box py={3}>
        <Typography variant='subHeader16'>Keahlian</Typography>
        <ChipList data={mentor.skills.split(',')} />
      </Box>
    </>
  );
};

export default ProfileTab;
