export const VoucherIcon = () => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.8996 3.54862C12.5072 4.15668 13.3981 4.30947 14.1474 4.00834L15.7568 5.61691C16.0851 5.94655 16.0856 6.48022 15.755 6.81075L6.80702 15.7534C6.47604 16.0826 5.94282 16.0817 5.61273 15.7534L4.00639 14.1444C4.30574 13.3943 4.15339 12.5042 3.54667 11.8962C2.93772 11.2881 2.04813 11.1358 1.29842 11.4364L0.247567 10.3852C-0.0820766 10.0568 -0.0829675 9.52184 0.247567 9.19131L9.19426 0.247288C9.52346 -0.0823551 10.0567 -0.0828005 10.3877 0.248179L11.4394 1.29992C11.1378 2.04963 11.2919 2.93967 11.8996 3.54862Z'
      fill='#1D2D5E'
    />
    <path
      d='M2.39611 8.2367L1.43881 9.19356C1.10961 9.52276 1.10872 10.0573 1.43747 10.3874L1.67891 10.6284C2.52351 10.4034 3.46032 10.6204 4.12094 11.2823C4.78379 11.9443 4.99895 12.8811 4.77444 13.7239L5.61102 14.5627C5.94111 14.8919 6.47566 14.8919 6.80575 14.5618L7.78087 13.5876L2.39611 8.2367ZM8.41922 12.9497L12.9442 8.42736L7.55814 3.07645L3.0349 7.5988L8.41922 12.9497ZM9.30569 6.71633C10.3058 7.70883 10.5285 9.10224 9.80506 9.82968C9.08029 10.558 7.68376 10.3424 6.68637 9.35081C5.68898 8.35831 5.46669 6.96401 6.19012 6.23657C6.914 5.51046 8.3083 5.72429 9.30569 6.71633ZM14.5622 5.61871L13.7242 4.78079C12.8801 5.00486 11.9433 4.79015 11.2818 4.12685C10.6189 3.46579 10.4015 2.52719 10.6269 1.68393L10.3859 1.44071C10.0563 1.11329 9.52174 1.11329 9.19299 1.44204L8.19694 2.4381L13.5835 7.78857L14.5617 6.81077C14.8914 6.48247 14.8914 5.94836 14.5622 5.61871Z'
      fill='#C1CBEC'
    />
  </svg>
);

export const HourGlassIcon = () => (
  <svg
    width='8'
    height='11'
    viewBox='0 0 8 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.92144 5.50022C4.92144 4.8888 5.91327 4.4708 6.44922 3.66688C7.06033 2.75022 7.06033 0.611328 7.06033 0.611328H0.949219C0.949219 0.611328 0.949219 2.75022 1.56033 3.66688C2.09627 4.4708 3.08811 4.8888 3.08811 5.50022C3.08811 6.11163 2.09627 6.52963 1.56033 7.33355C0.949219 8.25022 0.949219 10.3891 0.949219 10.3891H7.06033C7.06033 10.3891 7.06033 8.25022 6.44922 7.33355C5.91327 6.52963 4.92144 6.11163 4.92144 5.50022Z'
      fill='#FFE8B6'
    />
    <path
      d='M4.00694 0.611328H1.5625C1.5625 0.611328 1.5625 1.83355 1.86806 2.75022C2.2665 3.94555 3.70139 4.27769 3.70139 5.50022C3.70139 6.72274 3.70139 9.47244 4.00694 9.47244C4.3125 9.47244 4.3125 6.72244 4.3125 5.50022C4.3125 4.27799 5.74769 3.94555 6.14583 2.75022C6.45139 1.83355 6.45139 0.611328 6.45139 0.611328H4.00694Z'
      fill='#FFAC33'
    />
    <path
      d='M7.67318 10.3889C7.67318 10.7262 7.3994 11 7.06207 11H0.950955C0.613622 11 0.339844 10.7262 0.339844 10.3889C0.339844 10.0516 0.613622 9.77778 0.950955 9.77778H7.06207C7.3994 9.77778 7.67318 10.0516 7.67318 10.3889ZM7.67318 0.611111C7.67318 0.948444 7.3994 1.22222 7.06207 1.22222H0.950955C0.613622 1.22222 0.339844 0.948444 0.339844 0.611111C0.339844 0.273778 0.613622 0 0.950955 0H7.06207C7.3994 0 7.67318 0.273778 7.67318 0.611111Z'
      fill='#1D2D5E'
    />
  </svg>
);
