import firebase from 'firebase/app';
import 'firebase/storage';

import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRef } from 'react';
import CustomButton from '../button';
import CustomInput from '../forms/CustomInput';
import CustomSelect from '../forms/CustomSelect';
import DomicileInput from './DomicileInput';

const OnboardingForm = ({ cvUrl, renderedComponent, setCvUrl }) => {
  const fileInputRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));

  let cvRef;

  if (cvUrl) {
    cvRef = firebase.storage().refFromURL(cvUrl);
  }

  const handleCVUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    const ref = firebase.storage().ref(`/users/${user.uid}/cv/${file.name}`);
    const uploadTask = ref.put(file);

    uploadTask.on('state_changed', console.log, console.error, () => {
      ref.getDownloadURL().then((url) => {
        setCvUrl(url);
      });
    });
  };

  const handleCVRemove = () => {
    setCvUrl('');

    firebase.database().ref(`users/${user.uid}`).update({ cvUrl: '' });
  };

  return (
    <>
      <DomicileInput />
      <CustomSelect
        label='Pendidikan Terakhir'
        name='education'
        placeholder='Pilih pendidikan terakhir'
        validation={{ required: 'Pendidikan Terakhir harus diisi' }}
        options={[
          'Tidak Sekolah',
          'SD',
          'SMP',
          'SMA',
          'Diploma',
          'S1',
          'S2',
          'S3',
        ]}
      />
      <CustomSelect
        label='Status'
        name='status'
        placeholder='Pilih status'
        validation={{ required: 'Status harus diisi' }}
        options={[
          'Pelajar Aktif',
          'Mencari Pekerjaan',
          'Sudah Bekerja/Memiliki Usaha',
        ]}
      />
      {renderedComponent()}
      <CustomInput
        name='linkedin'
        label='Profil Linkedin'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              https://www.linkedin.com/in/
            </InputAdornment>
          ),
        }}
        style={{ marginTop: '16px' }}
      />
      <CustomInput
        name='summary'
        label='Ringkasan Singkat'
        placeholder='Ceritakan dirimu dengan singkat'
        multiline
        rows={4}
        style={{ marginTop: '16px' }}
      />
      <Box>
        <Typography variant='subHeader16'>CV / Resume</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.4rem',
            border: '1px solid #D0D0D0',
            borderRadius: '8px',
            padding: '8px 14px',
          }}
        >
          {cvUrl ? (
            <>
              <Typography>{cvRef.name}</Typography>
              <IconButton
                onClick={handleCVRemove}
                aria-label='delete'
                sx={{ color: '#E86258' }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Typography>Pilih CV / Resume kamu</Typography>
              <CustomButton
                size='small'
                onClick={() => fileInputRef.current.click()}
              >
                Choose File
              </CustomButton>
              <input
                name='file'
                type='file'
                accept='application/pdf'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleCVUpload}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OnboardingForm;
