import tokopedia from 'src/assets/img/tokopedia.png';
import alodokter from 'src/assets/img/alodokter.png';
import zenius from 'src/assets/img/zenius.png';
import pintaar from 'src/assets/img/pintaar.jpg';
import pluang from 'src/assets/img/pluang.png';
import cicil from 'src/assets/img/cicil.png';

const sponsor = [
  {
    name: 'tokopedia',
    src: tokopedia,
    width: '100%',
  },
  {
    name: 'alodokter',
    src: alodokter,
    width: '100%',
  },
  {
    name: 'zenius',
    src: zenius,
    width: '100%',
  },
  {
    name: 'pluang',
    src: pluang,
    width: '100%',
  },
  {
    name: 'cicil',
    src: cicil,
    width: '50%',
  },
  {
    name: 'pintaar',
    src: pintaar,
    width: '50%',
  },
];

export default sponsor;
