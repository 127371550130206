const Logo = (props) => (
  <img
    alt='Logo'
    src='/static/logo-black.png'
    style={{
      paddingTop: '4px',
      height: '32px',
    }}
    {...props}
  />
);

export default Logo;
