import { Typography } from '@material-ui/core';
import privacyPolicy from 'src/templates/privacyPolicy';

export default function PrivacyPolicy() {
  const { header, contents } = privacyPolicy;
  return (
    <div style={{ padding: '36px 24px 24px 24px', textAlign: 'center' }}>
      <Typography variant='header' gutterBottom>
        Kebijakan Privasi
      </Typography>
      <Typography variant='p'>{header}</Typography>
      {contents.map((content) => (
        <div style={{ marginTop: '24px', textAlign: 'left' }}>
          <Typography variant='subHeader16' gutterBottom>
            {content.title}
          </Typography>
          <Typography variant='p' gutterBottom>
            {content.description}
          </Typography>
          {content.bodies.map((body) => (
            <>
              {body.subHeader ? (
                <>
                  <Typography variant='subHeader14' gutterBottom>
                    {body.subHeader}
                  </Typography>
                  <Typography
                    variant='p'
                    gutterBottom
                    textAlign='justify'
                    sx={{ ml: '16px' }}
                  >
                    {body.content}
                  </Typography>
                  {body.subContents &&
                    body.subContents.map((subContent, index) => (
                      <Typography
                        key={index}
                        variant='p'
                        gutterBottom
                        textAlign='justify'
                        sx={{ ml: '32px' }}
                      >
                        {subContent}
                      </Typography>
                    ))}
                </>
              ) : (
                <Typography variant='p' gutterBottom textAlign='justify'>
                  {body.content}
                </Typography>
              )}
            </>
          ))}
        </div>
      ))}
    </div>
  );
}
