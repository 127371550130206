import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#1d2d5e',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
  },
  shadows,
  MuiTypography: {
    variantMapping: {
      mainHeader: 'mainHeader',
      header: 'header',
      sectionHeader: 'sectionHeader',
      subHeader20: 'subHeader20',
      subHeader16: 'subHeader16',
      subHeader14: 'subHeader14',
      pBold: 'pBold',
      p: 'p',
      smBold: 'smBold',
      sm: 'sm',
      button: 'button',
      smallButton: 'smallButton',
    },
  },
  typography,
});

export default theme;
