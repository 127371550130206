import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ServicesAccordion from '../booking/ServicesAccordion';

const useStyles = makeStyles({
  list: {
    marginTop: '16px',
    listStyle: 'none',
  },
});

const ServiceTab = ({ mentor }) => {
  const classes = useStyles();

  return (
    <>
      <Box py={3}>
        <Typography variant='subHeader16'>Layanan Mentoring</Typography>
        <Grid container xs={12} spacing={1} marginTop={2}>
          <Grid item xs={12}>
            <ServicesAccordion
              serviceLine={mentor.serviceLine && mentor.serviceLine.split(',')}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ color: '#E8E8E8' }} />
      <Box py={3}>
        <Typography variant='subHeader16'>Jadwal Mentoring</Typography>
        <ul className={classes.list}>
          {mentor.schedule.split(',').map((s) => (
            <Typography key={s} variant='p' component='p'>
              {s}
            </Typography>
          ))}
        </ul>

        {/* <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography variant="pBold">Senin</Typography>
            <ScheduleList
              data={[
                "09.00 - 10.00",
                "11.00 - 15.00",
                "17.00 - 19.00",
                "20.00 - 21.00",
              ]}
            />
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
};

export default ServiceTab;
