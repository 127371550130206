import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import CvNavbar from './CvNavbar';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 57,
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const useStyles = makeStyles(() => ({
  '@global': {
    '.css-1dztziv': {
      display: 'none !important',
    },
  },
}));

const CvLayout = () => {
  useStyles();

  return (
    <MainLayoutRoot>
      <Helmet>
        <title>CV Maker | Aksel</title>
      </Helmet>
      <CvNavbar />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default CvLayout;
