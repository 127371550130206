export const AUTHENTICATED = 'AUTHENTICATED';
export const DEAUTHENTICATED = 'DEAUTHENTICATED';
export const ASSESSMENT_RESULT = 'ASSESSMENT_RESULT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const BOOK_SUBMITTED = 'BOOK_SUBMITTED';
export const BOOK_DRAFT = 'BOOK_DRAFT';
export const BOOK_DETAIL = 'BOOK_DETAIL';
export const GET_CV_DETAIL = 'GET_CV_DETAIL';
export const CHANGE_CV_DETAIL = 'CHANGE_CV_DETAIL';
export const CV_BUILDER = 'CV_BUILDER';
export const CV_STEP = 'CV_STEP';
export const SET_NAVBAR_TITLE = 'SET_NAVBAR_TITLE';
export const SET_FILTER_MENTOR = 'SET_FILTER_MENTOR';
export const ATS_PARSE = 'ATS_PARSE';
export const OPEN_GENERAL_RATING = 'OPEN_GENERAL_RATING';
export const OPEN_CV_RATING = 'OPEN_CV_RATING';
export const OPEN_ATS_RATING = 'OPEN_ATS_RATING';
export const CLOSE_GENERAL_RATING = 'CLOSE_GENERAL_RATING';
export const CLOSE_CV_RATING = 'CLOSE_CV_RATING';
export const CLOSE_ATS_RATING = 'CLOSE_ATS_RATING';
