const termsAndConditions = {
  header: `Harap untuk membaca Syarat dan Ketentuan ini secara seksama. Anda harus
  membaca, memahami, menerima dan menyetujui semua Syarat dan Ketentuan ini
  sebelum menggunakan dan/atau mengakses produk dan layanan yang terdapat di
  situs Aksel.`,
  contents: [
    {
      title: `I. Definisi dan Penafsiran`,
      description: <b>Definisi ini berlaku:</b>,
      bodies: [
        {
          content: `(a) “Anda” berarti`,
          subContents: [
            '(i) pengunjung, pembaca, pengguna situs;',
            '(ii) pengguna jasa, dan atau;',
            '(iii) pihak lain menggunakan dan menerima Layanan, selain penyedia Jasa.',
          ],
        },
        {
          content: `(b) “Kami” berarti Pihak dan Situs Aksel`,
        },
        {
          content: `(c) “Mentor” berarti Pembimbing atau Pemberi jasa Mentoring yang di sediakan oleh pihak Aksel`,
        },
        {
          content: `(d) “Menti” berarti anda atau user sebagai calon pengguna jasa atau pengguna jasa Aksel`,
        },
        {
          content: `(e) “Situs” adalah situs yang dikelola Aksel dan beralamat di https://links.Aksel.co.id`,
        },
        {
          content: `(f) “Akun” berarti akun yang Anda peroleh setelah pendaftaran melalui situs Aksel yaitu
          https://links.Aksel.co.id untuk menggunakan dan menerima layanan dari Aksel melalui
          Situs.`,
        },
        {
          content: `(g) ‘Batch” adalah gelombang waktu pelaksanaan Layanan`,
        },
        {
          content: `(h) “Daftar Keterangan” berarti keterangan yang diperlukan untuk pelaksanaan layanan,
          termasuk tapi tidak terbatas pada Informasi Pribadi Anda, keterangan yang disediakan baik melalui Situs atau di luar Situs, sebagaimana dapat diminta oleh dan apabila dipandang
          perlu oleh kami untuk pelaksanaan layanan dari waktu ke waktu.`,
        },
        {
          content: `(i) “Penyedia Jasa” berarti pihak Aksel dan pihak-pihak yang terlah berafiliasi untuk bekerja
          sama dengan Aksel yang memberikan layanan CV Maker dan Mentoring pada pengguna
          jasa.`,
        },
        {
          content: `(j) “Pengguna Jasa” berarti orang yang telah menggunakan dan/atau mengakses Situs Aksel
          dan/atau mengajukan permohonan untuk mendapatkan layanan dari Penyedia Jasa melalui links.Aksel.co.id`,
        },
        {
          content: `(k) “Hari Kerja” adalah hari selain Sabtu, Minggu, dan hari libur nasional yang telah
          ditetapkan oleh pemerintah.`,
        },
        {
          content: `(l) “Jam Kerja” berarti jam 10.00 – 18.00 Waktu Indonesia Bagian Barat setiap Hari Kerja.`,
        },
        {
          content: `(m) “Informasi Pribadi”, berarti tiap-tiap dan seluruh data pribadi yang diberikan oleh
          Penyedia Jasa dan Pengguna Jasa, yaitu nama, nomor identitas, lokasi, kontak, serta
          dokumen dan data lainnya sebagaimana diminta pada formulir pendaftaran Akun atau
          formulir lainnya pada saat menggunakan situs Aksel.`,
        },
        {
          content: `(n) “Kebijakan Privasi” adalah ketentuan kebijakan privasi atas Layanan sebagaimana
          tercantum dalam Situs.`,
        },
        {
          content: `(o) “Layanan” adalah seluruh layanan yang disediakan oleh Aksel, baik melalui Situs atau di
          luar Situs, yang meliputi fasilitas untuk pemberian jasa layanan oleh Penyedia Jasa melalui
          telepon, surat elektronik (e-mail), pesan singkat (chat), dan layanan lainnya yang ditentukan
          oleh Aksel dari waktu ke waktu.`,
        },
        {
          content: `(p) “Materi” berarti artikel, panduan, penjelasan dan/atau keterangan lainnya sehubungan
          dengan Layanan yang disediakan oleh Aksel pada Situs.`,
        },
        {
          content: `(q) “Biaya Layanan” adalah biaya yang akan dibayarkan oleh Pengguna Jasa kepada
          Penyedia Jasa melalui Aksel untuk mendapatkan Layanan.`,
        },
      ],
    },
    {
      title: `II. Pendaftaran Akun dan Akses Pada Situs Aksel`,
      description: `Dengan menggunakan atau berpartisipasi dalam situs layanan Aksel, Anda
      menyatakan dan menjamin bahwa:`,
      bodies: [
        {
          content: `(a) Anda harus berusia minimal 18 (delapan belas) tahun atau sudah menikah dan
          tidak berada di bawah perwalian atau pengampuan agar Anda secara hukum sudah
          memiliki kapasitas dan berhak mengikatkan diri pada syarat dan Ketentuan ini. Jika
          usia Anda mendaftar, mengakses, menggunakan atau melakukan aktivitas lain dalam
          Situs kami harus dalam sepengetahuan, pengawasan dan persetuan yang sah dari
          orang tua atau wali atau pengampu Anda. Orang tua, wali atau pengampu yang
          memberikan persetujuan bagi Anda yang berusia di bawah 18 (delapan belas) tahun
          wajib bertanggung jawab secara penuh atas seluruh tindakan Anda dalam
          penggunaan dan akses terhadap Situs dan Layanan.`,
        },
        {
          content: `(b) Anda setuju untuk menjadi Pengguna Jasa sesuai dengan Syarat dan Ketentuan
          ini.`,
        },
        {
          content: `(c) Anda bertanggung jawab terhadap ketersediaan koneksi internet, biaya
          telekomunikasi dan/atau biaya lainnya`,
        },
        {
          content: `(d) Kami melakukan upaya sebaik-baiknya agar situs dapat diakses dan digunakan
          oleh Anda tanpa gangguan dalam bentuk apapun, namun Aksel tidak memberikan
          jaminan situs akan senantiasa dapat digunakan dan dilakukan akses dari waktu ke
          waktu.`,
        },
        {
          content: `(e) Layanan melalui situs sewaktu-waktu dapat dihentikan atau ditunda untuk
          sementara waktu karena pemeriksaan, pemeliharaan, perbaikan, perubahan,
          penambahan sistem pada Situs. Apabila terdapat gangguan terhadap Situs dan/atau
          sistem pembayaran yang disediakan pada Situs yang disebabkan karena alasan
          apapun termasuk tapi tidak terbatas pada gangguan virus, jaringan internet, Aksel
          akan memberikan pemberitahuan mengenai gangguan tersebut melalui Situs.`,
        },
        {
          content: `(f) Kami tidak bertanggungjawab kepada Anda dan/atau pihak manapun atas segala
          akibat yang timbul sehubungan dengan penggunaan Situs atau tidak dapat
          digunakannya Situs, baik sebagian maupun seluruhnya, yang disebabkan karena,
          termasuk namun tidak terbatas pada, gangguan virus, malware atau gangguan
          lainnya yang berada di luar kekuasaan Aksel dan dapat mempengaruhi
          beroperasinya Situs.`,
        },
        {
          content: `(g) Satu Pengguna Jasa hanya dapat memiliki satu Akun. Anda tidak dapat
          mengalihkan penggunaan Akun kepada pihak lain. Aksel tidak bertanggung jawab
          atas penggunaan Akun Anda oleh pihak lain dengan alasan apapun.`,
        },
        {
          content: `(h) Akses masuk ke Situs memerlukan alamat surel (E-mail) Pengguna Jasa. Anda
          bertanggung jawab secara penuh atas keamanan kata sandi Akun Anda dan akan
          segera memberitahukan kepada Aksel apabila keamanan tersebut telah atau
          dicurigai telah dirusak, ditembus atau digunakan oleh pihak lain tanpa persetujuan
          Anda.`,
        },
        {
          content: `(i) Kami berhak untuk menangguhkan atau menghentikan sebuah Akun untuk jangka
          waktu sementara ataupun seterusnya, dengan melakukan pemberitahuan sesegera
          mungkin, apabila Aksel memiliki dugaan bahwa Akun telah dirusak, ditembus atau
          digunakan oleh pihak lain tanpa persetujuan pemegang hak yang sah atas suatu
          Akun.`,
        },
      ],
    },
    {
      title: `III. Layanan`,
      bodies: [
        {
          content: `(a) Aksel adalah platform yang mempertemukan pihak yang membutuhkan jasa CV
          Builder, CV Review dan Aksel Career Mentoring dari Penyedia Jasa yang menjadi
          mitra Aksel.`,
        },
        {
          content: `(b) Dalam pelaksanaan layanan, apabila dipandang perlu oleh Aksel, kami berhak
          dan diberikan kewenangan oleh Pengguna Jasa untuk menunjuk atau mengalihkan
          seluruh atau sebagian Layanan kepada pihak lain tanpa pemberitahuan terlebih
          dahulu kepada Pengguna Jasa.`,
        },
        {
          content: `(c) Kami hanya akan memulai Layanan berdasarkan tahap yang telah ditentukan
          oleh Aksel, dengan ketentuan dan persetujuan yang telah disepakati oleh Menti
          sebagai calon pengguna jasa Aksel.`,
        },
        {
          content: `(d) Kami tidak memiliki kewajiban untuk menerjemahkan dokumen dalam Bahasa
          Indonesia ke dalam bahasa asing dan/atau sebaliknya`,
        },
        {
          content: `(e) Kami berhak untuk mendapatkan pembayaran dari Pengguna Jasa berdasarkan
          ketentuan pembayaran sebagaimana diatur dalam Syarat dan Ketentuan ini`,
        },
        {
          content: `(g) Kami berhak untuk menolak permintaan atau mengakhiri Layanan apabila
          berdasarkan pandangan dan pendapat Aksel terdapat indikasi pemalsuan, penipuan
          dan/atau tindakan lain yang melanggar peraturan perundang-undangan yang
          berlaku.`,
        },
      ],
    },
    {
      title: `IV. Pelaksanaan Layanan`,
      description: `Dengan tidak mengesampingkan ketentuan lainnya dalam syarat dan ketentuan ini:`,
      bodies: [
        {
          content: `(a) Anda bertanggung jawab atas segala pertanyaan, pernyataan, keterangan yang
          disampaikan atau dikirim kepada Aksel baik melalui Situs maupun di luar Situs.
          Segala keterangan yang diperlukan untuk pelaksanaan layanan sehubungan dengan
          Anda sebagaimana diperlukan untuk pelaksanaan layanan, harus dipilih, ditentukan,
          disediakan, diisi dan/atau dilengkapi oleh Anda dalam Daftar Keterangan.`,
        },
        {
          content: `(b) Anda menyatakan dan menjamin seluruh dokumen dan keterangan yang
          diberikan kepada Aksel, baik secara tertulis maupun tidak tertulis, baik itu dokumen
          yang disiapkan oleh Aksel maupun tidak, baik melalui Situs maupun di luar Situs,
          baik secara langsung maupun tidak langsung sehubungan dengan Layanan,
          sebagaimana diberikan oleh Anda atau pihak lain yang ditunjuk oleh Anda:`,
          subContents: [
            `(i) telah dibaca, dipahami, disetujui dan/atau ditandatangani oleh atau pihak lainnya
            yang memiliki kepentingan atas keterangan yang diberikan kepada Aksel tersebut,`,
            `(ii) merupakan keterangan yang benar, tepat, akurat, tidak menyesatkan, sesuai
            keadaan yang sebenarnya termasuk tapi tidak terbatas pada kebenaran, keaslian
            dan keabsahan identitas dan tanda tangan oleh pihak penandatangan dalam setiap
            dokumen yang diberikan kepada Aksel oleh Anda.`,
          ],
        },
        {
          content: `(c) Anda menyatakan dan menjamin pemberian keterangan kepada Aksel tidak
          melanggar peraturan perundang-undangan yang berlaku, rahasia dagang dan
          perjanjian apapun yang terkait termasuk tapi tidak terbatas pada perjanjian
          kerahasiaan (apabila ada).`,
        },
        {
          content: `(d) Anda memahami bahwa peraturan perundang-undangan dan kebijakan
          pemerintah yang berwenang dapat berubah sewaktu-waktu.`,
        },
        {
          content: `(e) Anda mengakui dan memahami bahwa dengan adanya penundaan, kelalaian
          dan/atau tidak dapat dipenuhinya Daftar Keterangan oleh Anda dapat
          mengakibatkan:`,
          subContents: [
            `(i) tertundanya pengajuan Layanan;`,
            `(ii) kelanjutan Layanan; memperlambat waktu proses dilaksanakannya Layanan,
            dan/atau;`,
            `(iii) dialihkannya Layanan anda untuk mengikuti pada Layanan batch selanjutnya.`,
          ],
        },
        {
          content: `(f) Penyedia Jasa akan menghubungi Pengguna Jasa melalui fitur yang disediakan
          Aksel setelah Pengguna Jasa memilih untuk menggunakan Jasa yang diberikan oleh
          Aksel`,
        },
        {
          content: `(g) Apabila Pengguna Jasa tidak dapat dihubungi, maka Penyedia Jasa akan mencoba
          menghubungi kembali Pengguna Jasa paling banyak 3 (tiga) kali kesempatan dalam
          rentang waktu yang telah Pengguna Jasa pilih. Apabila dalam rentang waktu
          dimaksud, Pengguna Jasa masih belum dapat dihubungi, maka Aksel menganggap
          Pengguna Jasa telah melepaskan haknya atas Layanan. Dengan demikian Biaya
          Layanan yang telah dibayar dengan sendirinya menjadi milik kami.`,
        },
      ],
    },
    {
      title: `V. Ketentuan Layanan`,
      bodies: [
        {
          content: `(a) Kami memberikan Materi Layanan dan Jasa sesuai dengan pilihan Layanan dan
          Lingkup yang hanya Anda pilih.`,
        },
        {
          content: `(b) Dalam hal Menti memilih Jasa layanan Aksel yaitu Career Mentoring, Menti berhak
          untuk:`,
          subContents: [
            `(i) Mendapatkan informasi jadwal ketersediaan para Mentor;`,
            `(ii) Mendapatkan informasi Profil mentor yang terbatas pada Nama, Latar belakang
            Pendidikan, dan Riwayat Pekerjaan Mentor dalam hal informasi ini diberikan untuk
            Menti dalam memilih Mentor yang akan dipilih.`,
          ],
        },
        {
          content: `(c) Menti wajib untuk memilih durasi layanan yang telah ditentukan.`,
        },
        {
          content: `(d) Menti wajib untuk melakukan pembayaran sesuai dengan Jasa yang dipilih dan
          jumlah sebanyak yang telah ditentukan.`,
        },
        {
          content: `(e) Menti wajib memberikan ulasan atau feedback kepada mentor setelah sesi
          mentoring selesai.`,
        },
        {
          content: `(f) Pada tahap pertama kali Menti memilih dan memesan jasa layanan Career mentoring tahap ini terhitung sebagai tahap percobaan atau tahap trial.`,
        },
        {
          content: `(g) Menti berhak untuk mendapatkan jaminan penuh pengembalian dana atau refund
          pada tahap percobaan, dengan syarat dan ketentuan sebagai berikut:`,
          subContents: [
            `(i) Mentor yang dipilih oleh Menti tidak dapat memenuhi ekspetasi yang telah
            disepakati oleh kedua belah pihak yaitu Mentor dan Menti pada awal sesi;`,
            `(ii) Mentor mengalami kendala atau gangguan koneksi internet pada saat
            melakukan Mentoring melebihi jangka waktu maksimum yang telah ditentukan.`,
            `(iii) Mentor tidak memenuhi durasi mentoring yang dipilih untuk Menti.`,
          ],
        },
        {
          content: `(h) Kami dapat memberikan pengembalian dana atau refund sebanyak 100% (seratus
            persen) hanya kepada Menti yang memenuhi syarat dan ketentuan yang berlaku.
            Dengan Syarat dan ketentuan:`,
          subContents: [
            `(i) Pengembalian dana dapat diajukan dengan tahapan awal Menti mengirimkan
            data lengkap Menti ke alamat surel kami (e-mail) team@aksel.co.id;`,
            `(ii) Menti memberitahukan secara lengkap dan jelas alasan mengajukan
            pengembalian dana;`,
            `(iii) Kami hanya akan memproses pengembalian dana bila Menti telah memenuhi
            syarat poin (i) dan (ii);`,
            `(iv) Kami terlebih dahulu akan secara detail menelaah sebab tidak terpenuhinya
            ekspetasi antara Mentor dan Menti;`,
            `(v) Kami hanya akan melakukan pengembalian dana apabila penyebab ekspetasi
            antara Mentor dan Menti telah di telaah oleh kami dan terbukti bahwa tidak
            tercapainya ekspetasi yang di bangun antara Mentor dan Menti;`,
            `(vi) Jumlah dana yang dikembalikan kepada Anda tidak lebih besar dari jumlah
            nominal yang Anda bayarkan kepada kami.`,
          ],
        },
      ],
    },
    {
      title: `VI. Tanggung Jawab Anda`,
      bodies: [
        {
          content: `(a) Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk
          menggunakan atau mengakses platform kami.`,
        },
        {
          content: `(b) Anda harus berperilaku dengan hormat dan tidak boleh terlibat dalam perilaku atau
          tindakan yang tidak sah, mengancam atau melehcehkan ketika menggunakan jasa
          dan platform kami.`,
        },
        {
          content: `(c) Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang
          timbul dari penggunaan Platform melalui Akun Anda, baik oleh Anda atau pihak lain
          yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Syarat dan
          Ketentuan ini, Kebijakan Privasi atau peraturan perundang-undangan yang berlaku.`,
        },
        {
          content: `(d) Anda bertanggung jawab untuk tidak menyebarluaskan kepada khalayak luas
          terhadap materi yang diberikan oleh pihak kami.`,
        },
        {
          content: `(e) Kami tidak menjamin bahwa Platform Kami akan aman atau terbebas dari bug atau
          virus. Anda bertanggung jawab untuk mengatur teknologi informasi, program
          komputer, serta platform yang Anda gunakan untuk mengakses Platform Kami. Anda
          harus menggunakan perangkat lunak anti virus Anda sendiri.`,
        },
      ],
    },
    {
      title: `VII. Batasan Tanggung Jawab Kami`,
      description: (
        <p>
          Platform yang Kami sediakan adalah sebagaimana adanya dan Kami tidak
          menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas,
          kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari
          Platform dapat memenuhi kebutuhan dan akan sesuai dengan harapan Anda.{' '}
          <br /> <br />
          Kami tidak bertanggung jawab kepada pengguna mana pun atas kerugian
          atau kerusakan, baik dalam bentuk kontrak, perbuatan melawan hukum
          (termasuk kelalaian), pelanggaran kewajiban berdasarkan undang-undang,
          atau sebaliknya, meskipun dapat diperkirakan, yang terjadi di bawah
          atau berhubungan dengan:
        </p>
      ),
      bodies: [
        {
          content: `(a) Penggunaan, atau ketidakmampuan untuk menggunakan, Platform Kami; atau`,
        },
        {
          content: (
            <p>
              (b) Penggunaan atau kepercayaan terhadap konten apa pun yang
              ditampilkan pada Platform Kami. <br />
              <br />
              Kami hanya menyediakan Platform untuk penggunaan domestik dan
              pribadi. Anda setuju untuk tidak menggunakan Platform Kami untuk
              tujuan komersial atau bisnis apa pun, dan Kami tidak bertanggung
              jawab kepada Anda atas kerugian, kehilangan usaha, gangguan usaha,
              maupun hilangnya kesempatan bisnis.
            </p>
          ),
        },
      ],
    },
    {
      title: `VIII. Keadaan Kahar (Force Majeur)`,
      description: `Platform Kami dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami
      (“Keadaan Kahar”), termasuk namun tidak terbatas pada bencana alam, gangguan
      listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju
      untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak
      dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan
      melalui Platform, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.`,
    },
    {
      title: `IX. Biaya Layanan dan Ketentuan Pembayaran`,
      bodies: [
        {
          content: `(a) Layanan ini adalah layanan berbayar dan ketentuannya akan diatur pada
          ketentuan tersendiri dan merupakan satu kesatuan dengan syarat dan ketentuan ini.`,
        },
        {
          content: `(b) Anda memahami bahwa PT Kreasi Pemuda Bangsa dari waktu ke waktu dapat
          mengubah harga atau memberikan uji coba dan penawaran khusus yang dapat
          mengakibatkan jumlah yang dikenakan kepada Pengguna tertentu menjadi berbeda.
          Anda setuju dan menerima perubahan harga dengan terus menggunakan akses yang
          disediakan oleh Aksel.`,
        },
      ],
    },
    {
      title: `X. Pengakhiran Layanan`,
      description: `Dengan tidak mengesampingkan hak-hak Aksel berdasarkan Syarat dan Ketentuan
      serta peraturan perundang-undangan yang berlaku, Layanan diakhiri apabila (mana
      yang terjadi lebih dahulu):`,
      bodies: [
        {
          content: `(a) Pengguna Jasa tidak memenuhi pembayaran sesuai dengan Biaya Layanan dan
          ketentuan pembayaran;`,
        },
        {
          content: `(b) Pengguna Jasa mengajukan permohonan secara tertulis kepada Aksel;`,
        },
        {
          content: `(c) Pengguna Jasa dinilai oleh Aksel telah melakukan pelanggaran Syarat dan
          Ketentuan dan peraturan perundangan yang berlaku;`,
        },
        {
          content: (
            <p>
              (d) Adanya indikasi penipuan, pemalsuan dan/atau kejahatan
              sehubungan dengan Layanan yang dilakukan oleh Pengguna Jasa.{' '}
              <br />
              <br />
              Dengan berakhirnya Layanan, Pengguna Jasa mengakui dan menyatakan
              Aksel tidak lagi memiliki kewajiban untuk melanjutkan Layanan.
            </p>
          ),
        },
      ],
    },
    {
      title: `XI. Penyelesaian Sengketa`,
      bodies: [
        {
          content: `Anda dengan ini membebaskan Kami dari segala macam tuntutan, gugatan, atau
          tindakan hukum lainnya, baik dalam sebuah gugatan perdata maupun setiap
          gugatan pidana yang dialami oleh Anda, dalam bentuk apapun terkait dengan jasa
          yang ditawarkan, maupun disediakan, diselenggarakan atau diselesaikan oleh Anda
          melalui Platform.`,
        },
        {
          content: `Apabila timbul perselisihkan sehubungan dengan penafsiran dan/atau pelaksanaan
          dari Syarat dan Ketentuan ini maka Kami dan Anda sepakat untuk menyelesaikan
          perselisihan dimaksud secara musyawarah terlebih dahulu.`,
        },
        {
          content: `Apabila perselisihan tidak dapat diselesaikan secara musyawarah maka Para Pihak
          sepakat untuk menyelesaikan perselisihan dimaksud melalui Pengadilan Negeri
          Jakarta Selatan, dengan tidak mengurangi hak Kami untuk mengajukan laporan,
          gugatan atau tuntutan baik perdata maupun pidana melalui Pengadilan Negeri,
          Kepolisian atau instansi terkait lainnya dalam wilayah Negara Republik Indonesia.
          ATAU SECARA ARBITRASE.`,
        },
      ],
    },
    {
      title: `XII. KETENTUAN LAINNYA`,
      bodies: [
        {
          content: `Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini merupakan perjanjian
          dalam bentuk elektronik dan tindakan Anda menekan tombol ‘daftar’ saat
          pembukaan Akun atau tombol ‘masuk’ saat akan mengakses Akun Anda merupakan
          persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami
          sehingga keberlakuan Syarat dan Ketentuan ini dan Kebijakan Privasi adalah sah dan
          mengikat secara hukum dan terus berlaku sepanjang penggunaan Platform oleh
          Anda.`,
        },
        {
          content: `Kami dapat mengubah Syarat dan Ketentuan ini dari waktu ke waktu, perubahan
          mana akan diberitahukan kepada Anda dalam bentuk dan melalui sarana apapun,
          termasuk namun tidak terbatas pada pemberitahuan melalui e-mail; dan Anda
          setuju bahwa Anda bertanggung jawab untuk meninjau Syarat dan Ketentuan ini
          secara berkala. Penggunaan secara berkelanjutan oleh Anda atas layanan yang
          diberikan dalam Platform setelah perubahan dan/atau penambahan Syarat dan
          Ketentuan yang berlaku, akan dianggap sebagai persetujuan dan penerimaan Anda
          atas perubahan dan/atau penambahan tersebut. Anda dapat menyampaikan
          keberatan atas perubahan dan/atau penambahan atas Syarat dan Ketentuan yang
          berlaku yang dianggap merugikan Anda secara komersial dan material dalam jangka
          waktu 14 (empat belas) hari kalender sejak perubahan dan/atau penambahan
          tersebut dipublikasikan. Kami berhak untuk menghentikan akses Anda terhadap
          Platform dalam hal Anda berkeberatan atas perubahan dan/atau penambahan
          Syarat dan Ketentuan yang berlaku tersebut.`,
        },
        {
          content: `Bila Anda tidak mematuhi atau melanggar ketentuan dalam Syarat dan Ketentuan
          ini, dan Kami tidak mengambil tindakan secara langsung, bukan berarti Kami
          mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di
          kemudian hari.`,
        },
        {
          content: `Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan
          permanen, penghapusan Platform atau setelah berakhirnya perjanjian ini antara
          Anda dan Kami.`,
        },
        {
          content: `Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini tidak dapat
          diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.`,
        },
      ],
    },
    {
      title: `XIII. Cara Menghubungi Kami`,
      description: `Untuk menghubungi kami, silahkan kirim surat elektronik ke team@aksel.co.id`,
    },
  ],
};

export default termsAndConditions;
