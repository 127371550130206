import { useState } from 'react';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Button,
  DialogActions,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { EmptyRatingIcon, FullRatingIcon } from 'src/assets/icons/Landing';

import CustomButton from 'src/components/button';
import { closeCVRating } from 'src/redux/actions/rating-actions';

function Logo({ ...rest }) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <circle cx='40' cy='40' r='40' fill='#FACD11' fill-opacity='0.2' />
      <path
        d='M60.9488 58.8885C60.9488 61.8689 58.5324 64.2853 55.552 64.2853H25.8695C22.8891 64.2853 20.4727 61.8689 20.4727 58.8885V21.1107C20.4727 18.1303 22.8891 15.7139 25.8695 15.7139H55.552C58.5324 15.7139 60.9488 18.1303 60.9488 21.1107V58.8885Z'
        fill='#FFB54A'
      />
      <path
        d='M55.5497 48.0928C55.5497 48.8389 54.9466 49.442 54.2005 49.442H27.2164C26.4716 49.442 25.8672 48.8389 25.8672 48.0928C25.8672 47.3466 26.4716 46.7436 27.2164 46.7436H54.2005C54.9466 46.7436 55.5497 47.3466 55.5497 48.0928ZM33.9624 53.4896C33.9624 54.2357 33.358 54.8388 32.6132 54.8388H27.2164C26.4716 54.8388 25.8672 54.2357 25.8672 53.4896C25.8672 52.7435 26.4716 52.1404 27.2164 52.1404H32.6132C33.358 52.1404 33.9624 52.7435 33.9624 53.4896ZM55.5497 26.5055C55.5497 27.2502 54.9466 27.8547 54.2005 27.8547H27.2164C26.4716 27.8547 25.8672 27.2502 25.8672 26.5055C25.8672 25.7607 26.4716 25.1562 27.2164 25.1562H54.2005C54.9466 25.1562 55.5497 25.7607 55.5497 26.5055ZM55.5497 31.9023C55.5497 32.6484 54.9466 33.2515 54.2005 33.2515H27.2164C26.4716 33.2515 25.8672 32.6484 25.8672 31.9023C25.8672 31.1562 26.4716 30.5531 27.2164 30.5531H54.2005C54.9466 30.5531 55.5497 31.1562 55.5497 31.9023ZM55.5497 37.2991C55.5497 38.0452 54.9466 38.6483 54.2005 38.6483H27.2164C26.4716 38.6483 25.8672 38.0452 25.8672 37.2991C25.8672 36.553 26.4716 35.9499 27.2164 35.9499H54.2005C54.9466 35.9499 55.5497 36.553 55.5497 37.2991ZM55.5497 42.6959C55.5497 43.442 54.9466 44.0451 54.2005 44.0451H27.2164C26.4716 44.0451 25.8672 43.442 25.8672 42.6959C25.8672 41.9498 26.4716 41.3467 27.2164 41.3467H54.2005C54.9466 41.3467 55.5497 41.9498 55.5497 42.6959Z'
        fill='white'
      />
      <path
        d='M43.6047 46.1061C43.6047 46.1061 41.8858 43.5683 40.7484 42.6616C39.8418 41.5242 37.3093 39.8067 37.3093 39.8067C36.7049 39.2049 35.7024 39.1591 35.1115 39.7487C34.5219 40.3396 34.0415 41.5917 33.5288 42.8397C32.892 44.3859 31.8073 48.4443 31.1435 50.6003C31.0315 50.9673 31.841 49.9932 31.8221 50.317C31.8086 50.5693 31.8585 50.8486 31.9219 51.1009L31.725 51.3154L32.0056 51.4058C32.0393 51.5165 32.0731 51.6136 32.0973 51.6892L32.3119 51.4922C32.5642 51.5556 32.8434 51.6055 33.0944 51.592C33.4182 51.5731 32.4427 52.384 32.8111 52.2707C34.9671 51.6055 39.0269 50.5221 40.5717 49.8853C41.8184 49.3712 43.0731 48.8922 43.6627 48.3013C44.255 47.7144 44.2105 46.7119 43.6047 46.1061Z'
        fill='#D99E82'
      />
      <path
        d='M53.2731 21.5826C52.0912 22.7631 52.0912 24.6763 53.2731 25.8568L57.5461 30.1311C58.728 31.3103 60.6412 31.3103 61.8204 30.1311L66.096 25.8568C67.2752 24.6763 67.2752 22.7631 66.096 21.5826L61.8204 17.3083C60.6412 16.1277 58.728 16.1277 57.5461 17.3083L53.2731 21.5826V21.5826Z'
        fill='#E86258'
      />
      <path
        d='M35.1094 39.7489L39.3864 44.0259L43.6566 48.2975L59.6865 32.2703L51.1366 23.7217L35.1094 39.7489V39.7489Z'
        fill='#FACD11'
      />
      <path
        d='M34.3655 51.7964C34.3655 51.7964 31.5862 53.3251 30.836 52.5736C30.0845 51.8207 31.6199 49.0508 31.6199 49.0508C31.6199 49.0508 34.2522 49.0994 34.3655 51.7964Z'
        fill='#292F33'
      />
      <path
        d='M51.1367 23.7188L56.4796 18.376L65.0281 26.9259L59.6853 32.2688L51.1367 23.7188Z'
        fill='#1D2D5E'
      />
      <path
        d='M54.3428 20.5158L55.4114 19.4473L63.96 27.9945L62.8914 29.0631L54.3428 20.5158ZM52.207 22.653L53.2743 21.5858L61.8228 30.133L60.7543 31.2016L52.207 22.653Z'
        fill='#89D2F1'
      />
      <path
        d='M60.9488 58.8885C60.9488 61.8689 58.5324 64.2853 55.552 64.2853H25.8695C22.8891 64.2853 20.4727 61.8689 20.4727 58.8885V21.1107C20.4727 18.1303 22.8891 15.7139 25.8695 15.7139H55.552C58.5324 15.7139 60.9488 18.1303 60.9488 21.1107V58.8885Z'
        fill='#FFB54A'
      />
      <path
        d='M55.5497 48.0928C55.5497 48.8389 54.9466 49.442 54.2005 49.442H27.2164C26.4716 49.442 25.8672 48.8389 25.8672 48.0928C25.8672 47.3466 26.4716 46.7436 27.2164 46.7436H54.2005C54.9466 46.7436 55.5497 47.3466 55.5497 48.0928ZM33.9624 53.4896C33.9624 54.2357 33.358 54.8388 32.6132 54.8388H27.2164C26.4716 54.8388 25.8672 54.2357 25.8672 53.4896C25.8672 52.7435 26.4716 52.1404 27.2164 52.1404H32.6132C33.358 52.1404 33.9624 52.7435 33.9624 53.4896ZM55.5497 26.5055C55.5497 27.2502 54.9466 27.8547 54.2005 27.8547H27.2164C26.4716 27.8547 25.8672 27.2502 25.8672 26.5055C25.8672 25.7607 26.4716 25.1562 27.2164 25.1562H54.2005C54.9466 25.1562 55.5497 25.7607 55.5497 26.5055ZM55.5497 31.9023C55.5497 32.6484 54.9466 33.2515 54.2005 33.2515H27.2164C26.4716 33.2515 25.8672 32.6484 25.8672 31.9023C25.8672 31.1562 26.4716 30.5531 27.2164 30.5531H54.2005C54.9466 30.5531 55.5497 31.1562 55.5497 31.9023ZM55.5497 37.2991C55.5497 38.0452 54.9466 38.6483 54.2005 38.6483H27.2164C26.4716 38.6483 25.8672 38.0452 25.8672 37.2991C25.8672 36.553 26.4716 35.9499 27.2164 35.9499H54.2005C54.9466 35.9499 55.5497 36.553 55.5497 37.2991ZM55.5497 42.6959C55.5497 43.442 54.9466 44.0451 54.2005 44.0451H27.2164C26.4716 44.0451 25.8672 43.442 25.8672 42.6959C25.8672 41.9498 26.4716 41.3467 27.2164 41.3467H54.2005C54.9466 41.3467 55.5497 41.9498 55.5497 42.6959Z'
        fill='white'
      />
      <path
        d='M60.9496 24.1784C59.8338 23.4566 58.4711 23.3972 57.5482 24.1473L56.4796 25.0189L54.4774 26.6528L54.3425 26.7608L51.1368 29.3755L35.1109 42.4439C34.5213 42.9256 34.041 44.0886 33.5283 45.2422C33.0183 46.389 32.2209 49.0901 31.5814 51.1666C31.3817 51.5039 30.1445 53.6572 30.838 54.4762C31.5396 55.3059 33.9762 54.4897 34.3027 54.3736C36.4965 54.1389 39.3366 53.8704 40.5738 53.5911C41.8205 53.3064 43.0753 53.0581 43.6649 52.5765C43.6757 52.5684 43.6784 52.5522 43.6878 52.5454L59.6867 39.504L60.7553 38.6324L60.9496 38.4732V24.1784Z'
        fill='#66757F'
      />
      <path
        d='M43.6047 46.1061C43.6047 46.1061 41.8858 43.5683 40.7484 42.6616C39.8418 41.5242 37.3093 39.8067 37.3093 39.8067C36.7049 39.2049 35.7024 39.1591 35.1115 39.7487C34.5219 40.3396 34.0415 41.5917 33.5288 42.8397C32.892 44.3859 31.8073 48.4443 31.1435 50.6003C31.0315 50.9673 31.841 49.9932 31.8221 50.317C31.8086 50.5693 31.8585 50.8486 31.9219 51.1009L31.725 51.3154L32.0056 51.4058C32.0393 51.5165 32.0731 51.6136 32.0973 51.6892L32.3119 51.4922C32.5642 51.5556 32.8434 51.6055 33.0944 51.592C33.4182 51.5731 32.4427 52.384 32.8111 52.2707C34.9671 51.6055 39.0269 50.5221 40.5717 49.8853C41.8184 49.3712 43.0731 48.8922 43.6627 48.3013C44.255 47.7144 44.2105 46.7119 43.6047 46.1061Z'
        fill='#D99E82'
      />
      <path
        d='M53.2731 21.5826C52.0912 22.7631 52.0912 24.6763 53.2731 25.8568L57.5461 30.1311C58.728 31.3103 60.6412 31.3103 61.8204 30.1311L66.096 25.8568C67.2752 24.6763 67.2752 22.7631 66.096 21.5826L61.8204 17.3083C60.6412 16.1277 58.728 16.1277 57.5461 17.3083L53.2731 21.5826V21.5826Z'
        fill='#E86258'
      />
      <path
        d='M35.1094 39.7489L39.3864 44.0259L43.6566 48.2975L59.6865 32.2703L51.1366 23.7217L35.1094 39.7489V39.7489Z'
        fill='#FACD11'
      />
      <path
        d='M34.3655 51.7964C34.3655 51.7964 31.5862 53.3251 30.836 52.5736C30.0845 51.8207 31.6199 49.0508 31.6199 49.0508C31.6199 49.0508 34.2522 49.0994 34.3655 51.7964Z'
        fill='#292F33'
      />
      <path
        d='M51.1367 23.7188L56.4796 18.376L65.0281 26.9259L59.6853 32.2688L51.1367 23.7188Z'
        fill='#1894C8'
      />
      <path
        d='M54.3428 20.5158L55.4114 19.4473L63.96 27.9945L62.8914 29.0631L54.3428 20.5158ZM52.207 22.653L53.2743 21.5858L61.8228 30.133L60.7543 31.2015L52.207 22.653Z'
        fill='#B6E3F6'
      />
    </svg>
  );
}

function IconContainer(props) {
  const { value, className, children, ...other } = props;
  const isDesktop = useMediaQuery('(min-width:1280px)');

  return (
    <span {...other} style={{ padding: isDesktop ? '0 8px' : '0 4px' }}>
      {children}
    </span>
  );
}

const CVRating = ({ onClose }) => {
  const [value, setValue] = useState(null);
  const [description, setDescription] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const isDesktop = useMediaQuery('(min-width:1280px)');

  const handleSubmit = async () => {
    const feedbackRef = firebase.database().ref(`feedbacks/${user.uid}`);
    let oldValues;

    await feedbackRef.once('value', (snapshot) => {
      oldValues = snapshot.val() || [];
    });

    await feedbackRef.set(
      [
        ...oldValues,
        {
          ratingId: uuid(),
          rating: value,
          description: description,
          type: 'cvmaker',
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        },
      ],
      (err) => {
        if (err) {
          console.error(err);
        }
      },
    );

    closeCVRating();
  };

  return (
    <Box margin={'32px 0'} padding={'0 24px'}>
      <Logo style={{ display: 'block', margin: 'auto' }} />
      <Typography
        variant={isDesktop ? 'sectionHeader' : 'subHeader18'}
        sx={{
          textAlign: 'center',
          margin: isDesktop ? '12px 48px 0 48px' : '12px 0 0 0',
        }}
      >
        Apakah kamu menyukai penggunaan CV Maker?
      </Typography>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop={3}
      >
        <Rating
          name='highlight-selected-only'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          IconContainerComponent={IconContainer}
          icon={
            <FullRatingIcon
              fontSize='inherit'
              style={{ height: isDesktop ? '60px' : '40px' }}
            />
          }
          emptyIcon={
            <EmptyRatingIcon
              fontSize='inherit'
              style={{ height: isDesktop ? '60px' : '40px' }}
            />
          }
        />
        {value !== null && (
          <TextField
            size='small'
            fullWidth
            placeholder={
              isDesktop
                ? 'Ceritakan pengalaman kamu menggunakan CV Maker?'
                : 'Bagaimana pengalaman kamu menggunakan CV Maker?'
            }
            margin='normal'
            variant='outlined'
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        )}
      </Box>
      <DialogActions
        style={{
          justifyContent: value ? 'center' : 'flex-end',
          marginTop: '24px',
          gap: '12px',
        }}
      >
        {value ? (
          <>
            <CustomButton
              variant='outlined'
              color='secondary'
              onClick={onClose}
              sx={{
                padding: '6px 0',
                width: '240px',
                borderColor: '#fff',
              }}
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              sx={{ padding: '6px 0', width: '240px' }}
            >
              Kirim
            </CustomButton>
          </>
        ) : (
          <Button
            onClick={onClose}
            sx={{
              color: '#1D2D5E',
              padding: 0,
              borderColor: '#fff',
              textTransform: 'none',
            }}
          >
            Ingatkan lagi nanti
          </Button>
        )}
      </DialogActions>
    </Box>
  );
};

export default CVRating;
