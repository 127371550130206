const testimony = [
  {
    photoUrl: '/static/images/testimony/ika.jpeg',
    name: 'Ika',
    title: 'Universitas Sebelas Maret - Sosiologi',
    content:
      'Setelah mentoring aku ngerasa mindful banyak banget hal-hal yang harus dipersiapkan untuk menjadi HR. Happy bgt ketemu mentor yang seru, pembawaannya asik, dan give the best advice for me.',
  },
  {
    photoUrl: '/static/images/testimony/wira.jpeg',
    name: 'Wira',
    title: 'Institut Teknologi Bandung - Farmasi',
    content:
      'Sangat membantu persiapan karirku, terutama dalam hal menyusun CV dan Interview. Bagi yang ingin mendapatkan mentoring berkualitas dengan harga terjangkau, disarankan untuk book mentor di sini.',
  },
  {
    photoUrl: '/static/images/testimony/audrey.jpeg',
    name: 'Audrey',
    title: 'Universitas Prasetiya Mulya - SBE',
    content:
      "Mentoring session from Aksel really helps me with my doubts and questions around “working life”. The only thing I regret is that I didn't book a longer session.",
  },
  {
    photoUrl: '/static/images/testimony/katarina.jpeg',
    name: 'Katarina',
    title: 'Universitas Indonesia - Vokasi Komunikasi Penyiaran',
    content:
      'Mentornya capable, mampu menjawab semua kebingungan sekaligus review cv dan juga ngasih action plan. Ngasih rekomendasi career path juga. Suka bgt pokoknya!',
  },
];

module.exports = {
  testimony,
};
