const privacyPolicy = {
  header: (
    <>
      Harap untuk membaca Kebijakan Privasi ini secara seksama. Anda harus
      membaca, memahami, menerima dan menyetujui semua Kebijakan Privasi ini
      sebelum menggunakan dan/atau mengakses produk dan layanan yang terdapat di
      situs Aksel. <br /> <br />
      Dengan mengakses dan menggunakan situs PT Kreasi Pemuda Bangsa
      (“menghormati dan melindungi keamanan data dan informasi pribadi setiap
      pengguna (“Pengguna atau “Anda PT Kreasi Pemuda Bangsa” atau “kami) kami
      berkomitmen secara penuh dalam) pada saat mengakses situs kami maupun
      produk yang kami sediakan . Kebijakan Privasi ini berlaku bagi seluruh
      pengguna Platform links.aksel.co.id. <br />
      <br />
      Dengan mengakses dan/atau mendaftar Akun pada Platform Kami, Anda atau
      orang tua, wali atau pengampu Anda (bila Anda berusia di bawah 18 tahun)
      menyatakan, menyatakan, menerima dan menyetujui ketentuan-ketentuan yang
      terdapat dalam Kebijakan Privasi ini.
    </>
  ),
  contents: [
    {
      title: `I. Informasi Pribadi`,
      bodies: [
        {
          content: `(a) Anda menyetujui bahwa Kami berhak untuk mengumpulkan, menyimpan,
          mengolah dan menggunakan setiap informasi yang diberikan oleh Anda
          sebagaimana diatur juga dalam Kebijakan Privasi, di mana informasi tersebut
          termasuk namun tidak terbatas kepada Informasi Pribadi yang diberikan pada saat
          pendaftaran pada Platform maupun informasi lainnya yang tersedia kepada dan
          dapat diakses oleh Kami melalui pengunduhan, instalasi dan penggunaan Platform
          oleh Anda.`,
        },
        {
          content: `(b) Dalam hal terdapatnya sebuah perubahan atas Informasi Pribadi dan/atau
          Informasi Pribadi Anda, Anda setuju untuk menginformasikannya terhadap pihak
          Kami.`,
        },
        {
          content: `(c) Ketika Anda telah membuka Akun pada situs Aksel Anda tunduk pada Kebijakan
          Privasi yang tidak terpisahkan dari Syarat dan Ketentuan ini.`,
        },
      ],
    },
    {
      title: `II. Data dan Informasi Pribadi yang Kami Kumpulkan`,
      description: `Data dan Informasi yang dapat kami kumpulkan meliputi, namun tidak terbatas
      pada:`,
      bodies: [
        {
          subHeader: `a. Informasi yang Anda berikan.`,
          content: `Anda memberikan informasi yang anda isi dengan keadaan sadar melalui formulir
          elektronik yang ada pada Platform kami yang meliputi identitas pribadi berupa
          nama, nomor telpon, usia, alamat email, transaksi pembelian dan informasi lainnya
          yang Anda berikan melalui Platform.`,
        },
        {
          subHeader: `b. Informasi yang kami kumpulkan.`,
          content: `Kami dapat mengumpulkan informasi berikut secara otomatis untuk setiap
          kunjungan anda ke Platform kamu, yang meliputi:`,
          subContents: [
            `(i) Informasi teknis, meliputi alamat Protokol Internet (Ip Adress) yang
          menghubungkan computer Anda dengan internet, dan informasi log in Anda;`,
            `(ii) Informasi tentang kunjungan Anda pada platform kami, termasuk daftar lengkap
          locator sumber seragam URL (Uniform Resource Locators) yang dikunjungi menuju,
          melalui, dan dari Platform (termasuk tanggal dan waktu), produk yang Anda cari,
          lihat, pengunduhan, lama kunjungan, informasi dan interaksi pada halaman, serta
          nomor telepon yang digunakan untuk menghubungi layanan kami; dan`,
            `(iii) data pendidikan, akademik, prestasi, pekerjaan, dan informasi lainnya yang
          anda isi dalam hal Anda menggunakan produk kami berupa CV Maker.`,
          ],
        },
        {
          subHeader: `c. Informasi yang kami terima dari sumber lain.`,
          content: `Kami dapat menerima informasi jika Anda menggunakan layanan yang kami
          sediakan.`,
        },
        {
          subHeader: `d. Penggunaan Informasi Pribadi`,
          content: `Kami menggunakan Informasi Pribadi Anda untuk:`,
          subContents: [
            `(i) untuk memverifikaksi Data Probadi yang terdapat pada akun Anda;`,
            `(ii) untuk menjalankan kewajiban Kami dalam menyediakan informasi, produk, dan
            jasa kepada Anda;`,
            `(iii) untuk komunikasi secara langsung kepada pengguna platform`,
            `(iv) memberikan informasi dalam melakukan sosialisi ataupun promosi terkait
            produk dan jasa kami; dan`,
            `(v) mematuhi setiap peraturan perundang-undangan yang belaku.`,
          ],
        },
      ],
    },
    {
      title: `III. Hak Anda`,
      bodies: [
        {
          content: `Anda dapat meminta atau memohon kepada pihak Kami dalam hal penghapusan
          Data dan Informasi Pribadi Anda dengan memberitahukan kepada kami secara
          tertulis dengan cara menghubungi layanan kami pada halaman cara menghubungi 
          Kami. Setelah Kami menerima permintaan Anda untuk menarik persertujuanm
          pengumpulan, penggunaan atau pengungkapan Data Pribadi Anda, kami akan
          memberikan informasikan Anda tentang Konsekuensi yang mungkin akan terjadi di
          kemudian hari atas penarikan tersebut sehingga Anda dapat memutuskan kembali
          apakah Anda tetap ingin menarik persetujuan penghapusan Data dan Informasi
          Pribadi Anda atau tidak.`,
        },
      ],
    },
    {
      title: `IV. Kerahasiaan dan Keamanan Data`,
      bodies: [
        {
          content: `Data Pribadi Anda Akan disimpan dan digunkan oleh PT Kreasi Pemuda Bangsa dan
          dijamin kerahasiannya. Kami tidak akan menggunakan data pribadi dengan tidak
          bijak seperti menjual, mengungkapkan atau memberikan Data Pribadi Anda kepada
          pihak ketiga manapun di luar PT Kreasi Pemuda Bangsa kecuali jika Kami memiliki
          persetujuan Anda.`,
        },
      ],
    },
    {
      title: `V. Perubahan Kebijakan Privasi`,
      bodies: [
        {
          content: `Kami berhak untuk mengubah, memodifikasi dan menambahkan Kebijakan Privasi
          ini dari waktu ke waktu dan setiap perubahan tersebut akan ditampilkan pada
          halaman ini. Kami akan memberitahukan kepada anda melalui surat elektronik atau
          media lainnya terkait Perubahan Kebijakan Privasi ini.`,
        },
      ],
    },
    {
      title: `VI. XV. Cara Menghubungi Kami`,
      bodies: [
        {
          content: `Untuk menghubungi kami, silahkan kirim surat elektronik ke team@aksel.co.id`,
        },
      ],
    },
  ],
};

export default privacyPolicy;
