import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  Avatar,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import reactGA from 'react-ga';
import firebase from 'firebase/app';
import { bookDraft, setNavbarTitle } from 'src/redux/actions';
import { checkIsFeedbackExist } from 'src/utils/utilities';
import { openGeneralRating } from 'src/redux/actions/rating-actions';
import ServicesAccordion from 'src/components/booking/ServicesAccordion';

const numberWithCommas = (x) => {
  if (x) {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  } else {
    return 0;
  }
};

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed
// reactGA.pageview('/bookingdetail'); // Main page

const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
  },
  buttonText: {
    color: '#1D2D5E',
    '&:hover': {
      color: '#fff',
    },
  },
  '@global': {
    '.MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
    '.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg) !important',
    },
  },
});

const BookingDetail = ({ bookDetail }) => {
  setNavbarTitle('Pesanan Saya');
  const classes = useStyles();
  const [stars, setStars] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [notCompletePayment, setNotCompletePayment] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleSubmit = async () => {
    reactGA.event({
      category: 'Mentoring',
      action: 'User Give Rating & Feedback',
      label: user.uid,
    });

    setIsSubmit(true);

    firebase
      .database()
      .ref()
      .child(`/bookings/${bookDetail.bookId}`)
      .update({ rate: stars, review }, (error) => {
        if (error) {
          alert(`Cannot submit review: ${error}`);
        } else {
          alert('Feedback successfully submitted');
        }
      });

    if (!(await checkIsFeedbackExist('general'))) {
      openGeneralRating();
    }
  };

  useEffect(() => {
    if (!bookDetail) {
      navigate('/app/booking', { replace: true });
    }

    const getRates = async () => {
      let rates = [];

      await firebase
        .database()
        .ref()
        .child('bookings')
        .orderByChild('productId')
        .equalTo(bookDetail.productId)
        .once('value', (snapshot) => {
          snapshot.forEach((s) => {
            let b = s.val();

            if (b.rate) {
              rates.push(b.rate);
            }
          });
        });

      let sumRtg = rates.reduce((a, b) => a + b, 0);
      let avgRtg = sumRtg / rates.length || 0;

      setRating(avgRtg);
    };

    getRates();

    const checkPayment = async () => {
      await firebase
        .database()
        .ref()
        .child('payments')
        .child(bookDetail.bookId)
        .get()
        .then((snapshot) => {
          let p = snapshot.val();

          if (p) {
            if (p.transactionStatus !== 'settlement') {
              setIsSubmit(true);
              setNotCompletePayment(true);
            }
          }
        });
    };

    checkPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookAgain = () => {
    reactGA.event({
      category: 'Mentoring',
      action: 'User Rebook',
      label: user.uid,
    });

    bookDraft(bookDetail);
    navigate('/app/book', { replace: true });
  };

  return (
    <div style={{ width: '100vw' }}>
      <Helmet>
        <title>My Booking Detail - Aksel</title>
      </Helmet>
      <Container sx={{ mt: '2rem' }}>
        <Box sx={{ mb: '12px', display: 'flex', flexDirection: 'row' }}>
          <Avatar
            src={bookDetail.photoUrl}
            sx={{
              height: 70,
              width: 70,
            }}
          />
          <div style={{ margin: 'auto 0 auto 14px' }}>
            <Typography variant='subHeader16'>{bookDetail.name}</Typography>
            <Typography variant='p'>{bookDetail.title}</Typography>
            {parseInt(rating) > 0 ? (
              <div style={{ display: 'flex', color: 'rgba(250, 205, 17, 1)' }}>
                <StarRoundedIcon />
                <Typography variant='pBold' sx={{ mt: '4px', ml: '4px' }}>
                  {rating.toFixed(1)}
                </Typography>
              </div>
            ) : null}
          </div>
        </Box>
        <hr style={{ border: '2px solid #E8E8E8', marginBottom: '19px' }} />
        {/* --------- Rating ---------- */}
        {notCompletePayment ? (
          <div
            style={{
              padding: '20px',
              textAlign: 'center',
            }}
          >
            <Typography variant='p'>
              Pembayaran belum selesai, cek email untuk status terkini.
            </Typography>
          </div>
        ) : null}
        <div
          style={
            isSubmit
              ? { display: 'none' }
              : {
                  textAlign: 'center',
                  borderRadius: 8,
                  marginBottom: '32px',
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.12)',
                }
          }
        >
          <div style={{ padding: '24px 16px 16px 16px' }}>
            <Typography variant='sectionHeader'>Beri Penilaian</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0',
              }}
            >
              <ReactStars
                count={5}
                onChange={(rate) => setStars(rate)}
                size={24}
                activeColor='rgba(250, 205, 17, 1)'
                a11y
                emptyIcon={
                  <StarRoundedIcon
                    fontSize='large'
                    style={{ color: '#BDBDBD' }}
                  />
                }
                filledIcon={<StarRoundedIcon fontSize='large' />}
              />
            </div>
            <TextField
              sx={{ borderRadius: '8px !important' }}
              className={classes.root}
              id='mentoring-review'
              label='Bagaimana sesi mentoring Anda?'
              multiline
              fullWidth
              required
              rows={2}
              variant='outlined'
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
            <div style={{ textAlign: 'end' }}>
              <Button
                sx={{
                  mt: '20px',
                  borderRadius: '24px',
                  backgroundColor: 'rgba(29, 45, 94, 0.16)',
                  padding: '0 !important',
                }}
                variant='contained'
                onClick={handleSubmit}
              >
                <Typography
                  variant='button'
                  sx={{ textTransform: 'capitalize', padding: '11px 28px' }}
                  className={classes.buttonText}
                >
                  Kirim
                </Typography>
              </Button>
            </div>
          </div>
        </div>
        {/* ----------- Layanan Mentoring ---------- */}
        <div style={{ marginBottom: '32px' }}>
          <Typography variant='subHeader16' sx={{ mb: '16px' }}>
            Layanan Mentoring
          </Typography>
          <ServicesAccordion
            serviceLine={
              bookDetail.serviceLine && bookDetail.serviceLine.split(',')
            }
          />
        </div>
        {/* ----------- Catatan Tambahan ------------ */}
        <div style={{ marginBottom: '28px' }}>
          <Typography variant='subHeader16' gutterBottom>
            Catatan Tambahan
          </Typography>
          <Typography variant='p'>{bookDetail.bookNotes}</Typography>
        </div>
        <hr style={{ border: '2px solid #E8E8E8', marginBottom: '19px' }} />
        {/* -------- Detail Pembayaran ---------- */}
        <div style={{ marginBottom: '106px' }}>
          <Typography variant='subHeader16' gutterBottom>
            Detail Pembayaran
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 9,
            }}
          >
            <Typography variant='p'>Total Bayar</Typography>
            <Typography variant='p'>
              Rp {numberWithCommas(bookDetail.productPrice)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 9,
            }}
          >
            <Typography variant='p'>Metode Pembayaran</Typography>
            <Typography variant='p'></Typography>
          </div>
        </div>
      </Container>
      {/* -------- Footer --------- */}
      <div
        style={{
          padding: '18px 24px',
          width: '100vw',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 10,
        }}
      >
        <Container>
          <Button fullWidth variant='contained' onClick={() => bookAgain()}>
            <Typography variant='button' sx={{ textTransform: 'capitalize' }}>
              Mentoring Lagi
            </Typography>
          </Button>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bookDetail: state.book.bookDetail,
});

export default connect(mapStateToProps)(BookingDetail);
