import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const JobSeekerForm = ({ control, jobType }) => {
  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend'>
        <Typography variant='subHeader16' color='black'>
          Tipe Pekerjaan yang Diinginkan*
        </Typography>
      </FormLabel>
      <FormGroup>
        <Controller
          name='wantedJobType'
          control={control}
          render={({ field }) => (
            <>
              {jobType.map((jobType) => {
                const checked = field.value.some(
                  (existingValue) => existingValue === jobType,
                );

                return (
                  <FormControlLabel
                    key={jobType}
                    label={jobType}
                    control={
                      <Checkbox
                        value={jobType}
                        checked={checked}
                        onChange={(event) => {
                          if (!checked) {
                            field.onChange([
                              ...field.value,
                              event.target.value,
                            ]);
                          } else {
                            field.onChange(
                              field.value.filter(
                                (value) => value !== event.target.value,
                              ),
                            );
                          }
                        }}
                      />
                    }
                  />
                );
              })}
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export default JobSeekerForm;
