import { AUTHENTICATED, DEAUTHENTICATED } from '../events';

const initialState = {
  isAuthenticated: false,
  isRegistered: false,
  userRole: '',
  expiredAt: 0,
  fullName: '',
  isLoading: true,
  dataUser: [],
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED: {
      return { ...state, ...action.payload };
    }
    case DEAUTHENTICATED: {
      return { ...initialState, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export default auth;
