import {
  CLOSE_ATS_RATING,
  CLOSE_CV_RATING,
  CLOSE_GENERAL_RATING,
  OPEN_ATS_RATING,
  OPEN_CV_RATING,
  OPEN_GENERAL_RATING,
} from '../events';
import store from '../store';

const { dispatch } = store;

export const closeATSRating = () => {
  dispatch({
    type: CLOSE_ATS_RATING,
  });
};

export const closeCVRating = () => {
  dispatch({
    type: CLOSE_CV_RATING,
  });
};

export const closeGeneralRating = () => {
  dispatch({
    type: CLOSE_GENERAL_RATING,
  });
};

export const openATSRating = () => {
  dispatch({
    type: OPEN_ATS_RATING,
  });
};

export const openCVRating = () => {
  dispatch({
    type: OPEN_CV_RATING,
  });
};

export const openGeneralRating = () => {
  dispatch({
    type: OPEN_GENERAL_RATING,
  });
};
