import { List, ListItem, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import logoAksel from 'src/assets/img/landing/logoAksel.png';
import instagram from 'src/assets/img/landing/instagram.png';
import twitter from 'src/assets/img/landing/twitter.png';
import linkedin from 'src/assets/img/landing/linkedin.png';
import whatsapp from 'src/assets/img/landing/whatsapp.png';
import youtube from 'src/assets/img/landing/youtube.png';
import telegram from 'src/assets/img/landing/telegram.png';

const products = [
  {
    id: 1,
    name: 'Career Mentoring',
    type: 'internal',
    link: '/app/home',
    desc: 'Konsultasikan perjalanan kariermu bersama mentor-mentor terpilih dari berbagai industri!',
    btnText: 'Lihat Mentor',
  },
  {
    id: 2,
    name: 'CV Maker & CV Review',
    type: 'internal',
    link: '/app/cv/dashboard',
    desc: 'Buat CV mu sesuai dengan standar profesional secara gratis!',
    btnText: 'Buat CV',
  },
  {
    id: 3,
    name: 'ATS Checker',
    type: 'internal',
    link: '/app/cv/dashboard?tab=ats',
    desc: 'Cek apakah CV kamu terbaca oleh ATS dengan cepat dan mudah!',
    btnText: 'Cek CV',
  },
  // {
  //   id: 4,
  //   name: 'CV Review',
  //   type: 'external',
  //   link: 'https://bit.ly/CVreviewAksel',
  //   desc: 'Layanan CV Review terpercaya oleh senior praktisi HR di Indonesia.',
  //   btnText: 'Pesan CV Review ',
  // },
];

const companies = [
  // {
  //   id: 1,
  //   name: "Tentang Aksel",
  //   type: "internal",
  //   // link: "/about",
  //   link: "",
  // },
  {
    id: 2,
    name: 'Blog',
    type: 'external',
    link: 'https://akselofficial.medium.com/',
  },
  {
    id: 3,
    name: 'FAQ',
    type: 'internal',
    link: '/frequently-asked-question',
  },
  {
    id: 4,
    name: 'Kebijakan Privasi',
    type: 'internal',
    link: '/privacy-policy',
  },
  {
    id: 5,
    name: 'Syarat & Ketentuan',
    type: 'internal',
    link: '/term-and-condition',
  },
];

const FooterDesktop = () => {
  const navigate = useNavigate();

  const handleClick = (data) => {
    if (data.type === 'internal') {
      navigate(data.link, { replace: true });
    } else {
      window.open(data.link, '_blank');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '72px 90px',
      }}
    >
      <div style={{ width: '700px' }}>
        <img
          style={{ width: '93px', height: '116px', marginBottom: '32px' }}
          alt='logo-aksel'
          src={logoAksel}
        />
        <Typography
          gutterBottom
          sx={{
            color: '#252525',
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '0.1px',
            fontFamily: 'Roboto',
          }}
        >
          Platform persiapan karier untuk <br />
          kaum muda Indonesia.
        </Typography>
        <Typography
          sx={{
            color: 'rgba(37, 37, 37, 0.5)',
            fontWeight: 500,
            fontSize: 18,
            letterSpacing: '0.1px',
            fontFamily: 'Roboto',
          }}
        >
          Hubungi Aksel di team@aksel.co.id
        </Typography>
      </div>
      <div style={{ width: '160px', marginRight: '75px' }}>
        <Typography variant='header' sx={{ color: '#252525' }}>
          Product
        </Typography>
        <List>
          {products.map((product) => (
            <ListItem
              onClick={() => handleClick(product)}
              button
              sx={{ paddingLeft: 0, color: 'rgba(37, 37, 37, 0.5)' }}
            >
              <Typography variant='p'>{product.name}</Typography>
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ width: '155px' }}>
        <Typography variant='header' sx={{ color: '#252525' }}>
          Company
        </Typography>
        <List>
          {companies.map((company) => (
            <ListItem
              onClick={() => handleClick(company)}
              button
              sx={{ paddingLeft: 0, color: 'rgba(37, 37, 37, 0.5)' }}
            >
              <Typography variant='p'>{company.name}</Typography>
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ width: '700px', marginTop: '90px' }}>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.instagram.com/aksel.official/'
          style={{ marginRight: '18.6px' }}
        >
          <img src={instagram} alt='instagram' />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://twitter.com/akselofficial_'
          style={{ marginRight: '18.6px' }}
        >
          <img src={twitter} alt='twitter' />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.linkedin.com/company/aksel-official/'
          style={{ marginRight: '18.6px' }}
        >
          <img src={linkedin} alt='linked-in' />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://wa.me/6285159788221'
          style={{ marginRight: '18.6px' }}
        >
          <img src={whatsapp} alt='whatsapp' />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.youtube.com/channel/UC_-w4knJEZn9W1km3sNnAFQ'
          style={{ marginRight: '18.6px' }}
        >
          <img src={youtube} alt='youtube' />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://t.me/AkselOppurtunitySeeker'
          style={{ marginRight: '18.6px' }}
        >
          <img src={telegram} alt='telegram' />
        </a>
      </div>
      <div style={{ marginTop: '90px' }}>
        <Typography variant='p' sx={{ color: 'rgba(37, 37, 37, 0.5)' }}>
          © {new Date().getFullYear()} Aksel (PT Kreasi Pemuda Bangsa)
        </Typography>
      </div>
    </div>
  );
};

export default FooterDesktop;
