import React from 'react';
import { Container, Typography, Avatar, Divider } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Slider from 'react-slick';

import testimony from 'src/templates/testimony';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimoni = ({ dotActive, setDotActive, isDesktop }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000000,
    // autoplaySpeed: 5000,
    pauseOnHover: false,
    afterChange: (current) => setDotActive(current),
    appendDots: (dots) => (
      <div style={{ bottom: '-39px' }}>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div style={{ paddingTop: '14px' }}>
        {i === dotActive ? (
          <FiberManualRecordIcon fontSize='11px' style={{ color: '#1D2D5E' }} />
        ) : (
          <FiberManualRecordIcon fontSize='11px' style={{ color: '#C4C4C4' }} />
        )}
      </div>
    ),
  };

  const testimonies = isDesktop
    ? testimony.testimony.slice(0, 3)
    : testimony.testimony;

  return (
    <>
      <div
        style={{
          backgroundColor: '#1D2D5E',
          position: 'absolute',
          width: '100%',
          height: isDesktop ? '406px' : '232px',
        }}
      >
        {' '}
      </div>
      <div
        style={
          isDesktop
            ? {
                maxWidth: '1280px',
                position: 'inherit',
                margin: 'auto',
                padding: '0 100px',
              }
            : { position: 'inherit', marginBottom: '50px', padding: '0 24px' }
        }
      >
        <Typography
          variant={isDesktop ? 'header32' : 'header'}
          style={{ paddingTop: isDesktop ? '62px' : '27px' }}
        >
          Testimoni
        </Typography>
        <Typography
          variant={isDesktop ? 'p20' : 'p'}
          style={{
            marginTop: '10px',
            marginBottom: isDesktop ? '62px' : '11px',
            padding: '0px 30px',
          }}
        >
          {isDesktop
            ? 'Kata mereka tentang produk Aksel'
            : 'Apa kata mereka tentang mentoring bersama Aksel?'}
        </Typography>
        <Slider {...sliderSettings}>
          {testimonies.map((t) => (
            <div key={t.name}>
              <Avatar
                src={t.photoUrl}
                sx={{
                  width: '88px',
                  height: '89px',
                  borderRadius: '100px',
                  margin: 'auto',
                  objectFit: 'cover',
                }}
              />
              <Container
                style={{
                  backgroundColor: '#FACD11',
                  width: '300px',
                  minHeight: '371px',
                  marginTop: '-40px',
                  borderRadius: 9,
                  color: '#000',
                }}
              >
                <Typography variant='header' style={{ paddingTop: '56px' }}>
                  {t.name}
                </Typography>
                <Typography
                  variant={isDesktop ? 'smD' : 'sm'}
                  style={{ paddingTop: '6px' }}
                  gutterBottom
                >
                  {t.title}
                </Typography>
                <Divider
                  style={{
                    margin: 'auto',
                    width: '70%',
                    marginBottom: 18,
                    backgroundColor: '#000',
                  }}
                />

                <Typography
                  variant={isDesktop ? 'pD' : 'p'}
                  style={{ padding: '0px 20px 45px 8px', display: 'flex' }}
                >
                  <div
                    style={{
                      textAlign: 'start',
                      marginTop: '-3px',
                    }}
                  >
                    <svg
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M2.42172 5.06C2.76172 5.16 3.02172 5.35 3.20172 5.63C3.40172 5.89 3.50172 6.21 3.50172 6.59C3.50172 7.07 3.35172 7.46 3.05172 7.76C2.75172 8.06 2.38172 8.21 1.94172 8.21C1.46172 8.21 1.07172 8.06 0.771719 7.76C0.491719 7.44 0.351719 7.05 0.351719 6.59C0.351719 6.35 0.371719 6.12 0.411719 5.9C0.471719 5.66 0.581719 5.32 0.741719 4.88L2.00172 0.739999H3.44172L2.42172 5.06ZM9.09086 0.589999C9.55086 0.589999 9.93086 0.749999 10.2309 1.07C10.5309 1.37 10.6809 1.75 10.6809 2.21C10.6809 2.45 10.6409 2.71 10.5609 2.99C10.5009 3.25 10.4109 3.56 10.2909 3.92L9.00086 8.06H7.56086L8.61086 3.74C8.27086 3.64 8.00086 3.46 7.80086 3.2C7.60086 2.92 7.50086 2.59 7.50086 2.21C7.50086 1.73 7.65086 1.34 7.95086 1.04C8.25086 0.739999 8.63086 0.589999 9.09086 0.589999Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                  {t.content}
                  <div style={{ position: 'relative' }}>
                    <svg
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ position: 'absolute', bottom: -5 }}
                    >
                      <path
                        d='M2.42172 5.06C2.76172 5.16 3.02172 5.35 3.20172 5.63C3.40172 5.89 3.50172 6.21 3.50172 6.59C3.50172 7.07 3.35172 7.46 3.05172 7.76C2.75172 8.06 2.38172 8.21 1.94172 8.21C1.46172 8.21 1.07172 8.06 0.771719 7.76C0.491719 7.44 0.351719 7.05 0.351719 6.59C0.351719 6.35 0.371719 6.12 0.411719 5.9C0.471719 5.66 0.581719 5.32 0.741719 4.88L2.00172 0.739999H3.44172L2.42172 5.06ZM9.09086 0.589999C9.55086 0.589999 9.93086 0.749999 10.2309 1.07C10.5309 1.37 10.6809 1.75 10.6809 2.21C10.6809 2.45 10.6409 2.71 10.5609 2.99C10.5009 3.25 10.4109 3.56 10.2909 3.92L9.00086 8.06H7.56086L8.61086 3.74C8.27086 3.64 8.00086 3.46 7.80086 3.2C7.60086 2.92 7.50086 2.59 7.50086 2.21C7.50086 1.73 7.65086 1.34 7.95086 1.04C8.25086 0.739999 8.63086 0.589999 9.09086 0.589999Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                </Typography>
              </Container>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Testimoni;
