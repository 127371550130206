export const ChangePasswordIcon = () => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.3984 2.33594C5.98001 2.33594 2.39844 5.81794 2.39844 10.1135V17.891H5.59842V10.1135C5.59842 7.53678 7.748 5.44695 10.3984 5.44695C13.0488 5.44695 15.1983 7.53678 15.1983 10.1135V17.891H18.3983V10.1135C18.3983 5.81794 14.8168 2.33594 10.3984 2.33594Z'
      fill='#AAB8C2'
    />
    <path
      d='M20.7999 24.8894C20.7999 26.6074 19.3671 28.0004 17.5999 28.0004H3.19998C1.43279 28.0004 0 26.6074 0 24.8894V15.5563C0 13.8383 1.43279 12.4453 3.19998 12.4453H17.5999C19.3671 12.4453 20.7999 13.8383 20.7999 15.5563V24.8894Z'
      fill='#FFAC33'
    />
    <path
      d='M27.9995 6.99978C27.9995 3.13357 24.7763 0 20.7996 0C16.8228 0 13.5996 3.13357 13.5996 6.99978C13.5996 10.0462 15.6052 12.6307 18.3996 13.5928V26.0547C18.3996 27.1288 19.2948 27.9991 20.3996 27.9991C21.37 27.9991 22.178 27.3272 22.3595 26.4358C22.3739 26.4366 22.3851 26.4436 22.3995 26.4436C22.8419 26.4436 23.1995 26.096 23.1995 25.6659V24.8881C23.1995 24.458 22.8419 24.1104 22.3995 24.1104V23.3326C22.8419 23.3326 23.1995 22.985 23.1995 22.5549V20.9993C23.1995 20.5692 22.8419 20.2216 22.3995 20.2216V18.4506C22.8763 18.1808 23.1995 17.6853 23.1995 17.1106V13.5928C25.9939 12.6315 27.9995 10.047 27.9995 6.99978ZM20.7996 1.55551C21.6828 1.55551 22.3995 2.25237 22.3995 3.11101C22.3995 3.96965 21.6828 4.66652 20.7996 4.66652C19.9164 4.66652 19.1996 3.96965 19.1996 3.11101C19.1996 2.25237 19.9164 1.55551 20.7996 1.55551Z'
      fill='#C1694F'
    />
  </svg>
);

export const CustomerSupportIcon = () => (
  <svg
    width='29'
    height='22'
    viewBox='0 0 29 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.5 5.94271C28.5 3.57982 26.1667 0.109375 14.5 0.109375C2.83333 0.109375 0.5 3.57982 0.5 5.94271C0.5 5.97771 0.514778 6.00182 0.517111 6.03526C0.507778 6.18771 0.5 6.34793 0.5 6.52604C0.5 8.56615 2.24144 9.44271 4.38889 9.44271C6.53633 9.44271 8.27778 8.56615 8.27778 6.52604C8.27778 6.35649 8.26145 6.2056 8.23811 6.06171C10.1242 5.78871 12.246 5.55382 14.5 5.55382C16.754 5.55382 18.8758 5.78871 20.7619 6.06171C20.7386 6.2056 20.7222 6.35649 20.7222 6.52604C20.7222 8.56615 22.4629 9.44271 24.6111 9.44271C26.7593 9.44271 28.5 8.56615 28.5 6.52604C28.5 6.35104 28.493 6.19237 28.4813 6.04304C28.4844 6.00726 28.5 5.98082 28.5 5.94271Z'
      fill='#BE1931'
    />
    <path
      d='M27.6709 14.8859C27.296 14.084 25.8742 11.627 24.5847 9.43596C23.3729 7.37718 21.5 5.55252 19.9444 5.55252V3.99696C19.9444 3.1383 19.2476 2.44141 18.3889 2.44141C17.5302 2.44141 16.8333 3.1383 16.8333 3.99696V5.55252H12.1667V3.99696C12.1667 3.1383 11.4698 2.44141 10.6111 2.44141C9.75244 2.44141 9.05556 3.1383 9.05556 3.99696V5.55252C7.5 5.55252 5.62556 7.38107 4.41222 9.44141C3.12344 11.6309 1.70322 14.0847 1.32911 14.8859C0.879556 15.8495 0.5 16.4414 0.5 17.997C0.5 19.5525 1.893 21.8859 3.61111 21.8859H25.3889C27.107 21.8859 28.5 19.5525 28.5 17.997C28.5 16.4414 28.1516 15.9156 27.6709 14.8859Z'
      fill='#DD2E44'
    />
    <path
      d='M16.0903 8.52294C16.0903 8.8745 15.3779 9.15916 14.499 9.15916C13.6201 9.15916 12.9085 8.8745 12.9085 8.52294C12.9085 8.17139 13.6201 7.88672 14.499 7.88672C15.3779 7.88672 16.0903 8.17139 16.0903 8.52294ZM12.2715 8.52294C12.2715 8.8745 11.5598 9.15916 10.6809 9.15916C9.80201 9.15916 9.09034 8.8745 9.09034 8.52294C9.09034 8.17139 9.80278 7.88672 10.6809 7.88672C11.559 7.88672 12.2715 8.17139 12.2715 8.52294ZM19.9077 8.52294C19.9077 8.8745 19.196 9.15916 18.3171 9.15916C17.4382 9.15916 16.7258 8.8745 16.7258 8.52294C16.7258 8.17139 17.4382 7.88672 18.3171 7.88672C19.196 7.88672 19.9077 8.17139 19.9077 8.52294ZM16.4077 10.7505C16.4077 11.2786 15.5529 11.7056 14.499 11.7056C13.4451 11.7056 12.5896 11.2786 12.5896 10.7505C12.5896 10.2239 13.4443 9.79616 14.499 9.79616C15.5537 9.79616 16.4077 10.2232 16.4077 10.7505ZM20.8628 10.7505C20.8628 11.2786 20.0072 11.7056 18.9541 11.7056C17.9002 11.7056 17.0447 11.2786 17.0447 10.7505C17.0447 10.2239 17.9002 9.79616 18.9541 9.79616C20.0072 9.79616 20.8628 10.2232 20.8628 10.7505ZM11.9533 10.7505C11.9533 11.2786 11.0986 11.7056 10.0447 11.7056C8.99078 11.7056 8.13523 11.2786 8.13523 10.7505C8.13523 10.2239 8.99001 9.79616 10.0447 9.79616C11.0986 9.79616 11.9533 10.2232 11.9533 10.7505ZM17.0439 13.6135C17.0439 14.3182 15.9045 14.8867 14.499 14.8867C13.0936 14.8867 11.9533 14.3182 11.9533 13.6135C11.9533 12.9112 13.0936 12.3411 14.499 12.3411C15.9045 12.3418 17.0439 12.9112 17.0439 13.6135ZM22.7722 13.6135C22.7722 14.3174 21.6312 14.8867 20.2266 14.8867C18.8211 14.8867 17.6809 14.3182 17.6809 13.6135C17.6809 12.9112 18.8211 12.3411 20.2266 12.3411C21.6312 12.3418 22.7722 12.9112 22.7722 13.6135ZM11.3171 13.6135C11.3171 14.3174 10.1769 14.8867 8.77145 14.8867C7.36601 14.8867 6.22656 14.3182 6.22656 13.6135C6.22656 12.9112 7.36601 12.3411 8.77145 12.3411C10.1769 12.3418 11.3171 12.9112 11.3171 13.6135Z'
      fill='white'
    />
  </svg>
);

export const TnCIcon = () => (
  <svg
    width='24'
    height='28'
    viewBox='0 0 24 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.8776 7.45578L15.8776 0.455778C15.5968 0.175 15.2071 0 14.777 0C13.9183 0 13.2222 0.696889 13.2214 1.55556C13.2214 1.98489 13.3957 2.37456 13.6772 2.65611L10.6758 5.65756L17.6758 12.6576L20.6772 9.65611C20.9588 9.93689 21.3477 10.1111 21.777 10.1111C22.6357 10.1111 23.3333 9.41422 23.3326 8.55556C23.3326 8.12622 23.1583 7.73733 22.8776 7.45578Z'
      fill='#E1E8ED'
    />
    <path
      d='M14.7778 0H3.11111C1.393 0 0 1.393 0 3.11111V24.8889C0 26.607 1.393 28 3.11111 28H20.2222C21.9403 28 23.3333 26.607 23.3333 24.8889V8.55556H16.3333C15.5556 8.55556 14.7778 7.77778 14.7778 7V0Z'
      fill='#CCD6DD'
    />
    <path
      d='M14.778 0H13.2224V7C13.2224 8.71811 14.6154 10.1111 16.3336 10.1111H23.3336V8.55556H16.3336C15.5558 8.55556 14.778 7.77778 14.778 7V0ZM10.8891 6.22222C10.8891 6.65156 10.5407 7 10.1113 7H3.88911C3.45977 7 3.11133 6.65156 3.11133 6.22222C3.11133 5.79289 3.45977 5.44445 3.88911 5.44445H10.1113C10.5407 5.44445 10.8891 5.79289 10.8891 6.22222ZM10.8891 9.33333C10.8891 9.76267 10.5407 10.1111 10.1113 10.1111H3.88911C3.45977 10.1111 3.11133 9.76267 3.11133 9.33333C3.11133 8.904 3.45977 8.55556 3.88911 8.55556H10.1113C10.5407 8.55556 10.8891 8.904 10.8891 9.33333ZM20.2224 12.4444C20.2224 12.8738 19.8748 13.2222 19.4447 13.2222H3.88911C3.45977 13.2222 3.11133 12.8738 3.11133 12.4444C3.11133 12.0151 3.45977 11.6667 3.88911 11.6667H19.4447C19.8748 11.6667 20.2224 12.0151 20.2224 12.4444ZM20.2224 15.5556C20.2224 15.9857 19.8748 16.3333 19.4447 16.3333H3.88911C3.45977 16.3333 3.11133 15.9857 3.11133 15.5556C3.11133 15.1254 3.45977 14.7778 3.88911 14.7778H19.4447C19.8748 14.7778 20.2224 15.1254 20.2224 15.5556ZM20.2224 18.6667C20.2224 19.0968 19.8748 19.4444 19.4447 19.4444H3.88911C3.45977 19.4444 3.11133 19.0968 3.11133 18.6667C3.11133 18.2366 3.45977 17.8889 3.88911 17.8889H19.4447C19.8748 17.8889 20.2224 18.2366 20.2224 18.6667ZM20.2224 21.7778C20.2224 22.2079 19.8748 22.5556 19.4447 22.5556H3.88911C3.45977 22.5556 3.11133 22.2079 3.11133 21.7778C3.11133 21.3477 3.45977 21 3.88911 21H19.4447C19.8748 21 20.2224 21.3477 20.2224 21.7778Z'
      fill='#99AAB5'
    />
  </svg>
);

export const PrivacyPolicyIcon = () => (
  <svg
    width='25'
    height='28'
    viewBox='0 0 25 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.166 2.33333C18.7215 0 12.4993 0 12.4993 0C12.4993 0 6.27707 0 0.832626 2.33333C-1.50071 14 0.832627 24.1111 12.4993 28C24.166 24.1111 26.4993 14 24.166 2.33333Z'
      fill='#CCD6DD'
    />
    <path
      d='M12.4991 26.3534C3.48619 23.1217 0.0243002 15.4232 2.2013 3.45558C7.10363 1.57724 12.4431 1.55469 12.4991 1.55469C12.5543 1.55469 17.9109 1.5858 22.7969 3.45558C24.9739 15.4232 21.512 23.1217 12.4991 26.3534Z'
      fill='#55ACEE'
    />
    <path
      d='M22.7958 3.45558C17.9098 1.5858 12.5533 1.55469 12.498 1.55469V26.3534C21.5109 23.1217 24.9728 15.4232 22.7958 3.45558Z'
      fill='#226699'
    />
  </svg>
);
