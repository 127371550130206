import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  Avatar,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import 'firebase/storage';
import { useSelector } from 'react-redux';
import Logo from './LogoBlack';
import { HamburgerMenu } from './hamburgerMenu';
import CustomButton from './button';
import CustomPopover from 'src/components/cvMaker/CustomPopover';
import { logout } from 'src/redux/actions';

import mentor from 'src/assets/img/mentor.svg';
import cvMaker from 'src/assets/img/cvMaker.svg';
// import cvReview from 'src/assets/img/cvReview.svg';
import atsChecker from 'src/assets/img/atsChecker.svg';
import profile from 'src/assets/img/profile.png';
import message from 'src/assets/img/message.png';

const MainNavbar = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTablet = useMediaQuery('(min-width:768px)');
  const [tabletView, setTabletView] = useState(isTablet);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listMenu, setListMenu] = useState([]);
  let user = JSON.parse(localStorage.getItem('user'));

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    setTabletView(isTablet);
  }, [isTablet]);

  const handleMenuOpen = (e, type) => {
    if (type === 'service') {
      setListMenu([
        {
          label: 'Career Mentoring',
          icon: mentor,
          handle: () => {
            navigate('/app/home', { replace: true });
            setMenuOpen(false);
          },
        },
        {
          label: 'ATS Checker',
          icon: atsChecker,
          handle: () => {
            navigate('/app/cv/dashboard?tab=ats', { replace: true });
            setMenuOpen(false);
          },
        },
        {
          label: 'CV Maker & CV Review',
          icon: cvMaker,
          handle: () => {
            navigate('/app/cv/dashboard', { replace: true });
            setMenuOpen(false);
          },
        },
        // {
        //   label: 'CV Review',
        //   icon: cvReview,
        //   handle: () => window.open('https://bit.ly/CVreviewAksel', '_blank'),
        // },
      ]);
    } else {
      setListMenu([
        {
          label: 'Profile',
          icon: profile,
          handle: () => {
            navigate('/app/profile', { replace: false });
            setMenuOpen(false);
          },
        },
        {
          label: 'CV Saya',
          icon: cvMaker,
          handle: () => {
            navigate('/app/cv/dashboard', { replace: true });
            setMenuOpen(false);
          },
        },
        {
          label: 'Pesanan Saya',
          icon: message,
          handle: () => {
            navigate('/app/booking', { replace: false });
            setMenuOpen(false);
          },
        },
        {
          button: <CustomButton sx={{ width: '100%' }}>Keluar</CustomButton>,
          handle: () => {
            logout();
            setMenuOpen(false);
          },
        },
      ]);
    }
    setAnchorEl(e.currentTarget);
    setMenuOpen(!menuOpen);
  };

  return (
    <AppBar
      elevation={0}
      {...props}
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Toolbar
        sx={{
          height: 64,
          display: 'flex',
          justifyContent: 'space-between',
          padding: `0 ${tabletView ? '70px' : '16px'} !important`,
        }}
      >
        <RouterLink to='/'>
          <Logo />
        </RouterLink>
        {!tabletView && <HamburgerMenu />}
        {tabletView && (
          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', cursor: 'pointer', color: '#252525' }}
              onClick={(e) => handleMenuOpen(e, 'service')}
            >
              <Typography variant='menu'>Layanan</Typography>
              <KeyboardArrowDown sx={{ mr: '46px' }} fontSize='small' />
            </div>
            <Typography
              variant='menu'
              sx={{ color: '#252525', marginRight: '42px', cursor: 'pointer' }}
              // onClick={() => navigate("/about", { replace: true })}
            >
              Tentang Aksel
            </Typography>
            <Typography
              variant='menu'
              sx={{ color: '#252525', cursor: 'pointer' }}
              onClick={() =>
                window.open('https://akselofficial.medium.com/', '_blank')
              }
            >
              Blog
            </Typography>
          </div>
        )}

        {tabletView &&
          !isAuthenticated &&
          !user &&
          (pathname === '/login' ? (
            <RouterLink to='/register'>
              <CustomButton>Daftar</CustomButton>
            </RouterLink>
          ) : (
            <RouterLink
              to='/login'
              onClick={() => localStorage.setItem('to', 'home')}
            >
              <CustomButton>Masuk</CustomButton>
            </RouterLink>
          ))}

        {tabletView && (isAuthenticated || user) && (
          <div
            style={{ display: 'flex', color: '#252525', cursor: 'pointer' }}
            onClick={(e) => handleMenuOpen(e, 'profile')}
          >
            <Avatar src={user?.photoURL} sx={{ height: 34, width: 34 }} />
            <Typography variant='menu' sx={{ margin: 'auto 8px' }}>
              {user?.displayName?.split(' ')[0]}
            </Typography>
            <KeyboardArrowDown sx={{ margin: 'auto' }} fontSize='small' />
          </div>
        )}
      </Toolbar>

      <CustomPopover
        open={menuOpen}
        anchorEl={anchorEl}
        handleClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        listMenu={listMenu}
        sx={{ marginTop: '28px', marginLeft: '-10px' }}
      />
    </AppBar>
  );
};

export default MainNavbar;
