import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import {
  Box,
  Button,
  Grid,
  Typography,
  Grow,
  Container,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReplayIcon from '@material-ui/icons/Replay';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import questions from 'src/templates/assessment';
import { assessmentResult } from '../redux/actions';
import CustomButton from 'src/components/button';
import reactGA from 'react-ga';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed
// reactGA.pageview('/assessment'); // Main page

const Assessment = ({ isAuthenticated }) => {
  const [active, setActive] = useState(0);
  const [selected, setSelected] = useState([]);
  const [output, setOutput] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [resultAssest, setResultAssest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [textResult, setTextResult] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const getAssesment = async () => {
      setIsLoading(true);
      let resAssesst = [];
      const ref = firebase.database().ref();

      if (user) {
        await ref
          .child('assessment')
          .orderByChild('customerId')
          .equalTo(user.uid)
          .once('value', (snapshot) => {
            snapshot.forEach((s) => {
              const b = s.val();
              resAssesst.push(b);
            });
          });
      }
      setIsLoading(false);
      if (resAssesst[0] && resAssesst[0].results) {
        setTextResult(resAssesst[0].results[0].value);
      }
    };

    getAssesment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (value, index) => {
    const isLast = index + 1 === questions.length;
    Promise.all([!isLast && setAnimate(false)]).then((_) => {
      selected[index] = value.id;
      setSelected(selected);
      setIsSelect(!isSelect);
      setTimeout(() => {
        if (!isLast) {
          setActive(index + 1);
        }
        setAnimate(true);
      }, 200);
    });
    output[index] = value.output;
    setOutput(output);
    const outputResult = [];
    output.forEach((datas) => {
      datas.forEach((data) => {
        outputResult.push(data);
      });
    });
    assessmentResult(outputResult);

    // final result
    const results = questions[0].values.filter((res) => res.id === selected[0]);
    setResultAssest(results[0] && results[0].output[0].value);

    if (isLast) {
      setLastQuestion(!lastQuestion);

      if (user) {
        const params = {
          customerId: user.uid,
          results: outputResult,
        };

        firebase.database().ref(`assessment/${user.uid}`).set(params);

        reactGA.event({
          category: 'Mentoring',
          action: 'User Fill Assessment',
          label: user.uid,
        });
      }
    }
  };

  const handleRetake = () => {
    assessmentResult([]);
    setActive(0);
    setLastQuestion(false);
    setSelected([]);
    setOutput([]);
    if (user) {
      const params = {
        customerId: user.uid,
        results: [],
      };

      firebase.database().ref(`assessment/${user.uid}`).set(params);
      setTextResult(null);

      reactGA.event({
        category: 'Mentoring',
        action: 'User Retake Assessment',
        label: user.uid,
      });
    }
  };

  const handleArrow = async (flag) => {
    if (flag === 'back') {
      setActive(active - 1);
    } else {
      setActive(active + 1);
    }
  };

  const handleMentor = () => {
    navigate('/app/mentoring');
  };

  return (
    <>
      {isLoading ? (
        <div style={{ padding: '2rem 0' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container
            sx={
              lastQuestion || textResult
                ? { backgroundColor: '#1D2D5E' }
                : { display: 'none' }
            }
          >
            <Typography
              variant='p'
              sx={{ padding: '66px 0 8px', color: '#EFF9FD' }}
            >
              Saat ini kamu membutuhkan jasa
            </Typography>
            <Typography variant='header' style={{ color: '#EFF9FD' }}>
              {resultAssest || textResult}
            </Typography>
            <CustomButton
              sx={{
                margin: '24px 0 16px',
                paddingLeft: '55px',
                paddingRight: '55px',
              }}
              variant='contained'
              color='secondary'
              onClick={handleMentor}
            >
              Pilih Mentor
            </CustomButton>
            <br />
            <CustomButton
              variant='contained'
              color='secondary'
              sx={{ marginBottom: '66px', backgroundColor: 'white !important' }}
              onClick={handleRetake}
              startIcon={<ReplayIcon />}
            >
              Retake Assessment
            </CustomButton>
          </Container>
          <Container
            sx={lastQuestion || textResult ? { display: 'none' } : null}
          >
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'center',
                marginTop: '3px',
                paddingBottom: '32px',
              }}
            >
              <Grid item xs={6} sx={active === 0 ? { display: 'none' } : null}>
                <Button
                  sx={{ float: 'left' }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => handleArrow('back')}
                >
                  <Typography
                    variant='button'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    Back
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6} sx={active === 0 ? { display: 'none' } : null}>
                <Typography
                  variant='p'
                  sx={{
                    paddingTop: '7px',
                    float: 'left',
                  }}
                >
                  {active + 1} / {questions.length}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '21px' }}>
                <Box sx={{ mb: '24px' }}>
                  <Typography
                    gutterBottom
                    variant='subHeader20'
                    align='center'
                    sx={{ color: '#1D2D5E' }}
                  >
                    {questions[active].question}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='p'
                    align='center'
                    sx={{ color: '#252525' }}
                  >
                    {questions[active].description}
                  </Typography>
                </Box>
                <Grow in={animate}>
                  <Grid container spacing={2} justifyContent='center'>
                    {questions[active].values.map((value) => (
                      <Grid key={value.id} item xs={12} md={6}>
                        <Button
                          fullWidth
                          onClick={() => handleSubmit(value, active)}
                          size='large'
                          id={value.id}
                          variant={
                            value.id !== selected[active]
                              ? 'outlined'
                              : 'contained'
                          }
                          style={
                            value.id !== selected[active]
                              ? {
                                  borderRadius: '80px',
                                  boxShadow:
                                    '0px 4px 8px rgba(37, 37, 37, 0.08)',
                                  backgroundColor: 'white',
                                  borderColor: 'transparent',
                                }
                              : {
                                  backgroundColor: '#1D2D5E',
                                  borderRadius: '80px',
                                }
                          }
                        >
                          <Typography
                            variant='p'
                            sx={{
                              textTransform: 'capitalize',
                              color:
                                value.id !== selected[active]
                                  ? '#252525'
                                  : '#EFF9FD',
                            }}
                          >
                            {value.value}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: localStorage.getItem('user')
    ? true
    : state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Assessment);
