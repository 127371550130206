import { Card, Typography } from '@material-ui/core';

import { NavLink as RouterLink } from 'react-router-dom';
import Button from 'src/components/button';

const CardPersuasive = ({
  title,
  buttonText,
  buttonColor,
  linkTo,
  children,
  isDesktop,
  isTablet,
}) => {
  return (
    <Card
      style={{
        width: '90%',
        margin: 'auto',
        marginTop: isDesktop || isTablet ? '-115px' : '-140px',
        borderRadius: '8px',
        textAlign: isDesktop || isTablet ? 'center' : 'left',
        background: isDesktop
          ? 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #EFF9FD 0%, #EFF9FD 86.98%), linear-gradient(0deg, #F5FCFF, #F5FCFF), url(image.png)'
          : null,
      }}
    >
      <Typography
        variant={isDesktop ? 'header32' : 'sectionHeader'}
        style={{
          marginTop: isDesktop ? 68 : 45,
          marginLeft: isDesktop || isTablet ? 0 : 24,
          color: '#000',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant={isDesktop ? 'p20' : 'p'}
        style={{
          margin: isDesktop ? '9px 0' : '8px 24px 0 24px',
          color: 'rgba(0, 0, 0, 0.64)',
        }}
      >
        {children}
      </Typography>
      <Button
        variant='contained'
        color={buttonColor || 'secondary'}
        sx={{
          margin: isDesktop
            ? '23px 0 62px 0'
            : `15px 0 ${isTablet ? '45px 0' : '45px 24px'}`,
        }}
        to={linkTo}
        component={RouterLink}
      >
        {buttonText}
      </Button>
    </Card>
  );
};

export default CardPersuasive;
