import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import CustomButton from '../button';
import { useStyles } from 'src/pages/landingStyles';

const Introduction = () => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.layer}>
        <Container style={{ paddingBottom: '37px' }}>
          <Typography
            variant='mainHeader'
            sx={{ paddingTop: '64px', color: '#fff' }}
          >
            Akselerasikan Kariermu <br />
            bersama Aksel
          </Typography>
          <Typography variant='pBold' sx={{ paddingTop: '9px', color: '#fff' }}>
            Bangun karier impianmu!
          </Typography>
          <Typography
            variant='p'
            style={{
              paddingTop: '9px',
              marginRight: '-5px',
              color: '#fff',
            }}
          >
            Aksel adalah platform persiapan karier untuk kaum muda Indonesia.
            Dapatkan ekosistem untuk Self Discovery, Career Exploration dan
            Career Preparation.
          </Typography>
          <CustomButton
            sx={{
              marginTop: '16px',
              paddingLeft: '5px !important',
              paddingRight: '5px !important',
            }}
            variant='contained'
            color='secondary'
            to={'/app/home'}
            component={RouterLink}
          >
            Mulai Sekarang
          </CustomButton>
        </Container>
      </div>
    </div>
  );
};

export default Introduction;
