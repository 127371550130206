import { BOOK_SUBMITTED, BOOK_DRAFT, BOOK_DETAIL } from '../events';

const initialState = {
  booked: false,
  bookDraft: {
    name: '',
    title: '',
    price: '',
    photoUrl: '',
  },
  bookDetail: {},
};

const book = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_SUBMITTED: {
      return {
        ...state,
        ...{
          booked: true,
        },
      };
    }
    case BOOK_DRAFT: {
      return { ...state, bookDraft: { ...action.payload } };
    }
    case BOOK_DETAIL: {
      return { ...state, bookDetail: { ...action.payload } };
    }
    default: {
      return state;
    }
  }
};

export default book;
