import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from 'react-router-dom';
import reactGA from 'react-ga';

import { useStyles } from './landingStyles';
import Introduction from 'src/components/landing/Introduction';
// import Banner from "src/components/landing/Banner";
import Products from 'src/components/landing/Products';
import Mentors from 'src/components/landing/Mentors';
import Testimoni from 'src/components/landing/Testimoni';
import Events from 'src/components/landing/Events';
import Footer from 'src/components/landing/Footer';
import Details from 'src/components/landing/Details';
import CardPersuasive from 'src/components/landing/CardPersuasive';
import Sponsors from 'src/components/landing/Sponsors';
import Promo from 'src/components/landing/Promo';

import LandingPageDesktop from './LandingPageDesktop';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed
// reactGA.pageview('/landingpage'); // Main page

const LandingPage = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:1280px)');
  const navigate = useNavigate();
  const [videoOpen, setVideoOpen] = useState({
    modal: false,
    url: '',
    title: '',
  });
  const [dotActive, setDotActive] = useState(0);
  const [desktopView, setDesktopView] = useState(isDesktop);

  // reset flag
  localStorage.removeItem('to');

  useEffect(() => {
    setDesktopView(isDesktop);
  }, [isDesktop]);

  const handleProduct = ({ id, pushType, pushTo }) => {
    if (pushType === 'app') {
      if (id === 'cvMaker') {
        localStorage.setItem('to', id);
      }
      navigate(pushTo, { replace: true });
    } else {
      window.open(pushTo, '_blank');
    }
  };

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}
    >
      <Helmet>
        <title>Aksel - Buat CV ATS Friendly Gratis dan Mudah</title>
      </Helmet>
      {desktopView ? (
        <LandingPageDesktop
          handleProduct={handleProduct}
          setVideoOpen={setVideoOpen}
        />
      ) : (
        <Grid container style={{ padding: 0, maxWidth: '768px' }}>
          <Introduction />

          {/* <Grid item xs={12} style={{ marginTop: "-10px", zIndex: 100 }}>
            <Banner />
          </Grid> */}

          <Grid item xs={12}>
            <Products handleProduct={handleProduct} />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              backgroundColor: '#F3F5FB',
            }}
          >
            <Mentors setVideoOpen={setVideoOpen} classes={classes} />
          </Grid>

          <Grid item xs={12}>
            <Events classes={classes} />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <Promo isMobile />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              position: 'relative',
              color: '#fff',
              marginTop: '40px',
            }}
          >
            <Testimoni dotActive={dotActive} setDotActive={setDotActive} />
          </Grid>

          <Grid item xs={12} style={{ margin: '16px 0 48px 0' }}>
            <Sponsors />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: '#1D2D5E',
              height: '192px',
              position: 'relative',
              marginTop: '150px',
            }}
          >
            <CardPersuasive
              title='Siap mengakselerasi kariermu?'
              buttonText='Daftar Sekarang'
              buttonColor='secondary'
              linkTo='/login'
            >
              Tenang, Kamu tidak sendiri <br /> Ada Aksel untukmu!
            </CardPersuasive>
          </Grid>

          <Grid item xs={12}>
            <Details />
          </Grid>

          <Footer />
        </Grid>
      )}

      <Dialog
        onClose={() => setVideoOpen({ modal: false })}
        aria-labelledby='customized-dialog-title'
        open={videoOpen.modal}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          onClose={() => setVideoOpen({ modal: false })}
          sx={{ padding: '16px' }}
        >
          {videoOpen.title}
          <IconButton
            aria-label='close'
            sx={{ padding: 0, position: 'absolute', right: '16px' }}
            onClick={() => setVideoOpen({ modal: false })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '16px' }}>
          <video autoPlay controls width='100%'>
            <source src={videoOpen.url} type='video/mp4' />
          </video>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LandingPage;
