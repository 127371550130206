const assessment = [
  {
    id: '1',
    question: 'Kamu lagi butuh apa?',
    description: 'Pilih salah satu untuk kami bantu mencari kebutuhanmu!',
    values: [
      {
        id: '1-1',
        value: 'Belajar dari mahasiswa high-achiever',
        output: [
          {
            id: '1-11',
            value: 'College Life Sharing',
          },
        ],
      },
      {
        id: '1-2',
        value: 'Belajar dari pengalaman mentor',
        output: [
          {
            id: '1-21',
            value: 'Professional Life Sharing',
          },
        ],
      },
      {
        id: '1-3',
        value: 'Persiapan magang',
        output: [
          {
            id: '1-31',
            value: 'Internship Preparation',
          },
        ],
      },
      {
        id: '1-4',
        value: 'Mengerti pekerjaan yang saya mau lebih dalam',
        output: [
          {
            id: '1-41',
            value: "Mentor's Career Path Exploration",
          },
        ],
      },
      {
        id: '1-5',
        value: 'Mengasah kemampuan yang dibutuhkan',
        output: [
          {
            id: '1-51',
            value: 'Knowledge and Skill Preparation',
          },
        ],
      },
      {
        id: '1-6',
        value: 'Persiapan CV atau Interview',
        output: [
          {
            id: '1-61',
            value: 'CV Preparation & Feedback',
          },
          {
            id: '1-62',
            value: 'Interview Preparation & Mock-up',
          },
        ],
      },
      {
        id: '1-7',
        value: 'Mencari lowongan kerja dan kenalan',
        output: [
          {
            id: '1-71',
            value: 'Opportunity and Network Sourcing',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    question: 'Apakah kamu memiliki preferensi industri perusahaan?',
    values: [
      {
        id: '2-1',
        value: 'Teknologi/Startup',
        output: [
          {
            id: '2-11',
            value: 'Teknologi/Startup',
          },
        ],
      },
      {
        id: '2-2',
        value: 'FMCG',
        output: [
          {
            id: '2-21',
            value: 'FMCG',
          },
        ],
      },
      {
        id: '2-3',
        value: 'Financial Services',
        output: [
          {
            id: '2-31',
            value: 'Financial Services',
          },
        ],
      },
      {
        id: '2-4',
        value: 'Consulting/Advisory Services',
        output: [
          {
            id: '2-41',
            value: 'Consulting/Advisory Services',
          },
        ],
      },
      {
        id: '2-6',
        value: 'NGO',
        output: [
          {
            id: '2-61',
            value: 'NGO',
          },
        ],
      },
      {
        id: '2-5',
        value: 'Tidak ada',
        output: [
          {
            id: '2-51',
            value: '-',
          },
        ],
      },
    ],
  },
  {
    id: '3',
    question: 'Apakah kamu memiliki preferensi keahlian atau profesi?',
    values: [
      {
        id: '3-1',
        value: 'Tech Engineering',
        output: [
          {
            id: '3-11',
            value: 'Tech Engineering',
          },
        ],
      },
      {
        id: '3-2',
        value: 'Product',
        output: [
          {
            id: '3-21',
            value: 'Product',
          },
        ],
      },
      {
        id: '3-3',
        value: 'Data Science',
        output: [
          {
            id: '3-31',
            value: 'Data Science',
          },
        ],
      },
      {
        id: '3-4',
        value: 'Marketing & PR',
        output: [
          {
            id: '3-41',
            value: 'Marketing & PR',
          },
        ],
      },
      {
        id: '3-5',
        value: 'Human Resource',
        output: [
          {
            id: '3-51',
            value: 'Human Resource',
          },
        ],
      },
      {
        id: '3-6',
        value: 'Consulting/Advisory Services',
        output: [
          {
            id: '3-61',
            value: 'Consulting/Advisory Services',
          },
        ],
      },
      {
        id: '3-7',
        value: 'Business Development',
        output: [
          {
            id: '3-71',
            value: 'Business Development',
          },
        ],
      },
      {
        id: '3-9',
        value: 'Finance',
        output: [
          {
            id: '3-91',
            value: 'Finance',
          },
        ],
      },
      {
        id: '3-8',
        value: 'Tidak ada',
        output: [
          {
            id: '3-81',
            value: '-',
          },
        ],
      },
    ],
  },
];

export default assessment;
