import firebase from 'firebase/app';
import store from '../store';
import {
  CV_BUILDER,
  GET_CV_DETAIL,
  CHANGE_CV_DETAIL,
  CV_STEP,
  ATS_PARSE,
} from '../events';

const { dispatch } = store;

export const getCvDetail = async (id) => {
  const snapshot = await firebase.database().ref(`resumes/${id}`).once('value');

  dispatch({
    type: GET_CV_DETAIL,
    payload: snapshot.val(),
  });
  return snapshot.val();
};

export const updateCvDetail = async (data) => {
  await firebase
    .database()
    .ref(`resumes/${data.id}`)
    .update({
      ...data,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });

  dispatch({
    type: CHANGE_CV_DETAIL,
    payload: { ...data },
  });
};

export const setCvStep = (step) => {
  dispatch({
    type: CV_STEP,
    payload: step,
  });
};

export const cvBuilder = async (data) => {
  dispatch({
    type: CV_BUILDER,
    payload: {
      ...data,
    },
  });
};

export const setAtsParse = (data) => {
  dispatch({
    type: ATS_PARSE,
    payload: data,
  });
};
