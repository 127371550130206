import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import sponsor from 'src/templates/akselSponsor';

const Sponsors = ({ isDesktop }) => (
  <Paper
    elevation={0}
    sx={{ borderRadius: '8px', textAlign: 'center', pt: '16px' }}
  >
    <Typography
      variant={isDesktop ? 'header' : 'subHeader14'}
      style={{ marginBottom: '15px' }}
    >
      Mitra yang pernah bekerja sama:
    </Typography>
    <Grid
      container
      spacing={2}
      style={{
        textAlign: 'center',
        padding: '0 48px',
      }}
    >
      {sponsor.map((idx) => (
        <Grid item key={idx.name} xs={4} style={{ margin: 'auto' }}>
          <img alt={`${idx.name}`} src={idx.src} style={{ width: idx.width }} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

export default Sponsors;
