import React from 'react';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import banners from 'src/templates/akselBanner';

const Promo = ({ isMobile }) => {
  const navigate = useNavigate();
  const handleClick = (d) => {
    if (d.pushType === 'app') {
      navigate(d.pushTo, { replace: false });
    } else {
      window.open(d.pushTo, '_blank');
    }
  };

  return (
    <div
      style={{
        width: isMobile ? 'auto' : '1280px',
        padding: isMobile ? '24px' : 0,
      }}
    >
      <Typography variant={isMobile ? 'sectionHeader' : 'header32'}>
        Penawaran Menarik
      </Typography>
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        {banners.map((banner) => (
          <a onClick={() => handleClick(banner)} href='#!'>
            <img
              src={banner.image}
              alt={banner.alt}
              style={{
                height: isMobile ? '158px' : '316px',
                margin: isMobile ? '16px 16px 0 0' : '47px 35.5px 28px 0',
                borderRadius: '16px',
                cursor: 'pointer',
                imageRendering: '-webkit-optimize-contrast',
              }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Promo;
