import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { useRoutes, useLocation } from 'react-router-dom';
import { ThemeProvider, Fab } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import { useEffect } from 'react';
import { refreshClientAuth } from './redux/actions';
// import { ChatWidget } from '@papercups-io/chat-widget';
import reactGA from 'react-ga';
import DateAdapter from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {
  closeATSRating,
  closeCVRating,
  closeGeneralRating,
} from 'src/redux/actions/rating-actions';
import CustomDialog from 'src/components/cvMaker/CustomDialog';
import GeneralRating from 'src/components/GeneralRating';
import CVRating from 'src/components/cvMaker/CVRating';
import ATSRating from 'src/components/dashboard/ATSRating';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});
// reactGA.pageview('/root');

const App = ({ showATSRating, showCVRating, showGeneralRating }) => {
  const routing = useRoutes(routes);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('redirection', false);
    refreshClientAuth();
  }, []);

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    // 1 -> default jadi muncul, 0 -> ditutup user
    if (pathArr[2] === 'cv') {
      if (localStorage.getItem('bannerCv') === '0') {
        localStorage.setItem('bannerCv', '0');
      }
    } else {
      localStorage.setItem('bannerCv', '1');
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <GlobalStyles />
        {routing}
        <CustomDialog open={showGeneralRating} isCustomComponent>
          <GeneralRating onClose={closeGeneralRating} />
        </CustomDialog>
        <CustomDialog open={showCVRating} isCustomComponent>
          <CVRating onClose={closeCVRating} />
        </CustomDialog>
        <CustomDialog open={showATSRating} isCustomComponent>
          <ATSRating onClose={closeATSRating} />
        </CustomDialog>

        <Fab
          sx={{
            position: 'fixed',
            bottom: '63px',
            right: '20px',
            left: 'auto',
            width: '50px',
            height: '50px',
          }}
          color='primary'
          onClick={() => window.open('https://wa.me/6285159788221')}
        >
          <WhatsAppIcon style={{ fontSize: '1.75rem' }} />
        </Fab>
        {/* <ChatWidget
          accountId='ad89afde-1101-4446-a0cd-1dd2371659ca'
          title='Welcome to Aksel'
          subtitle='Ask us anything in the chat window below'
          primaryColor='#1d2d5e'
          greeting=''
          awayMessage=''
          newMessagePlaceholder='Start typing...'
          showAgentAvailability={false}
          agentAvailableText="We're online right now!"
          agentUnavailableText="We're away at the moment."
          requireEmailUpfront={false}
          iconVariant='outlined'
          baseUrl='https://aksel-papercups.herokuapp.com'
        /> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  showATSRating: state.rating.showATSRating,
  showCVRating: state.rating.showCVRating,
  showGeneralRating: state.rating.showGeneralRating,
});

export default connect(mapStateToProps)(App);
