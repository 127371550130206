import { Helmet } from 'react-helmet';
import { Component } from 'react';
import firebase from 'firebase/app';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Tabs,
  Tab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import BookingCardMentor from 'src/components/booking/BookingCardMentor';
import BookingCardCV from 'src/components/booking/BookingCardCV';
import products from 'src/templates/products';
import bookListEmpty from '../assets/img/bookListEmpty.png';
import bookListCVEmpty from '../assets/img/bookListCVEmpty.png';
import { NavLink as RouterLink } from 'react-router-dom';
import reactGA from 'react-ga';
import { setNavbarTitle } from 'src/redux/actions';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});
// reactGA.pageview('/bookinglist'); // Main page

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default class BookingList extends Component {
  state = {
    bookings: [],
    cvR: [],
    loading: true,
    activeTab: 0,
  };

  async componentDidMount() {
    setNavbarTitle('Pesanan Saya');
    let bookingsData = [];
    const cvReviews = [];
    // let user = firebase.auth().currentUser;
    const user = JSON.parse(localStorage.getItem('user'));
    const ref = firebase.database().ref();
    // const refBookings = firebase.database().ref('bookings');

    if (user) {
      await ref
        .child('bookings')
        .orderByChild('customerId')
        .equalTo(user.uid)
        .once('value', (snapshot) => {
          snapshot.forEach((s) => {
            const b = s.val();
            const product = products.filter(
              (p) => p.id === b.productId && b.transactionType !== 'ats',
            );

            if (b.transactionType !== 'ats' && product.length > 0) {
              let booking = {
                ...b,
                ...product[0],
              };
              bookingsData.push(booking);
            }

            if (b.transactionType === 'cvReview') {
              cvReviews.push(b);
            }
          });
        });
    }
    // sort to get latest subscription
    cvReviews.sort(function (a, b) {
      return b.createdAt - a.createdAt;
    });

    if (cvReviews.length) {
      cvReviews.forEach(async (cvr) => {
        if (cvr.bookStatus === 'PROCESSING_PAYMENT') {
          // TODO: takeout dulu, belum dapet selisih jam yg pas
          // const diff = Date.now() - cvr.createdAt;
          // if (diff > 1800) {
          //   // 30 minutes
          //   await refBookings.child(cvr.bookId).update({
          //     bookStatus: 'FAILED_PAYMENT',
          //     updatedAt: firebase.database.ServerValue.TIMESTAMP,
          //   });
          // }
        }
        await ref
          .child('payments')
          .orderByChild('orderId')
          .equalTo(cvr.bookId)
          .once('value', (snapshot) => {
            snapshot.forEach((s) => {
              const p = s.val();
              cvr.paymentMethod = p.paymentType;
            });
          });
      });
    }

    this.setState({
      bookings: bookingsData,
      cvR: cvReviews,
      loading: false,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Bookings - Aksel</title>
        </Helmet>
        <Container sx={{ mt: '1rem' }}>
          <Tabs
            value={this.state.activeTab}
            onChange={(e, d) => this.setState({ activeTab: d })}
            aria-label='booking'
            variant='fullWidth'
          >
            <Tab label='Mentoring' {...a11yProps(0)} />
            <Tab label='CV Review' {...a11yProps(1)} />
          </Tabs>
        </Container>

        <TabPanel value={this.state.activeTab} index={0}>
          <Container maxWidth={false} sx={{ marginTop: '20px' }}>
            {this.state.loading ? (
              <div
                style={{ marginTop: '50%', width: '100%', textAlign: 'center' }}
              >
                <CircularProgress size={30} sx={{ mb: 2 }} />
                <Typography color='textSecondary' gutterBottom variant='body2'>
                  Memuat pemesanan
                </Typography>
              </div>
            ) : (
              <div>
                {this.state.bookings.length > 0 ? (
                  this.state.bookings.map((booking) => (
                    <Grid
                      item
                      xs={12}
                      key={booking.bookId}
                      sx={{
                        margin: '10px 0',
                      }}
                    >
                      <BookingCardMentor
                        booking={booking}
                        key={booking.bookId}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    sx={{
                      backgroundColor: 'background.default',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src={bookListEmpty}
                      alt=''
                      style={{ width: '224px', margin: '60px auto 10px' }}
                    />
                    <h4
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        marginBottom: 5,
                      }}
                    >
                      Belum ada pesanan
                    </h4>
                    <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='body2'
                    >
                      Kamu belum pernah pesan mentor nih sebelumnya. Yuk, pesan
                      mentor terlebih dahulu!
                    </Typography>
                    <br />
                    <div>
                      <Button
                        variant='contained'
                        sx={{
                          margin: '0 auto 20px',
                          textTransform: 'capitalize',
                        }}
                        to='/app/mentoring'
                        component={RouterLink}
                      >
                        Pilih Mentor
                      </Button>
                    </div>
                    <br />
                  </Box>
                )}
              </div>
            )}
          </Container>
        </TabPanel>

        <TabPanel value={this.state.activeTab} index={1}>
          <Container maxWidth={false} sx={{ marginTop: '20px' }}>
            {this.state.loading ? (
              <div
                style={{ marginTop: '50%', width: '100%', textAlign: 'center' }}
              >
                <CircularProgress size={30} sx={{ mb: 2 }} />
                <Typography color='textSecondary' gutterBottom variant='body2'>
                  Memuat pemesanan
                </Typography>
              </div>
            ) : (
              <div>
                {this.state.cvR.length ? (
                  this.state.cvR.map((cv) => (
                    <Grid
                      item
                      xs={12}
                      key={cv.bookId}
                      sx={{
                        margin: '10px 0',
                      }}
                    >
                      <BookingCardCV booking={cv} key={cv.bookId} />
                    </Grid>
                  ))
                ) : (
                  <Box
                    sx={{
                      backgroundColor: 'background.default',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src={bookListCVEmpty}
                      alt=''
                      style={{ width: '224px', margin: '60px auto 10px' }}
                    />
                    <h4
                      style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        marginBottom: 5,
                      }}
                    >
                      Belum ada pesanan
                    </h4>
                    <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='body2'
                    >
                      Kamu belum pernah pesan CV review nih sebelumnya. Yuk,
                      pesan CV review dahulu!
                    </Typography>
                    <br />
                    <div>
                      <Button
                        variant='contained'
                        sx={{
                          margin: '0 auto 20px',
                          textTransform: 'capitalize',
                        }}
                        to='/app/mentoring'
                        component={RouterLink}
                      >
                        Buka form CV review
                      </Button>
                    </div>
                    <br />
                  </Box>
                )}
              </div>
            )}
          </Container>
        </TabPanel>
      </div>
    );
  }
}
