export const CalenderIcon = ({ width, height }) => (
  <svg
    width={width || '13'}
    height={height || '15'}
    viewBox='0 0 13 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.4805 1.99414H11.2606V2.38497H10.4805V1.99414Z'
      fill='#66757F'
    />
    <path
      d='M0 5.12109V12.9377C0 13.801 0.699975 14.501 1.56332 14.501H10.9432C11.8066 14.501 12.5065 13.801 12.5065 12.9377V5.12109H0Z'
      fill='#CCD6DD'
    />
    <path
      d='M10.9432 1.99414H1.56332C0.699975 1.99414 0 2.69412 0 3.55746V5.5116H12.5065V3.55746C12.5065 2.69412 11.8066 1.99414 10.9432 1.99414Z'
      fill='#E86258'
    />
    <path
      d='M2.67155 3.84358C2.39719 3.84358 2.1748 3.58328 2.1748 3.26163C2.1748 2.94037 2.39719 2.67969 2.67155 2.67969C2.94552 2.67969 3.1679 2.94037 3.1679 3.26163C3.1679 3.58289 2.94552 3.84358 2.67155 3.84358ZM5.01652 3.84358C4.74216 3.84358 4.51978 3.58328 4.51978 3.26163C4.51978 2.94037 4.74216 2.67969 5.01652 2.67969C5.29049 2.67969 5.51288 2.94037 5.51288 3.26163C5.51288 3.58289 5.29049 3.84358 5.01652 3.84358ZM7.3615 3.84358C7.08713 3.84358 6.86475 3.58328 6.86475 3.26163C6.86475 2.94037 7.08713 2.67969 7.3615 2.67969C7.63547 2.67969 7.85824 2.94037 7.85824 3.26163C7.85785 3.58289 7.63547 3.84358 7.3615 3.84358ZM9.70647 3.84358C9.43211 3.84358 9.20973 3.58328 9.20973 3.26163C9.20973 2.94037 9.43211 2.67969 9.70647 2.67969C9.98044 2.67969 10.2032 2.94037 10.2032 3.26163C10.2028 3.58289 9.98044 3.84358 9.70647 3.84358Z'
      fill='#292F33'
    />
    <path
      d='M9.89404 0.5C9.39495 0.5 8.96269 0.813445 8.72389 1.26993C8.48705 0.823216 8.05284 0.5 7.54906 0.5C7.04997 0.5 6.61771 0.813445 6.37892 1.26993C6.14208 0.823216 5.70787 0.5 5.20409 0.5C4.705 0.5 4.27274 0.813445 4.03394 1.26993C3.7971 0.823216 3.36289 0.5 2.85911 0.5C2.10325 0.5 1.49121 1.21092 1.49121 2.06527C1.49121 2.78987 1.89963 3.38275 2.49525 3.59966C2.79189 3.70714 2.97363 3.42496 2.94822 3.19867C2.92673 3.01108 2.80205 2.88875 2.66331 2.80081C2.47376 2.68083 2.27248 2.40685 2.27248 2.06722C2.27248 1.63575 2.53472 1.24844 2.85872 1.24844C3.18272 1.24844 3.44497 1.60214 3.44497 1.99297H3.84205C3.84087 2.0172 3.83579 2.04026 3.83579 2.06488C3.83579 2.78948 4.24421 3.38236 4.83983 3.59927C5.13647 3.70675 5.31821 3.42457 5.2928 3.19828C5.27131 3.01108 5.14663 2.88875 5.00789 2.80081C4.81834 2.68083 4.61706 2.40685 4.61706 2.06722C4.61706 1.63575 4.87931 1.24844 5.2033 1.24844C5.5273 1.24844 5.79033 1.60253 5.79033 1.99336H6.18741C6.18624 2.01759 6.18116 2.04065 6.18116 2.06527C6.18116 2.78987 6.58958 3.38275 7.1852 3.59966C7.48184 3.70714 7.66357 3.42496 7.63817 3.19867C7.61667 3.01147 7.492 2.88914 7.35325 2.8012C7.1637 2.68122 6.96243 2.40725 6.96243 2.06762C6.96243 1.63614 7.22467 1.24883 7.54867 1.24883C7.87267 1.24883 8.1353 1.60253 8.1353 1.99336H8.53239C8.53121 2.01759 8.52613 2.04065 8.52613 2.06527C8.52613 2.78987 8.93455 3.38275 9.53017 3.59966C9.82681 3.70714 10.0085 3.42496 9.98314 3.19867C9.96165 3.01147 9.83697 2.88914 9.69823 2.8012C9.50868 2.68122 9.3074 2.40725 9.3074 2.06762C9.3074 1.63614 9.56965 1.24883 9.89364 1.24883C10.2176 1.24883 10.4803 1.60253 10.4803 1.99336H11.2604C11.2522 1.2117 10.6444 0.5 9.89404 0.5Z'
      fill='#66757F'
    />
    <path
      d='M3.51762 6.29297H5.08094V7.85628H3.51762V6.29297ZM5.47177 6.29297H7.03508V7.85628H5.47177V6.29297ZM7.42591 6.29297H8.98923V7.85628H7.42591V6.29297ZM9.38006 6.29297H10.9434V7.85628H9.38006V6.29297ZM1.56348 8.24711H3.12679V9.81043H1.56348V8.24711ZM3.51762 8.24711H5.08094V9.81043H3.51762V8.24711ZM5.47177 8.24711H7.03508V9.81043H5.47177V8.24711ZM7.42591 8.24711H8.98923V9.81043H7.42591V8.24711ZM9.38006 8.24711H10.9434V9.81043H9.38006V8.24711ZM1.56348 10.2013H3.12679V11.7646H1.56348V10.2013ZM3.51762 10.2013H5.08094V11.7646H3.51762V10.2013ZM5.47177 10.2013H7.03508V11.7646H5.47177V10.2013ZM7.42591 10.2013H8.98923V11.7646H7.42591V10.2013ZM9.38006 10.2013H10.9434V11.7646H9.38006V10.2013ZM1.56348 12.1554H3.12679V13.7187H1.56348V12.1554ZM3.51762 12.1554H5.08094V13.7187H3.51762V12.1554ZM5.47177 12.1554H7.03508V13.7187H5.47177V12.1554Z'
      fill='white'
    />
  </svg>
);

export const ClockIcon = ({ width, height }) => (
  <svg
    width={width || '14'}
    height={height || '15'}
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.7782 2.84965C7.7782 3.2821 7.42704 3.63287 7.00043 3.63287C6.57382 3.63287 6.22266 3.2821 6.22266 2.84965V1.28322C6.22266 0.850387 6.57382 0.5 7.00043 0.5C7.42704 0.5 7.7782 0.850387 7.7782 1.28322V2.84965Z'
      fill='#FACD11'
    />
    <path
      d='M3.56986 14.4996C3.48275 14.4996 3.39409 14.4793 3.31087 14.4377C3.02465 14.2923 2.90837 13.94 3.05148 13.6495L6.52813 6.60011C6.62613 6.401 6.82679 6.27539 7.04651 6.27539C7.26623 6.27539 7.4669 6.401 7.5649 6.60011L11.0419 13.6495C11.185 13.94 11.0692 14.2923 10.7829 14.4377C10.4967 14.5824 10.1487 14.465 10.0056 14.1748L7.0469 8.17627L4.08864 14.1748C3.98675 14.3806 3.7822 14.4996 3.56986 14.4996Z'
      fill='#FFAC33'
    />
    <path
      d='M7.04734 8.43049C6.94507 8.43049 6.84668 8.38926 6.77434 8.31576L1.3661 2.83285C1.21521 2.67963 1.21521 2.43191 1.3661 2.27908C1.51698 2.12624 1.76159 2.12624 1.91209 2.27908L7.04734 7.48471L12.1818 2.27869C12.3331 2.12585 12.5773 2.12585 12.7282 2.27869C12.8791 2.43191 12.8791 2.67963 12.7282 2.83246L7.32073 8.31576C7.24801 8.38888 7.15001 8.43049 7.04734 8.43049Z'
      fill='#58595B'
    />
    <path
      d='M13.2278 8.03957C13.2278 11.4999 10.4608 14.3049 7.04679 14.3049C3.63353 14.3049 0.866211 11.4999 0.866211 8.03957C0.866211 4.57848 3.63353 1.77344 7.04679 1.77344C10.4604 1.77305 13.2278 4.57848 13.2278 8.03957Z'
      fill='#E86258'
    />
    <path
      d='M11.6828 8.03759C11.6828 10.633 9.60807 12.7369 7.04725 12.7369C4.48721 12.7369 2.41211 10.633 2.41211 8.03759C2.41211 5.44177 4.48721 3.33789 7.04725 3.33789C9.60807 3.3375 11.6828 5.44177 11.6828 8.03759Z'
      fill='#E6E7E8'
    />
    <path
      d='M11.6822 0.988281C11.0421 0.988281 10.463 1.25117 10.0434 1.67661L13.3213 4.99926C13.7405 4.57421 13.9999 3.98699 13.9999 3.33793C13.9999 2.04061 12.962 0.988281 11.6822 0.988281ZM2.31776 0.988281C2.95748 0.988281 3.53692 1.25117 3.95653 1.67661L0.678607 4.99926C0.259387 4.57421 0 3.98699 0 3.33793C0 2.04061 1.03755 0.988281 2.31776 0.988281Z'
      fill='#FACD11'
    />
    <path
      d='M8.94465 8.27775H7.00021C6.78555 8.27775 6.61133 8.10392 6.61133 7.88887V4.38889C6.61133 4.17422 6.78555 4 7.00021 4C7.21488 4 7.3891 4.17422 7.3891 4.38889V7.49998H8.94465C9.1597 7.49998 9.33354 7.6742 9.33354 7.88887C9.33354 8.10392 9.1597 8.27775 8.94465 8.27775Z'
      fill='#414042'
    />
  </svg>
);

export const EmptyRatingIcon = ({ ...rest }) => (
  <svg
    width='38'
    height='36'
    viewBox='0 0 51 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M25.5003 36.8659L26.3745 37.4928L38.3351 46.0703C38.3355 46.0706 38.3359 46.0709 38.3363 46.0711C38.5877 46.2503 38.8731 46.3367 39.1577 46.3367C39.4473 46.3367 39.732 46.2487 39.9776 46.069L39.982 46.0657C40.4718 45.71 40.6769 45.08 40.4943 44.5118L40.4919 44.5043L40.4896 44.4968L36.0278 30.0968L35.705 29.055L36.5966 28.4267L48.4375 20.0829C48.9179 19.7225 49.1171 19.0951 48.931 18.522L48.9304 18.5204C48.7473 17.9543 48.2157 17.5601 47.6048 17.5544C47.6037 17.5544 47.6026 17.5544 47.6015 17.5544L32.851 17.5323L31.7653 17.5307L31.4277 16.4989L26.8345 2.46352C26.8341 2.46249 26.8338 2.46145 26.8334 2.46042C26.6406 1.88571 26.1038 1.5 25.5003 1.5C24.8945 1.5 24.3588 1.88649 24.1683 2.4614L24.1677 2.46351L19.4927 16.5061L19.1516 17.5307L18.0717 17.5323L3.39623 17.5544C3.39507 17.5544 3.3939 17.5544 3.39274 17.5544C2.78933 17.5601 2.25598 17.9503 2.06802 18.5226C1.88351 19.0963 2.0835 19.7234 2.56225 20.0833L14.4026 28.4268L15.2942 29.055L14.9714 30.0968L10.5096 44.4968L10.506 44.5087L10.5059 44.5086C10.3229 45.0824 10.5292 45.7113 11.017 46.0645L11.0227 46.0687C11.5074 46.4233 12.1652 46.4277 12.6629 46.0722L25.5003 36.8659ZM25.5003 36.8659L24.6262 37.4928L12.6641 46.0714L25.5003 36.8659Z'
      stroke='#FACD11'
      stroke-width='3'
    />
  </svg>
);

export const FullRatingIcon = ({ ...rest }) => (
  <svg
    width='38'
    height='36'
    viewBox='0 0 51 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M39.1577 47.8367C38.5635 47.8367 37.9724 47.6544 37.4635 47.2911L25.5003 38.7117L13.5371 47.2911C12.518 48.0205 11.1503 48.0205 10.1371 47.2794C9.12387 46.5455 8.6974 45.2426 9.07682 44.0529L13.5386 29.6529L1.68271 21.2985C0.675356 20.5559 0.256239 19.25 0.641532 18.0588C1.02977 16.8706 2.13565 16.0632 3.38565 16.0544L18.0695 16.0323L22.7444 1.9897C23.1386 0.799999 24.2489 0 25.5003 0C26.7518 0 27.8621 0.801469 28.2577 1.9897L32.8533 16.0323L47.6121 16.0544C48.8665 16.0632 49.9738 16.872 50.3577 18.0588C50.7444 19.25 50.3253 20.5559 49.3165 21.2985L37.4606 29.6529L41.9224 44.0529C42.3047 45.2426 41.8738 46.5455 40.8635 47.2794C40.3532 47.6529 39.7562 47.8367 39.1577 47.8367Z'
      fill='#FACD11'
    />
  </svg>
);

export const Rating1Icon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40 20C40 31.0456 31.0456 40 20 40C8.95444 40 0 31.0456 0 20C0 8.95444 8.95444 0 20 0C31.0456 0 40 8.95444 40 20Z'
      fill='#BDBDBD'
    />
    <path
      d='M26.0957 32.0884C26.0835 32.0395 24.8235 27.2228 20.0013 27.2228C15.1791 27.2228 13.9191 32.0395 13.9069 32.0884C13.848 32.325 13.9557 32.5673 14.1646 32.6928C14.3735 32.8173 14.6457 32.7884 14.8269 32.6262C14.8369 32.6173 15.9524 31.6673 20.0013 31.6673C23.9902 31.6673 25.1313 32.5895 25.1746 32.6262C25.2802 32.7262 25.418 32.7784 25.5569 32.7784C25.6502 32.7784 25.7446 32.755 25.8302 32.7073C26.048 32.5828 26.1569 32.3306 26.0957 32.0884ZM32.2235 13.3339C26.0524 13.3339 23.5546 8.48171 23.4513 8.27504C23.1769 7.72615 23.3991 7.05837 23.948 6.78393C24.4957 6.50949 25.1613 6.7306 25.4369 7.27615C25.5202 7.43837 27.4602 11.1117 32.2235 11.1117C32.838 11.1117 33.3346 11.6095 33.3346 12.2228C33.3346 12.8362 32.838 13.3339 32.2235 13.3339ZM7.77908 13.3339C7.16575 13.3339 6.66797 12.8362 6.66797 12.2228C6.66797 11.6095 7.16575 11.1117 7.77908 11.1117C13.4269 11.1117 14.4413 7.64504 14.4824 7.49726C14.6435 6.9106 15.2513 6.55504 15.8369 6.70949C16.4257 6.86393 16.7824 7.45726 16.6346 8.04837C16.5802 8.26393 15.228 13.3339 7.77908 13.3339Z'
      fill='#252525'
    />
    <path
      d='M13.3325 22.2221C14.8666 22.2221 16.1102 20.481 16.1102 18.3332C16.1102 16.1855 14.8666 14.4443 13.3325 14.4443C11.7983 14.4443 10.5547 16.1855 10.5547 18.3332C10.5547 20.481 11.7983 22.2221 13.3325 22.2221Z'
      fill='#252525'
    />
    <path
      d='M26.6684 22.2221C28.2025 22.2221 29.4462 20.481 29.4462 18.3332C29.4462 16.1855 28.2025 14.4443 26.6684 14.4443C25.1343 14.4443 23.8906 16.1855 23.8906 18.3332C23.8906 20.481 25.1343 22.2221 26.6684 22.2221Z'
      fill='#252525'
    />
  </svg>
);

export const Rating1Active = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M60 30C60 46.5683 46.5683 60 30 60C13.4317 60 0 46.5683 0 30C0 13.4317 13.4317 0 30 0C46.5683 0 60 13.4317 60 30Z'
      fill='#FFCC4D'
    />
    <path
      d='M39.1417 48.1326C39.1233 48.0592 37.2333 40.8342 30 40.8342C22.7667 40.8342 20.8767 48.0592 20.8583 48.1326C20.77 48.4876 20.9317 48.8509 21.245 49.0392C21.5583 49.2259 21.9667 49.1826 22.2383 48.9392C22.2533 48.9259 23.9267 47.5009 30 47.5009C35.9833 47.5009 37.695 48.8842 37.76 48.9392C37.9183 49.0892 38.125 49.1676 38.3333 49.1676C38.4733 49.1676 38.615 49.1326 38.7433 49.0609C39.07 48.8742 39.2333 48.4959 39.1417 48.1326ZM48.3333 20.0009C39.0767 20.0009 35.33 12.7226 35.175 12.4126C34.7633 11.5892 35.0967 10.5876 35.92 10.1759C36.7417 9.76423 37.74 10.0959 38.1533 10.9142C38.2783 11.1576 41.1883 16.6676 48.3333 16.6676C49.255 16.6676 50 17.4142 50 18.3342C50 19.2542 49.255 20.0009 48.3333 20.0009ZM11.6667 20.0009C10.7467 20.0009 10 19.2542 10 18.3342C10 17.4142 10.7467 16.6676 11.6667 16.6676C20.1383 16.6676 21.66 11.4676 21.7217 11.2459C21.9633 10.3659 22.875 9.83256 23.7533 10.0642C24.6367 10.2959 25.1717 11.1859 24.95 12.0726C24.8683 12.3959 22.84 20.0009 11.6667 20.0009Z'
      fill='#664500'
    />
    <path
      d='M19.9987 33.3337C22.2999 33.3337 24.1654 30.722 24.1654 27.5003C24.1654 24.2787 22.2999 21.667 19.9987 21.667C17.6975 21.667 15.832 24.2787 15.832 27.5003C15.832 30.722 17.6975 33.3337 19.9987 33.3337Z'
      fill='#664500'
    />
    <path
      d='M39.9987 33.3337C42.2999 33.3337 44.1654 30.722 44.1654 27.5003C44.1654 24.2787 42.2999 21.667 39.9987 21.667C37.6975 21.667 35.832 24.2787 35.832 27.5003C35.832 30.722 37.6975 33.3337 39.9987 33.3337Z'
      fill='#664500'
    />
  </svg>
);

export const Rating2Icon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z'
      fill='#BDBDBD'
    />
    <path
      d='M28.3182 30.4212C28.2682 30.2223 27.0204 25.5557 20.0015 25.5557C12.9815 25.5557 11.7348 30.2223 11.6848 30.4212C11.6237 30.6623 11.7326 30.9123 11.9482 31.0368C12.1648 31.1601 12.4359 31.1234 12.6148 30.9512C12.6359 30.9301 14.7859 28.889 20.0015 28.889C25.2171 28.889 27.3682 30.9301 27.3882 30.9501C27.4948 31.0557 27.6371 31.1112 27.7793 31.1112C27.8726 31.1112 27.9671 31.0879 28.0526 31.0401C28.2704 30.9157 28.3793 30.6634 28.3182 30.4212Z'
      fill='#252525'
    />
    <path
      d='M13.3325 18.8891C14.8666 18.8891 16.1102 17.148 16.1102 15.0002C16.1102 12.8524 14.8666 11.1113 13.3325 11.1113C11.7983 11.1113 10.5547 12.8524 10.5547 15.0002C10.5547 17.148 11.7983 18.8891 13.3325 18.8891Z'
      fill='#252525'
    />
    <path
      d='M26.6684 18.8891C28.2025 18.8891 29.4462 17.148 29.4462 15.0002C29.4462 12.8524 28.2025 11.1113 26.6684 11.1113C25.1343 11.1113 23.8906 12.8524 23.8906 15.0002C23.8906 17.148 25.1343 18.8891 26.6684 18.8891Z'
      fill='#252525'
    />
  </svg>
);

export const Rating2Active = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z'
      fill='#FFCC4D'
    />
    <path
      d='M42.4753 45.6313C42.4003 45.333 40.5286 38.333 30.0003 38.333C19.4703 38.333 17.6003 45.333 17.5253 45.6313C17.4336 45.993 17.597 46.368 17.9203 46.5547C18.2453 46.7397 18.652 46.6847 18.9203 46.4263C18.952 46.3947 22.177 43.333 30.0003 43.333C37.8236 43.333 41.0503 46.3947 41.0803 46.4247C41.2403 46.583 41.4536 46.6663 41.667 46.6663C41.807 46.6663 41.9486 46.6313 42.077 46.5597C42.4036 46.373 42.567 45.9947 42.4753 45.6313Z'
      fill='#664500'
    />
    <path
      d='M19.9987 28.3337C22.2999 28.3337 24.1654 25.722 24.1654 22.5003C24.1654 19.2787 22.2999 16.667 19.9987 16.667C17.6975 16.667 15.832 19.2787 15.832 22.5003C15.832 25.722 17.6975 28.3337 19.9987 28.3337Z'
      fill='#664500'
    />
    <path
      d='M39.9987 28.3337C42.2999 28.3337 44.1654 25.722 44.1654 22.5003C44.1654 19.2787 42.2999 16.667 39.9987 16.667C37.6975 16.667 35.832 19.2787 35.832 22.5003C35.832 25.722 37.6975 28.3337 39.9987 28.3337Z'
      fill='#664500'
    />
  </svg>
);

export const Rating3Icon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40 20C40 31.0456 31.0456 40 20 40C8.95556 40 0 31.0456 0 20C0 8.95556 8.95556 0 20 0C31.0456 0 40 8.95556 40 20Z'
      fill='#BDBDBD'
    />
    <path
      d='M12.7778 22.2221C14.3119 22.2221 15.5556 20.481 15.5556 18.3332C15.5556 16.1855 14.3119 14.4443 12.7778 14.4443C11.2437 14.4443 10 16.1855 10 18.3332C10 20.481 11.2437 22.2221 12.7778 22.2221Z'
      fill='#252525'
    />
    <path
      d='M27.2231 22.2221C28.7572 22.2221 30.0009 20.481 30.0009 18.3332C30.0009 16.1855 28.7572 14.4443 27.2231 14.4443C25.689 14.4443 24.4453 16.1855 24.4453 18.3332C24.4453 20.481 25.689 22.2221 27.2231 22.2221Z'
      fill='#252525'
    />
    <path
      d='M27.776 28.8892H12.2205C11.6072 28.8892 11.1094 28.3925 11.1094 27.7781C11.1094 27.1637 11.6072 26.667 12.2205 26.667H27.776C28.3905 26.667 28.8872 27.1637 28.8872 27.7781C28.8872 28.3925 28.3905 28.8892 27.776 28.8892Z'
      fill='#252525'
    />
  </svg>
);

export const Rating3Active = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M60 30C60 46.5683 46.5683 60 30 60C13.4333 60 0 46.5683 0 30C0 13.4333 13.4333 0 30 0C46.5683 0 60 13.4333 60 30Z'
      fill='#FFCC4D'
    />
    <path
      d='M19.1667 33.3337C21.4679 33.3337 23.3333 30.722 23.3333 27.5003C23.3333 24.2787 21.4679 21.667 19.1667 21.667C16.8655 21.667 15 24.2787 15 27.5003C15 30.722 16.8655 33.3337 19.1667 33.3337Z'
      fill='#664500'
    />
    <path
      d='M40.8346 33.3337C43.1358 33.3337 45.0013 30.722 45.0013 27.5003C45.0013 24.2787 43.1358 21.667 40.8346 21.667C38.5334 21.667 36.668 24.2787 36.668 27.5003C36.668 30.722 38.5334 33.3337 40.8346 33.3337Z'
      fill='#664500'
    />
    <path
      d='M41.668 43.3333H18.3346C17.4146 43.3333 16.668 42.5883 16.668 41.6667C16.668 40.745 17.4146 40 18.3346 40H41.668C42.5896 40 43.3346 40.745 43.3346 41.6667C43.3346 42.5883 42.5896 43.3333 41.668 43.3333Z'
      fill='#664500'
    />
  </svg>
);

export const Rating4Icon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40 20C40 31.0456 31.0456 40 20 40C8.95444 40 0 31.0456 0 20C0 8.95444 8.95444 0 20 0C31.0456 0 40 8.95444 40 20Z'
      fill='#BDBDBD'
    />
    <path
      d='M7.77821 25.5554C10.8465 25.5554 13.3338 23.0681 13.3338 19.9999C13.3338 16.9316 10.8465 14.4443 7.77821 14.4443C4.70996 14.4443 2.22266 16.9316 2.22266 19.9999C2.22266 23.0681 4.70996 25.5554 7.77821 25.5554Z'
      fill='#252525'
    />
    <path
      d='M32.2235 25.5554C35.2918 25.5554 37.7791 23.0681 37.7791 19.9999C37.7791 16.9316 35.2918 14.4443 32.2235 14.4443C29.1553 14.4443 26.668 16.9316 26.668 19.9999C26.668 23.0681 29.1553 25.5554 32.2235 25.5554Z'
      fill='#252525'
    />
    <path
      d='M30.3716 24.0327C30.1738 23.8538 29.8783 23.8427 29.6661 24.0004C29.6227 24.0327 25.3083 27.2227 19.9994 27.2227C14.7038 27.2227 10.3749 24.0327 10.3327 24.0004C10.1205 23.8427 9.82495 23.856 9.62717 24.0327C9.4305 24.2104 9.38606 24.5038 9.52273 24.7304C9.66606 24.9693 13.0972 30.556 19.9994 30.556C26.9016 30.556 30.3338 24.9693 30.4761 24.7304C30.6127 24.5027 30.5694 24.2104 30.3716 24.0327ZM8.88717 16.6671C8.7205 16.6671 8.5505 16.6293 8.39162 16.5493C7.84273 16.2749 7.6205 15.6071 7.89495 15.0582C7.95273 14.9427 9.35939 12.2227 13.3327 12.2227C17.3072 12.2227 18.7127 14.9438 18.7705 15.0593C19.0449 15.6082 18.8227 16.276 18.2738 16.5504C17.7272 16.8227 17.0683 16.6049 16.7894 16.066C16.7416 15.9771 15.8672 14.4449 13.3327 14.4449C10.7583 14.4449 9.88939 16.0371 9.88162 16.0527C9.68717 16.4427 9.29495 16.6671 8.88717 16.6671ZM31.1116 16.6671C30.7038 16.6671 30.3116 16.4427 30.1161 16.0527C30.0883 16.0015 29.2205 14.4449 26.6661 14.4449C24.1116 14.4449 23.2438 16.0015 23.2083 16.0671C22.9194 16.6015 22.2538 16.8115 21.7138 16.5338C21.1749 16.2538 20.9561 15.6015 21.2272 15.0582C21.285 14.9438 22.6916 12.2227 26.6661 12.2227C30.6405 12.2227 32.0472 14.9438 32.105 15.0593C32.3794 15.6082 32.1572 16.276 31.6083 16.5504C31.4483 16.6293 31.2783 16.6671 31.1116 16.6671Z'
      fill='#252525'
    />
  </svg>
);

export const Rating4Active = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M60 30C60 46.5683 46.5683 60 30 60C13.4317 60 0 46.5683 0 30C0 13.4317 13.4317 0 30 0C46.5683 0 60 13.4317 60 30Z'
      fill='#FFCC4D'
    />
    <path
      d='M11.6654 38.3327C16.2677 38.3327 19.9987 34.6017 19.9987 29.9993C19.9987 25.397 16.2677 21.666 11.6654 21.666C7.06299 21.666 3.33203 25.397 3.33203 29.9993C3.33203 34.6017 7.06299 38.3327 11.6654 38.3327Z'
      fill='#FF7892'
    />
    <path
      d='M48.3333 38.3327C52.9357 38.3327 56.6667 34.6017 56.6667 29.9993C56.6667 25.397 52.9357 21.666 48.3333 21.666C43.731 21.666 40 25.397 40 29.9993C40 34.6017 43.731 38.3327 48.3333 38.3327Z'
      fill='#FF7892'
    />
    <path
      d='M45.5594 36.049C45.2627 35.7807 44.8194 35.764 44.501 36.0007C44.436 36.049 37.9644 40.834 30.001 40.834C22.0577 40.834 15.5644 36.049 15.501 36.0007C15.1827 35.764 14.7394 35.784 14.4427 36.049C14.1477 36.3157 14.081 36.7557 14.286 37.0957C14.501 37.454 19.6477 45.834 30.001 45.834C40.3544 45.834 45.5027 37.454 45.716 37.0957C45.921 36.754 45.856 36.3157 45.5594 36.049V36.049ZM13.3327 25.0007C13.0827 25.0007 12.8277 24.944 12.5894 24.824C11.766 24.4123 11.4327 23.4107 11.8444 22.5873C11.931 22.414 14.041 18.334 20.001 18.334C25.9627 18.334 28.071 22.4157 28.1577 22.589C28.5694 23.4123 28.236 24.414 27.4127 24.8257C26.5927 25.234 25.6044 24.9073 25.186 24.099C25.1144 23.9657 23.8027 21.6673 20.001 21.6673C16.1394 21.6673 14.836 24.0557 14.8244 24.079C14.5327 24.664 13.9444 25.0007 13.3327 25.0007V25.0007ZM46.6694 25.0007C46.0577 25.0007 45.4694 24.664 45.176 24.079C45.1344 24.0023 43.8327 21.6673 40.001 21.6673C36.1694 21.6673 34.8677 24.0023 34.8144 24.1007C34.381 24.9023 33.3827 25.2173 32.5727 24.8007C31.7644 24.3807 31.436 23.4023 31.8427 22.5873C31.9294 22.4157 34.0394 18.334 40.001 18.334C45.9627 18.334 48.0727 22.4157 48.1594 22.589C48.571 23.4123 48.2377 24.414 47.4144 24.8257C47.1744 24.944 46.9194 25.0007 46.6694 25.0007V25.0007Z'
      fill='#664500'
    />
  </svg>
);

export const Rating5Icon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40 20C40 31.0456 31.0456 40 20 40C8.95444 40 0 31.0456 0 20C0 8.95444 8.95444 0 20 0C31.0456 0 40 8.95444 40 20Z'
      fill='#BDBDBD'
    />
    <path
      d='M19.9996 23.3334C15.974 23.3334 13.3029 22.8645 9.99957 22.2223C9.24512 22.0767 7.77734 22.2223 7.77734 24.4445C7.77734 28.8889 12.8829 34.4445 19.9996 34.4445C27.1151 34.4445 32.2218 28.8889 32.2218 24.4445C32.2218 22.2223 30.754 22.0756 29.9996 22.2223C26.6962 22.8645 24.0251 23.3334 19.9996 23.3334Z'
      fill='#252525'
    />
    <path
      d='M10 24.4443C10 24.4443 13.3333 25.5554 20 25.5554C26.6667 25.5554 30 24.4443 30 24.4443C30 24.4443 27.7778 28.8888 20 28.8888C12.2222 28.8888 10 24.4443 10 24.4443Z'
      fill='white'
    />
    <path
      d='M17.4261 4.90288L12.3795 5.79288L9.77948 1.06732C9.49281 0.546211 8.90392 0.267322 8.3217 0.369544C7.73615 0.472878 7.28059 0.937322 7.18948 1.52399L6.3617 6.85399L1.31504 7.74399C0.720591 7.84843 0.261702 8.32732 0.180591 8.92621C0.0994798 9.5251 0.415035 10.1073 0.961702 10.3673L5.48392 12.514L4.65281 17.8607C4.56059 18.4562 4.86281 19.0429 5.40059 19.3151C5.59948 19.4151 5.81281 19.4629 6.02504 19.4629C6.38837 19.4629 6.74726 19.3207 7.01504 19.0495L10.9173 15.0929L15.9373 17.4762C16.4817 17.734 17.1306 17.6129 17.5439 17.174C17.9573 16.7351 18.0395 16.0807 17.7495 15.5529L15.1406 10.8107L18.6573 7.24621C19.0817 6.81621 19.1784 6.16066 18.8984 5.6251C18.6161 5.08955 18.0173 4.79954 17.4261 4.90288ZM22.5773 4.90288L27.6239 5.79288L30.2239 1.06732C30.5106 0.546211 31.0995 0.267322 31.6817 0.369544C32.2661 0.472878 32.7217 0.937322 32.8128 1.52399L33.6406 6.85399L38.6873 7.74399C39.2828 7.84843 39.7417 8.32621 39.8217 8.9251C39.9017 9.52399 39.5873 10.1062 39.0406 10.3662L34.5184 12.5129L35.3495 17.8595C35.4417 18.4551 35.1395 19.0418 34.6017 19.314C34.4028 19.414 34.1895 19.4618 33.9773 19.4618C33.6139 19.4618 33.255 19.3195 32.9873 19.0484L29.085 15.0918L24.065 17.4751C23.5206 17.7329 22.8717 17.6118 22.4584 17.1729C22.045 16.734 21.9628 16.0795 22.2528 15.5518L24.8617 10.8107L21.345 7.24621C20.9206 6.81621 20.8239 6.16066 21.1039 5.6251C21.3873 5.08955 21.9861 4.79954 22.5773 4.90288Z'
      fill='#252525'
    />
  </svg>
);

export const Rating5Active = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M60 30C60 46.5683 46.5683 60 30 60C13.4317 60 0 46.5683 0 30C0 13.4317 13.4317 0 30 0C46.5683 0 60 13.4317 60 30Z'
      fill='#FFCC4D'
    />
    <path
      d='M30.0013 35.0011C23.963 35.0011 19.9563 34.2977 15.0013 33.3344C13.8696 33.1161 11.668 33.3344 11.668 36.6677C11.668 43.3344 19.3263 51.6677 30.0013 51.6677C40.6746 51.6677 48.3346 43.3344 48.3346 36.6677C48.3346 33.3344 46.133 33.1144 45.0013 33.3344C40.0463 34.2977 36.0396 35.0011 30.0013 35.0011Z'
      fill='#664500'
    />
    <path
      d='M15 36.666C15 36.666 20 38.3327 30 38.3327C40 38.3327 45 36.666 45 36.666C45 36.666 41.6667 43.3327 30 43.3327C18.3333 43.3327 15 36.666 15 36.666Z'
      fill='white'
    />
    <path
      d='M26.1373 7.3548L18.5673 8.68981L14.6673 1.60147C14.2373 0.819805 13.3539 0.401472 12.4806 0.554805C11.6023 0.709805 10.9189 1.40647 10.7823 2.28647L9.5406 10.2815L1.9706 11.6165C1.07893 11.7731 0.3906 12.4915 0.268933 13.3898C0.147267 14.2881 0.6206 15.1615 1.4406 15.5515L8.22393 18.7715L6.97727 26.7915C6.83893 27.6848 7.29227 28.5648 8.09893 28.9731C8.39727 29.1231 8.71727 29.1948 9.0356 29.1948C9.5806 29.1948 10.1189 28.9815 10.5206 28.5748L16.3739 22.6398L23.9039 26.2148C24.7206 26.6015 25.6939 26.4198 26.3139 25.7615C26.9339 25.1031 27.0573 24.1215 26.6223 23.3298L22.7089 16.2165L27.9839 10.8698C28.6206 10.2248 28.7656 9.24147 28.3456 8.43814C27.9223 7.63481 27.0239 7.19981 26.1373 7.3548ZM33.8639 7.3548L41.4339 8.68981L45.3339 1.60147C45.7639 0.819805 46.6473 0.401472 47.5206 0.554805C48.3973 0.709805 49.0806 1.40647 49.2173 2.28647L50.4589 10.2815L58.0289 11.6165C58.9223 11.7731 59.6106 12.4898 59.7306 13.3881C59.8506 14.2865 59.3789 15.1598 58.5589 15.5498L51.7756 18.7698L53.0223 26.7898C53.1606 27.6831 52.7073 28.5631 51.9006 28.9715C51.6023 29.1215 51.2823 29.1931 50.9639 29.1931C50.4189 29.1931 49.8806 28.9798 49.4789 28.5731L43.6256 22.6381L36.0956 26.2131C35.2789 26.5998 34.3056 26.4181 33.6856 25.7598C33.0656 25.1015 32.9423 24.1198 33.3773 23.3281L37.2906 16.2165L32.0156 10.8698C31.3789 10.2248 31.2339 9.24147 31.6539 8.43814C32.0789 7.63481 32.9773 7.19981 33.8639 7.3548Z'
      fill='#E95F28'
    />
  </svg>
);
