import {
  SHOW_MODAL,
  CLOSE_MODAL,
  ASSESSMENT_RESULT,
  SET_NAVBAR_TITLE,
  SET_FILTER_MENTOR,
} from '../events';
import store from '../store';

const { dispatch } = store;

export const assessmentResult = (result) => {
  dispatch({
    type: ASSESSMENT_RESULT,
    payload: result,
  });
};

export const showModal = (type, title, content, footer) => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      type,
      title,
      content,
      footer,
    },
  });
};

export const closeModal = () => {
  dispatch({
    type: CLOSE_MODAL,
  });
};

export const setNavbarTitle = (title) => {
  dispatch({
    type: SET_NAVBAR_TITLE,
    payload: title,
  });
};

export const setFilterMentor = (
  products,
  filterServiceLine,
  filterIndustry,
  filterSkills,
  sortMentor,
  countServiceLine,
  countIndustry,
  countSkills,
) => {
  let finalResult = [];
  const filtered = [];
  const filteredSrv = filterServiceLine.filter((srv) => srv.checked);
  const filteredInd = filterIndustry.filter((ind) => ind.checked);
  const filteredSkl = filterSkills.filter((skl) => skl.checked);

  products.forEach((product) => {
    const filterArrSrv = [];
    const filterArrInd = [];
    const filterArrSkl = [];
    // ServiceLine
    filteredSrv.forEach((fltr) => {
      const indexOf = product.serviceLine.indexOf(fltr.value);
      filterArrSrv.push(indexOf);
    });
    const isNotExistSrv = filterArrSrv.findIndex((fltr) => fltr === -1);
    // industry
    filteredInd.forEach((fltr) => {
      const indexOf = product.industry.indexOf(fltr.value);
      filterArrInd.push(indexOf);
    });
    const isNotExistInd = filterArrInd.findIndex((fltr) => fltr === -1);
    // skills
    filteredSkl.forEach((fltr) => {
      const indexOf = product.skills.indexOf(fltr.value);
      filterArrSkl.push(indexOf);
    });
    const isNotExistSkl = filterArrSkl.findIndex((fltr) => fltr === -1);
    if (isNotExistSrv === -1 && isNotExistInd === -1 && isNotExistSkl === -1) {
      filtered.push(product);
    }
  });

  // tidak ada filter yg dipilih
  if (!filteredSrv.length && !filteredInd.length && !filteredSkl.length) {
    products.forEach((product) => {
      filtered.push(product);
    });
  }

  finalResult = [...new Set(filtered)];
  // section sort if sort first
  const sortedValue = sortMentor.filter((srt) => srt.checked);
  if (sortedValue[0]) {
    if (sortedValue[0].sort === 'desc') {
      finalResult.sort((a, b) => {
        return b[`${sortedValue[0].value}`] - a[`${sortedValue[0].value}`];
      });
    } else if (sortedValue[0].sort === 'asc') {
      finalResult.sort((a, b) => {
        return a[`${sortedValue[0].value}`] - b[`${sortedValue[0].value}`];
      });
    }
  }

  dispatch({
    type: SET_FILTER_MENTOR,
    payload: {
      finalResult,
      filterServiceLine,
      filterIndustry,
      filterSkills,
      countServiceLine,
      countIndustry,
      countSkills,
    },
  });
};
