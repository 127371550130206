import {
  MentoringCard,
  // CvMakerCard,
  // CvReviewCard,
  AtsCheckerCard,
  CvMakerReviewCard,
} from 'src/assets/cards/landing/products';
import mentor from 'src/assets/img/mentor.svg';
import cvMaker from 'src/assets/img/cvMaker.svg';
// import cvReview from 'src/assets/img/cvReview.svg';
import atsChecker from 'src/assets/img/atsChecker.svg';

const product = [
  {
    id: 'mentoring',
    title: 'Career Mentoring',
    body: `Konsultasikan perjalanan kariermu bersama mentor-mentor terpilih dari berbagai industri!`,
    iconMobile: <MentoringCard />,
    icon: mentor,
    pushType: 'app',
    pushTo: '/app/home',
    buttonTitle: 'Lihat Mentor',
  },
  {
    id: 'atsChecker',
    title: 'ATS Checker',
    body: 'Cek apakah CV kamu terbaca oleh ATS dengan cepat dan mudah!',
    iconMobile: <AtsCheckerCard />,
    icon: atsChecker,
    pushType: 'app',
    pushTo: '/app/cv/dashboard?tab=ats',
    buttonTitle: 'Cek CV',
  },
  {
    id: 'cvMakerReview',
    title: 'CV Maker & CV Review',
    body: 'Buat CV ATS-friendly dengan gratis. Dan CV-mu juga bisa di-review oleh profesional mentor langsung!',
    iconMobile: <CvMakerReviewCard />,
    icon: cvMaker,
    pushType: 'app',
    pushTo: '/app/cv/dashboard',
    buttonTitle: 'Buat & Review CV',
  },
  // {
  //   id: 'cvReview',
  //   title: 'CV Review',
  //   body: 'Layanan CV Review terpercaya oleh senior praktisi HR di Indonesia.',
  //   iconMobile: <CvReviewCard />,
  //   icon: cvReview,
  //   pushType: 'external',
  //   pushTo: 'https://bit.ly/CVreviewAksel',
  //   buttonTitle: 'Pesan CV Review ',
  // },
];

export default product;
