import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@material-ui/core';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Assessment from "./Assessment";
// import reactGA from 'react-ga';
import Footer from 'src/components/landing/Footer';
import Details from 'src/components/landing/Details';
import ServicesAccordion from 'src/components/landing/ServicesAccordion';

// reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
//   standardImplementation: true,
// }); // TBD until the service is deployed
// reactGA.pageview('/faq'); // Main page

const Booking = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}
    >
      <Helmet>
        <title>Aksel - Buat CV ATS Friendly Gratis dan Mudah</title>
      </Helmet>
      {/* TODO: kalau mau dipaksa 375px */}
      {/* <Container> */}
      <Grid container style={{ padding: 0, maxWidth: '768px' }}>
        <Grid item xs={12}>
          <div style={{ padding: '36px 24px 24px 24px', textAlign: 'center' }}>
            <Typography variant='header' gutterBottom>
              Frequently Asked Qustions
            </Typography>
          </div>
        </Grid>
        {/* ----------- are u ready card ------------- */}
        <Grid item xs={12}>
          <div style={{ padding: '0px 24px 24px 24px', textAlign: 'left' }}>
            <ServicesAccordion />
          </div>
        </Grid>
        {/* --------- dropdown ------------ */}
        <Grid item xs={12}>
          <Details />
        </Grid>
        {/* ------- footer ----------- */}
        <Footer />
      </Grid>
      {/* </Container> */}
    </div>
  );
};

export default Booking;
