import React from 'react';
import { Typography, Grid, IconButton } from '@material-ui/core';

import banners from 'src/templates/akselBanner';
import akselProducts from 'src/templates/akselProduct';

const Products = ({ handleProduct }) => (
  <>
    <div style={{ paddingLeft: 25, paddingTop: banners.length ? 32 : 0 }}>
      <Typography variant='header'>Produk Aksel</Typography>
      <Typography
        variant='p'
        style={{
          marginTop: 6,
          marginBottom: 16,
          maxWidth: 290,
          color: 'rgba(0, 0, 0, 0.64)',
        }}
      >
        Aksel menyediakan berbagai layanan untuk mengakselerasikan kariermu!
      </Typography>
    </div>
    <Grid
      container
      padding='0 14px 30px'
      style={{ flexWrap: 'nowrap', overflowX: 'auto' }}
    >
      {akselProducts.map((idx) => (
        <Grid key={idx.id} item xs={4} sm={4}>
          <Grid container justify='center'>
            <IconButton
              sx={{
                padding: '0 !important',
                margin: '0 auto',
                display: 'flex',
              }}
              onClick={() => handleProduct(idx)}
            >
              {idx.iconMobile}
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </>
);

export default Products;
