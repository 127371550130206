import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import download from 'downloadjs';
import axios from 'axios';
import { b64toBlob } from 'src/utils/utilities';
import CustomButton from 'src/components/button';

const DownloadCV = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const content = {
      id: id,
      type: 'multi',
    };
    axios
      .post(
        'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
        content,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((response) => {
        const blob = b64toBlob(response.data, 'application/pdf');
        download(blob, `AkselResume-${id}.pdf`, 'application/pdf');
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingTop: '10%' }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomButton
          onClick={() => {
            window.open('', '_parent', '');
            window.close();
          }}
        >
          Close Tab
        </CustomButton>
      )}
    </div>
  );
};

export default DownloadCV;
