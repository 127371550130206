import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));

const CustomInput = ({
  label,
  labelProps,
  name,
  style,
  validation,
  helperText,
  ...rest
}) => {
  const classes = useStyles();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography htmlFor={name} variant='subHeader16' {...labelProps}>
        {label}
      </Typography>
      <TextField
        {...register(name, validation)}
        name={name}
        id={name}
        size='small'
        margin='normal'
        variant='outlined'
        className={classes.root}
        style={{ marginTop: '0.4rem', borderRadius: '8px', ...style }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        error={errors[name]}
        helperText={errors[name] ? errors[name].message : helperText}
        {...rest}
      />
    </Box>
  );
};

export default CustomInput;
