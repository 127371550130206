import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import serviceLineTemplate from 'src/templates/faqLine';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '16px',
  },
}));

export default function ServicesAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {serviceLineTemplate.map((d) => (
        <Accordion key={d.title}>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography variant='p'>{d.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='p'>{d.desc}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
