import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import reactGA from 'react-ga';

import { authGoogle, registerWithEmail } from '../redux/actions';

import CustomButton from '../components/button';
import CustomInput from '../components/forms/CustomInput';
import PasswordInput from '../components/forms/PasswordInput';

import GoogleIcon from '../icons/Google';
import { FormProvider, useForm } from 'react-hook-form';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed
// reactGA.pageview('/register'); // Main page

const Register = ({ isAuthenticated, dataUser }) => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onTouched',
  });
  const { getValues, handleSubmit } = methods;

  const handleRegister = async (data) => {
    try {
      await registerWithEmail({
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
      });

      navigate('/profile/onboarding', { replace: true });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      localStorage.setItem('redirection', 'true');
      await authGoogle();

      reactGA.event({
        category: 'App',
        action: 'User Sign In',
        label: dataUser.uid,
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register - Aksel</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          width: '90%',
          maxWidth: '500px',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)',
          justifyContent: 'center',
          m: '0 auto',
          p: '100px 0',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant='header'
            color='#1D2D5E'
            sx={{ fontWeight: 'bold' }}
          >
            Mulai Perjalanan Kariermu Bersama Aksel
          </Typography>
          <Typography variant='pD' color='#BDBDBD' sx={{ mt: '16px' }}>
            Mulai Sekarang!
          </Typography>
          {error && (
            <Alert severity='error' sx={{ mt: '16px' }}>
              {error}
            </Alert>
          )}
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleRegister)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '32px',
              }}
            >
              <CustomInput
                name='name'
                label='Nama Lengkap'
                placeholder='Masukkan nama lengkap kamu'
                validation={{ required: 'Nama Lengkap harus diisi' }}
                style={{ marginTop: '16px' }}
              />
              <CustomInput
                type='email'
                name='email'
                label='Alamat Email'
                placeholder='Masukkan alamat email yang aktif'
                helperText='Pastikan alamat email ini dapat diakses'
                validation={{
                  required: 'Email harus diisi',
                  pattern: {
                    value: '/^([w.%+-]+)@([w-]+.)+([w]{2,})$/i',
                    message: 'Format email tidak valid',
                  },
                }}
                style={{ marginTop: '16px' }}
              />
              <CustomInput
                type='email'
                name='confirmEmail'
                label='Konfirmasi Alamat Email'
                placeholder='Masukkan alamat email yang aktif'
                validation={{
                  required: 'Konfirmasi Alamat Email harus diisi',
                  validate: {
                    confirmEmail: (v) =>
                      v === getValues('email') || 'Alamat email tidak cocok',
                  },
                }}
                style={{ marginTop: '16px' }}
              />
              <CustomInput
                name='phone'
                label='Nomor Telepon'
                placeholder='Contoh : +628123456789'
                validation={{
                  required: 'Nomor Telepon harus diisi',
                  pattern: {
                    value: /^(\+)/,
                    message:
                      'Nomor telepon harus diawali dengan kode negara. Contoh: +628123456789',
                  },
                }}
                style={{ marginTop: '16px' }}
              />
              <PasswordInput
                name='password'
                label='Password'
                placeholder='Masukkan kata sandi'
                helperText='Minimal 6 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka'
                validation={{
                  required: 'Password harus diisi',
                  pattern: {
                    value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/i',
                    message:
                      'Minimal 6 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka',
                  },
                }}
                style={{ marginTop: '16px' }}
              />
              <CustomButton type='submit'>Daftar</CustomButton>
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      borderTop: '1px solid #BDBDBD',
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='p'
                    color='#BDBDBD'
                    sx={{ padding: '0 10px', background: 'white' }}
                  >
                    Atau
                  </Typography>
                </Box>
              </Box>
              <CustomButton
                type='buton'
                startIcon={<GoogleIcon />}
                onClick={handleGoogleLogin}
              >
                Lanjutkan dengan Google
              </CustomButton>
              <Typography variant='p' textAlign='center'>
                Sudah memiliki akun Aksel?{' '}
                <Link to='/login'>
                  <Typography
                    component='span'
                    variant='pBold'
                    color='#1D2D5E'
                    sx={{ display: 'inline' }}
                  >
                    Masuk disini!
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </form>
        </FormProvider>
        <Box sx={{ mt: '39px' }}>
          <Typography
            color='textSecondary'
            gutterBottom
            variant='p'
            align='center'
          >
            Dengan klik Lanjutkan, kamu menyetujui <br />
            <b>
              <a
                href='/term-and-condition'
                style={{ color: '#6b778c' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/term-and-condition', { replace: true });
                }}
              >
                Syarat dan Ketentuan Layanan
              </a>{' '}
              &{' '}
              <a
                href='/privacy-policy'
                style={{ color: '#6b778c' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/privacy-policy', { replace: true });
                }}
              >
                Kebijakan Privasi Aksel
              </a>
            </b>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  datauser: state.auth.dataUser,
});
export default connect(mapStateToProps)(Register);
