import { Autocomplete, Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormContext } from 'react-hook-form';
import regions from 'src/templates/regions';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));

const DomicileInput = () => {
  const classes = useStyles();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography variant='subHeader16'>Domisili</Typography>
      <Autocomplete
        id='domicile'
        options={regions}
        autoHighlight
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...register('domicile', {
                required: 'Domisili harus diisi',
              })}
              name='domicile'
              id='domicile'
              size='small'
              margin='normal'
              variant='outlined'
              placeholder='Pilih domisili'
              classNames={classes.root}
              style={{ marginTop: '0.4rem', borderRadius: '8px' }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              error={errors.domicile}
              helperText={errors.domicile && errors.domicile.message}
            />
          );
        }}
      />
    </Box>
  );
};

export default DomicileInput;
