import { useState } from 'react';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import {
  Box,
  DialogActions,
  Rating,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import {
  Rating1Active,
  Rating1Icon,
  Rating2Active,
  Rating2Icon,
  Rating3Active,
  Rating3Icon,
  Rating4Active,
  Rating4Icon,
  Rating5Active,
  Rating5Icon,
} from 'src/assets/icons/Landing';

import CustomButton from 'src/components/button';
import { closeGeneralRating } from 'src/redux/actions/rating-actions';

const customIcons = {
  1: {
    icon: <Rating1Icon />,
    active: <Rating1Active />,
    label: 'Sangat buruk!',
  },
  2: {
    icon: <Rating2Icon />,
    active: <Rating2Active />,
    label: 'Buruk!',
  },
  3: {
    icon: <Rating3Icon />,
    active: <Rating3Active />,
    label: 'Baik!',
  },
  4: {
    icon: <Rating4Icon />,
    active: <Rating4Active />,
    label: 'Keren nih!',
  },
  5: {
    icon: <Rating5Icon />,
    active: <Rating5Active />,
    label: 'Luar biasa!',
  },
};

function IconContainer(props) {
  const { value, className, ...other } = props;
  const isDesktop = useMediaQuery('(min-width:1280px)');

  return (
    <span {...other} style={{ padding: isDesktop ? '0 16px' : '0 8px' }}>
      {className.includes('MuiRating-iconFilled')
        ? customIcons[value].active
        : customIcons[value].icon}
    </span>
  );
}

const GeneralRating = ({ onClose }) => {
  const [value, setValue] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const isDesktop = useMediaQuery('(min-width:1280px)');

  const handleSubmit = async () => {
    const feedbackRef = firebase.database().ref(`feedbacks/${user.uid}`);
    let oldValues;

    await feedbackRef.once('value', (snapshot) => {
      oldValues = snapshot.val() || [];
    });

    await feedbackRef.set(
      [
        ...oldValues,
        {
          ratingId: uuid(),
          rating: value,
          type: 'general',
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        },
      ],
      (err) => {
        if (err) {
          console.error(err);
        }
      },
    );

    closeGeneralRating();
  };

  return (
    <Box style={{ margin: '32px 0' }}>
      <Typography
        variant='sectionHeader'
        sx={{ textAlign: 'center', margin: isDesktop ? '0 48px' : '0 24px' }}
      >
        Bagaimana pengalaman kamu menggunakan Aksel?
      </Typography>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop={3}
      >
        <Rating
          name='highlight-selected-only'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          IconContainerComponent={IconContainer}
          highlightSelectedOnly
        />
        {value !== null && (
          <Typography mt={3}>{customIcons[value].label}</Typography>
        )}
      </Box>
      <DialogActions
        style={{
          justifyContent: 'center',
          marginTop: '24px',
          gap: '12px',
        }}
      >
        <CustomButton
          variant='outlined'
          color='secondary'
          onClick={onClose}
          sx={{
            padding: '6px 0',
            width: '240px',
            borderColor: '#fff',
          }}
        >
          Ingatkan lagi nanti
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          sx={{ padding: '6px 0', width: '240px' }}
        >
          Kirim
        </CustomButton>
      </DialogActions>
    </Box>
  );
};

export default GeneralRating;
