import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';
// import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import BookingDetail from 'src/pages/BookingDetail';
import { bookDetail } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  bookingCard: {
    width: '100%',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    marginBottom: 5,
  },
  list: {
    width: '100vw',
    height: '100vh',
  },
  '@global': {
    '.MuiDrawer-root': {
      zIndex: '99999 !important',
    },
  },
});

const BookingCardMentor = ({ booking, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const date = new Date(booking.createdAt);
  const sevenDaysDate = date.setDate(date.getDate() + 7);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const sevenDaysDateString = new Date(sevenDaysDate).toLocaleString(
    'id-ID',
    options,
  );

  const handleToDetailBooking = (booking) => {
    bookDetail(booking);
    navigate('/app/booking/detail', { replace: false });
  };

  const paymentServer =
    process.env.REACT_APP_DEPLOYED === 'dev'
      ? 'https://aksel-payment-staging.herokuapp.com'
      : 'https://aksel-payment.herokuapp.com';

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      {...rest}
    >
      <CardActionArea onClick={() => handleToDetailBooking(booking)}>
        <Typography
          variant='h6'
          component='h6'
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            pt: '10px',
            pl: '16px',
          }}
        >
          {booking.bookTime * 60} menit mentoring
        </Typography>
        <CardContent className={classes.bookingCard}>
          <Avatar
            src={booking.photoUrl}
            sx={{
              height: 60,
              width: 60,
              mr: 1,
              display: 'inline-block',
            }}
          />
          <div
            style={{
              display: 'inline-block',
              margin: '10px 0',
              verticalAlign: 'top',
            }}
          >
            <h4 className={classes.heading}>{booking.name}</h4>
            <Typography variant='h6' component='h6'>
              {booking.title}
            </Typography>
          </div>
        </CardContent>
        <Typography gutterBottom variant='p' component='p' sx={{ pl: '16px' }}>
          Mentoring akan dilakukan sebelum {sevenDaysDateString}
        </Typography>
      </CardActionArea>
      <CardActions>
        <Button
          color='primary'
          variant='contained'
          disableElevation
          onClick={() => handleToDetailBooking(booking)}
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            marginRight: 1,
          }}
        >
          Detail Booking
        </Button>
        <Button
          color='primary'
          variant='contained'
          disableElevation
          target='_blank'
          href={`${paymentServer}/?order_id=${booking.bookId}`}
          sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            textTransform: 'capitalize',
          }}
        >
          Tanda Terima
        </Button>
      </CardActions>
      <Drawer anchor={'left'} open={open} onClose={handleOpen}>
        <BookingDetail handleClose={handleOpen} />
      </Drawer>
    </Card>
  );
};

BookingCardMentor.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default BookingCardMentor;
