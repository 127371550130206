import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isLoading,
  ...props
}) => {
  const navigate = useNavigate();
  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.uid) {
      const userRef = firebase.database().ref();

      userRef
        .child('users')
        .child(user?.uid)
        .get()
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            // const userData = snapshot.val();
            // TODO: onboarding disable dulu
            // if (isAuthenticated && !userData.isMandatoryField) {
            //   navigate('/profile/onboarding', { replace: true });
            // }
          } else {
            // regist first to db
            await firebase.database().ref(`users/${user.uid}`).set({
              displayName: user.displayName,
              email: user.email,
              isAnonymous: user.isAnonymous,
              uid: user.uid,
            });
            // TODO: onboarding disable dulu
            // navigate('/profile/onboarding', { replace: true });
            // // eslint-disable-next-line no-console
            // console.error('No data available');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, [isAuthenticated, navigate, user]);

  if (!isAuthenticated) {
    navigate('/login', { replace: true });
  }

  return <Component {...props} />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: localStorage.getItem('user')
    ? true
    : state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps)(PrivateRoute);
