import {
  CLOSE_ATS_RATING,
  CLOSE_CV_RATING,
  CLOSE_GENERAL_RATING,
  OPEN_ATS_RATING,
  OPEN_CV_RATING,
  OPEN_GENERAL_RATING,
} from '../events';

const initialState = {
  showATSRating: false,
  showCVRating: false,
  showGeneralRating: false,
};

const cv = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_ATS_RATING: {
      return { ...state, showATSRating: false };
    }
    case CLOSE_CV_RATING: {
      return { ...state, showCVRating: false };
    }
    case CLOSE_GENERAL_RATING: {
      return { ...state, showGeneralRating: false };
    }
    case OPEN_ATS_RATING: {
      return { ...state, showATSRating: true };
    }
    case OPEN_CV_RATING: {
      return { ...state, showCVRating: true };
    }
    case OPEN_GENERAL_RATING: {
      return { ...state, showGeneralRating: true };
    }
    default: {
      return state;
    }
  }
};

export default cv;
