import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));

const PasswordInput = ({
  label,
  labelProps,
  name,
  style,
  validation,
  helperText,
  ...rest
}) => {
  const classes = useStyles();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword((prev) => !prev);

  return (
    <Box>
      <Typography htmlFor={name} variant='subHeader16' {...labelProps}>
        {label}
      </Typography>
      <TextField
        {...register(name, validation)}
        type={showPassword ? 'text' : 'password'}
        name={name}
        id={name}
        size='small'
        margin='normal'
        variant='outlined'
        className={classes.root}
        style={{ marginTop: '0.4rem', borderRadius: '8px', ...style }}
        InputProps={{
          endAdornment: (
            <IconButton
              size='small'
              aria-label='toggle password visibility'
              onClick={togglePassword}
              edge='end'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors[name]}
        helperText={errors[name] ? errors[name].message : helperText}
        fullWidth
        {...rest}
      />
    </Box>
  );
};

export default PasswordInput;
