import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { config } from '../firebase.config';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default class AuthClient {
  client;

  constructor(client) {
    this.client = client;
  }

  googleAuth = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const data = await firebase.auth().signInWithPopup(provider);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
      return error;
    }
  };

  logout = () => {
    firebase.auth().signOut();
    localStorage.removeItem('user');
    localStorage.removeItem('assessmentResult');
    sessionStorage.clear();
  };

  register = async (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user.uid;

    try {
      return await firebase.database().ref(`users/${userId}`).set(data);
    } catch (error) {
      return error.message;
    }
  };
}
