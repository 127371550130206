import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { HamburgerMenu } from './hamburgerMenu';
import { connect } from 'react-redux';
// import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { NavLink as NavRouterLink } from "react-router-dom";

const DashboardNavbar = ({
  onMobileNavOpen,
  isAuthenticated,
  title,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.pathname === '/app/home') {
      navigate('/', { replace: true });
    } else if (location.pathname === '/app/booking') {
      navigate('/app/home', { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      elevation={0}
      {...rest}
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Toolbar
        sx={{ height: 48, display: 'flex', justifyContent: 'space-between' }}
      >
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='sectionHeader' style={{ color: '#404040' }}>
          {title}
        </Typography>
        {/* <RouterLink to="/app/home">
          <Logo />
        </RouterLink> */}
        {/* {isAuthenticated ? (
          <Button
            variant="outlined"
            startIcon={<PersonRoundedIcon />}
            to="/app/profile"
            component={NavRouterLink}
          >
            <Typography
              variant="p"
              component="p"
              sx={{ fontFamily: "Roboto", textTransform: "capitalize" }}
            >
              Profile & Settings
            </Typography>
          </Button>
        ) : (
          )} */}
        {window.location.pathname === '/app/home' && <HamburgerMenu />}
        {window.location.pathname !== '/app/home' && (
          <div style={{ width: '24px' }}></div>
        )}
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  title: state.app.navbar.title,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(DashboardNavbar);
