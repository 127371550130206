import firebase from 'firebase/app';

import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import OnboardingForm from 'src/components/account/OnboardingForm';
import CustomButton from 'src/components/button';
import StudentForm from 'src/components/account/StudentForm';
import JobSeekerForm from 'src/components/account/JobSeekerForm';
import EmployedForm from 'src/components/account/EmployedForm';
import { useNavigate } from 'react-router';

const jobType = ['Magang', 'Part-time', 'Full-time', 'Kontrak', 'Freelance'];

const ProfileOnboarding = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [cvUrl, setCvUrl] = useState(null);

  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      wantedJobType: [],
    },
  });
  const { control, handleSubmit, watch } = methods;
  const status = watch('status');

  const renderedComponent = () => {
    switch (status) {
      case 'Pelajar Aktif':
        return <StudentForm />;
      case 'Mencari Pekerjaan':
        return <JobSeekerForm control={control} jobType={jobType} />;
      case 'Sudah Bekerja/Memiliki Usaha':
        return <EmployedForm jobType={jobType} />;
      default:
        return null;
    }
  };

  const handleSave = (data) => {
    firebase
      .database()
      .ref(`users/${user.uid}`)
      .update({
        ...data,
        isMandatoryField: true,
      });

    navigate('/', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Account Onboarding - Aksel</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          width: '90%',
          maxWidth: '500px',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)',
          justifyContent: 'center',
          m: '0 auto',
          p: '60px 0',
        }}
      >
        <Typography
          variant='header'
          color='#1D2D5E'
          sx={{ fontWeight: 'bold' }}
        >
          Halo {user.displayName}!
        </Typography>
        <Typography
          variant='header'
          color='#1D2D5E'
          sx={{ fontWeight: 'bold' }}
        >
          Lengkapi Profilmu Yuk!
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '32px',
              }}
            >
              <OnboardingForm
                cvUrl={cvUrl}
                renderedComponent={renderedComponent}
                setCvUrl={setCvUrl}
              />
            </Box>
            <CustomButton type='submit' sx={{ mt: 3 }} fullWidth>
              Simpan
            </CustomButton>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default ProfileOnboarding;
