import React from 'react';
import { Container, Typography, Grid, Button } from '@material-ui/core';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import { useNavigate } from 'react-router-dom';

import landingMentors from 'src/templates/landingMentors';

const Mentors = ({ setVideoOpen, classes }) => {
  const navigate = useNavigate();

  return (
    <Container style={{ textAlign: 'initial' }}>
      <Grid display='flex' justifyContent='space-between' marginTop='27px'>
        <Typography variant='header'>Mentor Aksel</Typography>
        <Typography
          variant='button'
          sx={{
            textTransform: 'none',
            color: '#1D2D5E',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/app/mentoring', { replace: false })}
        >
          See All
        </Typography>
      </Grid>
      <Typography
        variant='p'
        style={{ marginTop: '4px', marginBottom: '30px' }}
      >
        Pilih Mentor dan Konsultasikan <br /> Perjalanan Kariermu !
      </Typography>
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          marginBottom: '48px',
        }}
      >
        {landingMentors.map((mentor) => (
          <div className={classes.containerVideo} key={mentor.id}>
            <Button
              size='small'
              variant='outlined'
              sx={{
                float: 'right',
                textTransform: 'none',
                position: 'absolute',
                top: 20,
                left: 24,
                backgroundColor: '#fff',
                zIndex: 10,
                borderRadius: 24,
                borderColor: '#fff',
                color: '#252525',
              }}
              onClick={() =>
                setVideoOpen({
                  modal: true,
                  url: mentor.videoUrl,
                  title: mentor.name,
                })
              }
            >
              <PlayCircleFilled sx={{ mr: 1 }} />
              <Typography variant='sm'>Video Perkenalan</Typography>
            </Button>
            <img
              src={mentor.photoUrl}
              alt={mentor.name}
              className={classes.video}
            />
            <div className={classes.videoTitle}>
              <Typography variant='sm'>{mentor.title}</Typography>
              <Typography variant='subHeader16'>{mentor.name}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Mentors;
