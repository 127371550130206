import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import reactGA from 'react-ga';
import { setNavbarTitle } from 'src/redux/actions';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});
// reactGA.pageview('/account'); // Account page

const Account = () => {
  setNavbarTitle('Edit Profile');
  return (
    <>
      <Helmet>
        <title>Account - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={3}>
            <Grid
              item
              // lg={8}
              // md={6}
              xs={12}
            >
              <AccountProfile />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
