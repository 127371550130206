export const cvReviewPackage = [
  {
    id: '7-days',
    title: '7 Hari',
    time: 7,
    price: 60000,
  },
  {
    id: '3-days',
    title: '3 Hari',
    time: 3,
    price: 80000,
  },
];
