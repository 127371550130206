import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';
import { setNavbarTitle } from 'src/redux/actions';

const MaintenancePage = () => {
  setNavbarTitle('Aksel');
  return (
    <>
      <Helmet>
        <title>Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='md'>
          <Typography align='center' color='textPrimary' variant='h1'>
            MAINTENANCE PAGE
          </Typography>
          <Typography align='center' color='textPrimary' variant='subtitle2'>
            During our scheduled maintenance, certain website features may be
            temporarily unavailable.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt='Under development'
              src='/static/images/undraw_newspaper_re_syf5.svg'
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560,
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MaintenancePage;
