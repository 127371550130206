import { useState } from 'react';
import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useNavigate } from 'react-router-dom';

const Details = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    product: false,
    company: false,
  });

  const handleClick = (type) => {
    setOpen({ [type]: !open[type] });
  };

  return (
    <>
      {['product', 'company'].map((idx) => (
        <List key={idx} component='nav' aria-labelledby='list'>
          <ListItem button onClick={() => handleClick(idx)}>
            <ListItemText
              primary={
                <Typography variant='subHeader16'>
                  {idx[0].toUpperCase() + idx.slice(1)}
                </Typography>
              }
            />
            {open[idx] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[idx]} timeout='auto' unmountOnExit>
            {idx === 'product' && (
              <>
                <List
                  onClick={() => navigate('/app/home', { replace: false })}
                  component='div'
                  disablePadding
                >
                  <ListItem button>
                    <Typography variant='p'>Career Mentoring</Typography>
                  </ListItem>
                </List>
                {/* <List
                  onClick={() =>
                    window.open('https://bit.ly/CVreviewAksel', '_blank')
                  }
                  component='div'
                  disablePadding
                >
                  <ListItem button>
                    <Typography variant='p'>CV Review</Typography>
                  </ListItem>
                </List> */}
                <List
                  onClick={() => navigate('/app/cv/dashboard')}
                  component='div'
                  disablePadding
                >
                  <ListItem button>
                    <Typography variant='p'>
                      {'CV Maker & CV Review'}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  onClick={() => navigate('/app/cv/dashboard?tab=ats')}
                  component='div'
                  disablePadding
                >
                  <ListItem button>
                    <Typography variant='p'>ATS Checker</Typography>
                  </ListItem>
                </List>
                {/* <List
                  onClick={() =>
                    window.open("https://links.aksel.co.id/", "_blank")
                  }
                  component="div"
                  disablePadding
                >
                  <ListItem button>
                    <Typography variant="p">Event</Typography>
                  </ListItem>
                </List> */}
              </>
            )}

            {idx === 'company' && (
              <>
                {/* <List
                  component="div"
                  disablePadding
                  onClick={() =>
                    navigate("/about", { replace: false })
                  }
                >
                  <ListItem button>
                    <Typography variant="p">Tentang Aksel</Typography>
                  </ListItem>
                </List> */}
                <List component='div' disablePadding>
                  <ListItem
                    button
                    component='a'
                    target='_blank'
                    rel='noreferrer'
                    href='https://akselofficial.medium.com/'
                  >
                    <Typography variant='p'>Blog</Typography>
                  </ListItem>
                </List>
                <List
                  component='div'
                  disablePadding
                  onClick={() =>
                    navigate('/frequently-asked-question', { replace: false })
                  }
                >
                  <ListItem button>
                    <Typography variant='p'>FAQ</Typography>
                  </ListItem>
                </List>
                <List
                  component='div'
                  disablePadding
                  onClick={() =>
                    navigate('/term-and-condition', { replace: false })
                  }
                >
                  <ListItem button>
                    <Typography variant='p'>Syarat & Ketentuan</Typography>
                  </ListItem>
                </List>
                <List
                  component='div'
                  disablePadding
                  onClick={() =>
                    navigate('/privacy-policy', { replace: false })
                  }
                >
                  <ListItem button>
                    <Typography variant='p'>Kebijakan Privasi</Typography>
                  </ListItem>
                </List>
              </>
            )}
          </Collapse>
        </List>
      ))}
      <Container>
        <Divider />
      </Container>
    </>
  );
};

export default Details;
