import CustomInput from '../forms/CustomInput';

const StudentForm = () => {
  return (
    <CustomInput
      name='college'
      label='Universitas/Sekolah'
      placeholder='Masukkan nama universitas/sekolah'
      validation={{ required: 'Universitas/Sekolah harus diisi' }}
      style={{ marginTop: '16px' }}
    />
  );
};

export default StudentForm;
