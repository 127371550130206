import { useState } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

import { useStyles } from './landingStyles';
import Testimoni from 'src/components/landing/Testimoni';
import CardPersuasive from 'src/components/landing/CardPersuasive';
import Sponsors from 'src/components/landing/Sponsors';
import CustomButton from 'src/components/button';
import EventsDesktop from 'src/components/landing/EventsDesktop';
import Promo from 'src/components/landing/Promo';

import headerAvatar from 'src/assets/img/landing/headerAvatar.svg';
import dollar from 'src/assets/img/landing/dollar.svg';
import suitcase from 'src/assets/img/landing/suitcase.svg';
import lamp from 'src/assets/img/landing/lamp.svg';
import persuasiveBackground from 'src/assets/img/landing/persuasiveBackground.jpg';
import FooterDesktop from 'src/components/landing/FooterDesktop';
import akselProducts from 'src/templates/akselProduct';
import events from 'src/templates/akselEvents';

const whyAksel = [
  {
    id: 1,
    name: 'Harga Terjangkau',
    desc: 'Harga produk Aksel yang bersahabat ga bikin kantong kamu jebol.',
    icon: dollar,
  },
  {
    id: 2,
    name: 'Ekosistem Persiapan Karier',
    desc: 'Temukan individu dan organisasi yang bantu perjalanan karier kamu sukses.',
    icon: suitcase,
  },
  {
    id: 3,
    name: 'One-stop-solution',
    desc: 'Semua kebutuhanmu ada, bantu kamu memulai karier dari 0 hingga gol.',
    icon: lamp,
  },
];
const LandingPageDesktop = ({ handleProduct, setVideoOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dotActive, setDotActive] = useState(0);

  // reset flag
  localStorage.removeItem('to');

  const handleClickEvent = (d) => {
    if (d.pushType === 'app') {
      navigate(d.pushTo, { replace: false });
    } else {
      window.open(d.pushTo, '_blank');
    }
  };

  return (
    <Grid container>
      {/* intro */}
      <div className={classes.headerDesktop}>
        <div className={classes.container1280}>
          <Typography
            variant='header48'
            sx={{ paddingTop: '136px', paddingBottom: '21px', color: '#fff' }}
          >
            Akselerasikan Kariermu <br />
            bersama Aksel
          </Typography>
          <Typography variant='p20' style={{ maxWidth: 510, color: '#fff' }}>
            Kami adalah ekosistem persiapan karier untuk kaum muda Indonesia.
            Dapatkan ekosistem untuk mulai dan akselerasi kariermu!
          </Typography>
          <CustomButton
            sx={{
              marginTop: '28px',
              marginBottom: '28px',
              paddingLeft: '5px !important',
              paddingRight: '5px !important',
            }}
            variant='contained'
            color='secondary'
            to={'/app/home'}
            component={RouterLink}
          >
            Mulai Sekarang
          </CustomButton>
          <div style={{ display: 'flex', marginBottom: '125px' }}>
            <img
              src={headerAvatar}
              alt='avatar'
              style={{ padding: '10.5px 0' }}
            />
            <Divider
              orientation='vertical'
              style={{
                backgroundColor: '#fff',
                height: 'auto',
                borderWidth: '1px',
                marginLeft: '12.4px',
              }}
            />
            <Typography
              variant='p20'
              style={{ margin: 'auto 0 auto 12.4px', color: '#fff' }}
            >
              Ayu, Dimas, dan ratusan ribu orang lainnya telah berhasil
              <br /> mengakselerasikan karier mereka bersama Aksel.
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.container1280}>
        <div
          style={{
            display: 'flex',
            width: '1280px',
            justifyContent: 'space-between',
            padding: '63px 68px',
          }}
        >
          {[
            { title: 'Pengguna Aksel', data: '200.000+' },
            { title: 'Mentor', data: '100+' },
            { title: 'CV Maker', data: '180.000+' },
            { title: 'CV Review', data: '3000+' },
          ].map((usr) => (
            <div
              style={{
                backgroundColor: '#fff',
                boxShadow: '0px 0px 8px rgba(37, 37, 37, 0.1)',
                borderRadius: '14px',
                padding: 24,
                textAlign: 'center',
                minWidth: 250,
              }}
            >
              <Typography
                variant='header'
                style={{ color: '#1D2D5E' }}
                gutterBottom
              >
                {usr.data}
              </Typography>
              <Typography variant='p20' style={{ color: '#252525' }}>
                {usr.title}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          background:
            'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(24, 148, 200, 0.08) 100%), linear-gradient(295.05deg, rgba(255, 255, 255, 0) 34.08%, rgba(24, 148, 200, 0.08) 100%), linear-gradient(180deg, rgba(24, 148, 200, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
          padding: '108px 0',
          width: '100%',
        }}
      >
        <div className={classes.container1280} style={{ padding: '0 64px' }}>
          <div>
            <Typography variant='header32' style={{ color: '#1D2D5E' }}>
              Kamu lagi butuh apa?
            </Typography>
            <Typography
              variant='p20'
              style={{ color: 'rgba(29, 45, 94, 0.9)', marginTop: 20 }}
            >
              Aksel menyediakan produk yang dirancang sesuai dengan kebutuhan
              mahasiswa Indonesia
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '55px',
              padding: '0 72px',
            }}
          >
            {akselProducts.map((product) => (
              <div
                className={classes.cardNoBorder}
                sx={{ mt: '55px' }}
                id={product.id}
              >
                <img src={product.icon} alt={product.title} />
                <Typography
                  variant='header'
                  gutterBottom
                  sx={{ pt: '24px', color: '#1D2D5E' }}
                >
                  {product.title}
                </Typography>
                <Typography
                  variant='pD'
                  sx={{ color: 'rgba(29, 45, 94, 0.6)', marginBottom: '62px' }}
                >
                  {product.body}
                </Typography>
                <Typography
                  variant='pBoldD'
                  sx={{
                    position: 'absolute',
                    bottom: '34px',
                    color: '#1894C8',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleProduct(product)}
                >
                  {product.buttonTitle}
                </Typography>
              </div>
            ))}
          </div>
          {/* TODO: takedown sementara */}
          {/* <div style={{ marginTop: "80px", textAlign: "center" }}>
            <CustomButton
              onClick={() =>
                window.open("https://recommendation.aksel.co.id/", "_blank")
              }
            >
              Rekomendasi Produk Untukmu
            </CustomButton>
          </div> */}
        </div>
      </div>

      <Grid item xs={12}>
        <div
          className={classes.container1280}
          style={{ textAlign: 'center', paddingTop: '125px' }}
        >
          <Typography variant='header32'>Mengapa Aksel?</Typography>
          <Typography variant='p20' sx={{ mt: '20px' }}>
            Aksel akan membantu mencapai karier impianmu.
          </Typography>
          <div
            style={{
              background: 'rgba(91, 192, 235, 0.1)',
              minHeight: '325px',
              marginTop: '56px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '62px 0',
              width: 1280,
              borderRadius: 16,
            }}
          >
            {whyAksel.map((aksel) => (
              <div id={aksel.id} style={{ padding: '0 44px', width: '100%' }}>
                <img
                  src={aksel.icon}
                  alt={aksel.name}
                  style={{ marginBottom: '24px', height: '48px' }}
                />
                <Typography variant='header' gutterBottom>
                  {aksel.name}
                </Typography>
                <Typography
                  variant='p20'
                  style={{ maxWidth: '295px', margin: 'auto' }}
                >
                  {aksel.desc}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        style={!events.length ? { display: 'none' } : { marginTop: '125px' }}
      >
        <EventsDesktop
          classes={classes}
          events={events}
          handleClick={handleClickEvent}
        />
      </Grid>

      <div className={classes.container1280} style={{ marginTop: '125px' }}>
        <Promo />
      </div>

      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
          position: 'relative',
          color: '#fff',
          marginTop: '125px',
        }}
      >
        <Testimoni
          dotActive={dotActive}
          setDotActive={setDotActive}
          isDesktop
        />
      </Grid>

      <div
        className={classes.container1280}
        style={{ padding: '0 273px', marginTop: '125px' }}
      >
        <Sponsors isDesktop />
      </div>

      <Grid
        item
        xs={12}
        style={{
          background: `url(${persuasiveBackground})`,
          backgroundSize: 'cover',
          height: '281px',
          position: 'relative',
          marginTop: '250px',
        }}
      >
        <CardPersuasive
          title='Siap mengakselerasi kariermu?'
          buttonText='Mulai Sekarang'
          buttonColor='secondary'
          linkTo='/login'
          isDesktop
        >
          Tenang, Kamu tidak sendiri Ada Aksel untukmu!
        </CardPersuasive>
      </Grid>

      <div className={classes.container1280}>
        <FooterDesktop />
      </div>
    </Grid>
  );
};

export default LandingPageDesktop;
