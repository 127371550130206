import React from 'react';
import { Typography, CardMedia, Grid, CardContent } from '@material-ui/core';

import { CalenderIcon, ClockIcon } from 'src/assets/icons/Landing';
import CustomButton from '../button';

const EventsDesktop = ({ classes, events = [], handleClick }) => (
  <div
    style={
      !events.length
        ? { display: 'none' }
        : { maxWidth: '1280px', margin: 'auto' }
    }
  >
    <Typography variant='header32'>Event Aksel</Typography>
    <div style={{ display: 'flex', overflowX: 'auto' }}>
      {events.map((event) => (
        <div
          key={event.id}
          style={{
            width: '428px',
            borderRadius: '16px',
            boxShadow: '0px 4px 16px rgba(37, 37, 37, 0.08)',
            margin: '47px 35.5px 28px 0',
          }}
        >
          <CardMedia image={event.photoUrl} className={classes.mediaEvent} />
          <CardContent className={classes.contentEvent}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='pBoldD' color='#252525' gutterBottom>
                {event.title}
              </Typography>
              <div style={{ width: '79px' }}>
                <CustomButton
                  color='secondary'
                  sx={{
                    borderRadius: '32px !important',
                    padding: '3px 0 !important',
                  }}
                  onClick={() => handleClick(event)}
                >
                  Daftar
                </CustomButton>
              </div>
            </div>
            <Grid display='flex' marginBottom='4px'>
              <CalenderIcon />
              <Typography variant='smD' sx={{ ml: '8px', color: '#8D8D8D' }}>
                {event.date}
              </Typography>
            </Grid>
            <Grid display='flex'>
              <ClockIcon />
              <Typography variant='smD' sx={{ ml: '8px', color: '#8D8D8D' }}>
                {event.time}
              </Typography>
            </Grid>
          </CardContent>
        </div>
      ))}
    </div>
  </div>
);

export default EventsDesktop;
