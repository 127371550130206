import promo1 from 'src/assets/img/landing/Promo1.jpeg';
import promo2 from 'src/assets/img/landing/Promo2.jpeg';
import promo3 from 'src/assets/img/landing/Promo3.jpeg';

// pushType: app || external
const banners = [
  {
    id: 1,
    image: promo1,
    pushType: 'external',
    pushTo: 'https://bit.ly/CVreviewAksel',
    alt: 'promo-1',
  },
  {
    id: 2,
    image: promo2,
    pushType: 'app',
    pushTo: '/app/home',
    alt: 'promo-2',
  },
  {
    id: 3,
    image: promo3,
    linkTo: 'https://bit.ly/KLASARegistration',
    pushType: 'app',
    pushTo: '/app/cv/dashboard',
    alt: 'promo-3',
  },
];

export default banners;
