import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import firebase from 'firebase/app';
import { useLocation, useNavigate } from 'react-router';
import { Alert, Box, Container, Grid, Typography } from '@material-ui/core';
import reactGA from 'react-ga';
import CustomButton from 'src/components/button';
import MainLayout from 'src/components/MainLayout';
import CustomInput from 'src/components/forms/CustomInput';
import { FormProvider, useForm } from 'react-hook-form';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
});

const Icon = () => (
  <svg
    width='96'
    height='95'
    viewBox='0 0 96 95'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='48' cy='47.5' r='47.5' fill='#5BC0EB' fill-opacity='0.08' />
    <circle
      cx='47.9375'
      cy='47.4346'
      r='32.6562'
      fill='#5BC0EB'
      fill-opacity='0.1'
    />
    <path
      d='M59.1077 34.5497C55.341 31.5676 49.7743 32.9288 46.9714 35.381C43.8334 38.1241 43.7451 42.4596 45.379 46.0002L34.0585 57.1104C33.5987 57.5624 33.1259 60.7965 33.1493 61.4329L34.6819 62.5031C34.6819 62.5031 37.2769 63.2798 38.4069 62.3966C39.5369 61.5134 40.5292 59.9133 40.4123 59.1937C40.3448 58.7807 40.4331 58.3962 40.7007 58.1339C41.0332 57.8066 41.5631 57.752 42.1008 57.9313C42.5138 58.0689 42.9736 57.952 43.2827 57.6481L43.3918 57.5416C43.8308 57.1312 43.8802 56.6532 43.7945 56.2532C43.662 55.6323 43.888 55.118 44.2361 54.6634C44.5374 54.2686 45.1089 54.1023 45.6076 54.0945C46.5064 54.0815 47.0883 53.8893 47.7247 53.2659L49.382 51.6449C53.1123 53.3074 57.6867 52.5567 60.6559 49.3902C64.464 45.3274 63.4899 38.0202 59.1077 34.5497V34.5497ZM56.8633 43.2129C55.8242 44.2312 54.1409 44.2312 53.1019 43.2129C52.0628 42.1947 52.0628 40.5399 53.1019 39.5217C54.1409 38.5034 55.8242 38.5034 56.8633 39.5217C57.9023 40.5399 57.9023 42.1947 56.8633 43.2129Z'
      fill='#9E740B'
    />
    <path
      d='M59.1012 34.5494C55.2359 30.6841 48.9704 30.6841 45.1051 34.5494C42.1463 37.5081 41.4554 41.8696 43.0243 45.4881L33.1403 55.3098C32.9103 55.5393 32.7301 55.8136 32.6108 56.1157C32.4915 56.4178 32.4356 56.7412 32.4467 57.0659L32.561 60.1181C33.1247 60.435 33.1429 61.4351 33.1429 61.4351L35.7145 61.6325C36.3718 61.6533 37.0082 61.4013 37.4706 60.939L38.2862 60.1233C38.7954 59.6142 39.0837 58.8946 38.9564 58.188C38.8759 57.749 38.9642 57.3386 39.2422 57.0607C39.5695 56.7334 40.0812 56.671 40.6085 56.8347C41.0475 56.9723 41.5255 56.884 41.8528 56.5593L41.9645 56.4476C42.4087 56.0242 42.4581 55.5332 42.3723 55.1228C42.2399 54.4838 42.4685 53.9565 42.8165 53.4889C43.1205 53.0811 43.6997 52.9122 44.2011 52.9044C45.1077 52.8914 45.6973 52.694 46.339 52.0524L47.8924 50.5068C51.5628 52.2342 56.0724 51.5822 59.1064 48.5481C62.9666 44.6802 62.9666 38.4147 59.1012 34.5494V34.5494ZM55.5684 41.88C54.519 42.9294 52.8175 42.9294 51.7707 41.88C50.7212 40.8305 50.7212 39.129 51.7707 38.0822C52.8201 37.0327 54.5216 37.0327 55.5684 38.0822C56.6179 39.129 56.6179 40.8305 55.5684 41.88Z'
      fill='#FFCA28'
    />
    <path
      d='M32.5625 60.1182C32.5625 60.1182 32.8846 60.3364 33.1444 61.4352L45.4781 48.8963C45.8807 48.4547 44.0753 48.6833 43.535 49.221L32.5625 60.1182Z'
      fill='#DBA010'
    />
    <path
      d='M46.8032 35.7859C45.7902 36.3366 44.6472 37.8121 44.3329 39.9422C44.0471 41.8748 44.1692 43.2178 44.8186 44.3478C45.5538 45.6232 46.5539 44.9478 46.1512 43.7062C45.8863 42.8879 45.8187 42.5788 45.7824 41.7112C45.7538 41.0332 45.8239 40.35 46.0162 39.7006C46.4941 38.09 47.6033 37.0328 47.928 36.5107C48.3411 35.8431 47.689 35.3002 46.8032 35.7859V35.7859Z'
      fill='#FFF59D'
    />
    <path
      d='M39.6951 51.3195C38.9574 51.9481 39.7211 49.9921 40.2874 49.3322C41.0329 48.4594 42.7993 46.6073 43.1111 46.4489C43.5371 46.2332 43.5111 47.423 43.3371 47.6412C42.7188 48.4049 40.4355 50.6908 39.6951 51.3195Z'
      fill='#FFF59D'
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    width='95'
    height='96'
    viewBox='0 0 95 96'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='47.5' cy='48' r='47.5' fill='#5BC0EB' fill-opacity='0.08' />
    <circle
      cx='47.4336'
      cy='47.9336'
      r='32.6562'
      fill='#5BC0EB'
      fill-opacity='0.1'
    />
    <g clip-path='url(#clip0_6803_8827)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M62.926 44.4629H32.333C31.9659 44.4629 31.668 44.7611 31.668 45.1296V64.0824C31.668 64.4508 31.9659 64.7494 32.333 64.7494H62.926C63.0135 64.7493 63.1001 64.732 63.1809 64.6984C63.2617 64.6648 63.335 64.6157 63.3968 64.5537C63.4586 64.4918 63.5076 64.4183 63.541 64.3374C63.5743 64.2565 63.5914 64.1699 63.5913 64.0824V45.1296C63.5914 45.0421 63.5742 44.9555 63.5409 44.8746C63.5075 44.7938 63.4585 44.7203 63.3967 44.6584C63.3349 44.5965 63.2616 44.5474 63.1808 44.5138C63.1 44.4803 63.0134 44.463 62.926 44.4629Z'
        fill='#D35400'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M32.6641 36.8119V64.0782C32.6641 64.4496 32.962 64.7505 33.3291 64.7505H61.9244C62.0122 64.7501 62.099 64.7323 62.1799 64.6983C62.2608 64.6642 62.3342 64.6146 62.3959 64.5522C62.4576 64.4898 62.5064 64.4159 62.5396 64.3346C62.5727 64.2533 62.5895 64.1663 62.5891 64.0786V32.1622C62.5895 32.0745 62.5727 31.9874 62.5396 31.9062C62.5064 31.8249 62.4576 31.751 62.3959 31.6886C62.3342 31.6262 62.2608 31.5765 62.1799 31.5425C62.099 31.5085 62.0122 31.4907 61.9244 31.4902L37.9854 31.4929L32.6641 36.8119Z'
        fill='white'
      />
      <path
        opacity='0.1'
        d='M44.3008 55.4393L62.5873 44.4668L62.5883 46.7876L47.9676 55.4393H44.3008Z'
        fill='black'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M31.6641 64.0819C31.6641 64.4509 31.9616 64.7498 32.3291 64.7498H62.9191C63.2861 64.7498 63.5841 64.4509 63.5841 64.0819V45.4648L47.6241 55.1073L31.6641 45.4648V64.0819Z'
        fill='#F39C12'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M32.6641 36.8114L37.9844 36.8147V31.5L32.6641 36.8114Z'
        fill='#F1C40F'
      />
      <path
        d='M31.6641 45.4648L31.6814 63.4198L47.6241 55.1073L31.6641 45.4648Z'
        fill='#F1C40F'
      />
      <path
        d='M63.585 45.4648L47.625 55.1073L63.585 63.4198V45.4648Z'
        fill='#E57E22'
      />
      <path
        opacity='0.3'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M63.5841 64.0819V63.4199L47.6241 55.1074L63.3606 64.5777C63.4966 64.4557 63.5841 64.2798 63.5841 64.0819ZM31.6641 64.0819V63.4199L47.6241 55.1074L31.8875 64.5777C31.8174 64.5155 31.7612 64.4392 31.7227 64.3537C31.6842 64.2683 31.6642 64.1756 31.6641 64.0819Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_6803_8827'>
        <rect
          width='33.25'
          height='33.25'
          fill='white'
          transform='translate(31 31.5)'
        />
      </clipPath>
    </defs>
  </svg>
);

const ForgotPassword = () => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm({
    mode: 'onTouched',
  });
  const { getValues, handleSubmit } = methods;

  useEffect(() => {
    if (location.search === '?success=true' && !getValues('email')) {
      navigate('/forgot-password', { replace: true });
    }
  }, [getValues, location, navigate]);

  const handleForgotPassword = async (data) => {
    try {
      await firebase.auth().sendPasswordResetEmail(data.email);
      navigate('/forgot-password?success=true', { replace: true });
    } catch (err) {
      setError(err.message);
    }
  };

  if (location.search === '?success=true') {
    return (
      <MainLayout>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3,
          }}
        >
          <Container
            maxWidth='lg'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '32px 0',
            }}
          >
            <SuccessIcon />
            <Typography
              variant='header'
              sx={{ fontWeight: 700, color: '#1D2D5E', mt: '32px' }}
            >
              Periksa Email Kamu
            </Typography>
            <Typography variant='p20' sx={{ mt: '16px' }}>
              Kami telah mengirimkan instruksi pengaturan ulang kata sandi ke:
            </Typography>
            <Typography variant='p20Bold' sx={{ mt: '16px' }}>
              {getValues('email')}
            </Typography>
            <Typography variant='p' textAlign='center' sx={{ mt: '24px' }}>
              Tidak menerima email?{' '}
              <Typography
                component='span'
                variant='pBold'
                color='#1D2D5E'
                onClick={handleForgotPassword}
                sx={{ display: 'inline', cursor: 'pointer' }}
              >
                Kirim ulang
              </Typography>
            </Typography>
          </Container>
        </Box>
      </MainLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Account - Aksel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container
          maxWidth='lg'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 0',
          }}
        >
          <Icon />
          <Box sx={{ marginTop: '32px', textAlign: 'center' }}>
            <Typography
              variant='header'
              color='#1D2D5E'
              sx={{ fontWeight: 'bold' }}
            >
              Lupa Password
            </Typography>
            <Typography variant='p20' sx={{ mt: '16px' }}>
              Masukkan alamat email yang kamu gunakan saat bergabung dan kami
              akan mengirimkan instruksi untuk mengatur ulang kata sandi kamu.
            </Typography>
            {error && (
              <Alert severity='error' sx={{ mt: '16px' }}>
                {error}
              </Alert>
            )}
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleForgotPassword)}>
              <Grid container spacing={3} sx={{ marginTop: '32px' }}>
                <Grid item xs={12}>
                  <CustomInput
                    type='email'
                    name='email'
                    label='Email Kamu'
                    placeholder='Masukkan alamat email yang terdaftar'
                    validation={{
                      required: 'Email harus diisi',
                      pattern: {
                        value: '/^([w.%+-]+)@([w-]+.)+([w]{2,})$/i',
                        message: 'Format email tidak valid',
                      },
                    }}
                    style={{ marginTop: '16px' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomButton type='submit' fullWidth>
                    Kirim
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
