export const config =
  process.env.REACT_APP_DEPLOYED === 'dev'
    ? {
        apiKey: 'AIzaSyCScxMbXqn1PPB3nVTEJltjk4K_PB0N8hs',
        authDomain: 'aksel-staging.firebaseapp.com',
        databaseURL: 'https://aksel-staging-default-rtdb.firebaseio.com',
        projectId: 'aksel-staging',
        storageBucket: 'aksel-staging.appspot.com',
        messagingSenderId: '14641443408',
        appId: '1:14641443408:web:2abef67f4a08fef90e5755',
      }
    : {
        apiKey: 'AIzaSyCj14mdTcU89kIqy8jbMvW7gj5eWV0eJXo',
        authDomain: 'aksel-indonesia.firebaseapp.com',
        databaseURL: 'https://aksel-indonesia-default-rtdb.firebaseio.com',
        projectId: 'aksel-indonesia',
        storageBucket: 'aksel-indonesia.appspot.com',
        messagingSenderId: '62298238844',
        appId: '1:62298238844:web:b0880f631d97f08fd0bef9',
        measurementId: 'G-8VB5R1E2ZD',
      };
