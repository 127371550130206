import {
  CV_BUILDER,
  GET_CV_DETAIL,
  CHANGE_CV_DETAIL,
  CV_STEP,
  ATS_PARSE,
} from '../events';

const initialState = {
  cvDetail: {},
  cvBuilder: {},
  cvStep: 0,
  dataAts: {},
};

const cv = (state = initialState, action) => {
  switch (action.type) {
    case GET_CV_DETAIL: {
      return { ...state, cvDetail: { ...action.payload } };
    }
    case CHANGE_CV_DETAIL: {
      return { ...state, cvDetail: { ...action.payload } };
    }
    case CV_BUILDER: {
      return { ...state, cvBuilder: { ...action.payload } };
    }
    case CV_STEP: {
      return { ...state, cvStep: action.payload };
    }
    case ATS_PARSE: {
      return { ...state, atsParse: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default cv;
