import {
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#5BC0EB',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));

const CustomSelect = ({
  children,
  helperText,
  label,
  labelProps,
  name,
  options,
  placeholder,
  style,
  validation,
  ...rest
}) => {
  const classes = useStyles();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl error={errors[name]}>
      <Typography variant='subHeader16' {...labelProps}>
        {label}
      </Typography>
      <Select
        {...register(name, validation)}
        label='Test'
        displayEmpty
        input={<OutlinedInput size='small' margin='normal' />}
        renderValue={(selected) => selected || placeholder}
        className={classes.root}
        style={{ marginTop: '0.4rem', borderRadius: '8px', ...style }}
        fullWidth
        {...rest}
      >
        {options.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {errors[name] ? errors[name].message : helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
