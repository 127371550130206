import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Box, CircularProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import reactGA from 'react-ga';

import { authGoogle, loginWithEmail } from '../redux/actions';

import CustomButton from '../components/button';
import CustomInput from '../components/forms/CustomInput';
import PasswordInput from '../components/forms/PasswordInput';

import GoogleIcon from '../icons/Google';
import { FormProvider, useForm } from 'react-hook-form';

reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  standardImplementation: true,
}); // TBD until the service is deployed
// reactGA.pageview('/login'); // Main page

const Login = ({ isAuthenticated, dataUser }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const methods = useForm({
    mode: 'onTouched',
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (localStorage.getItem('redirection') === 'true') {
      setIsLoading(true);
    }

    if (isAuthenticated && localStorage.getItem('to') === 'cvMaker') {
      navigate('/app/cv/dashboard', { replace: true });
    } else if (isAuthenticated && localStorage.getItem('to') === 'atsc') {
      navigate('/app/cv/dashboard?tab=ats', { replace: true });
    } else if (isAuthenticated && localStorage.getItem('to') === 'mentoring') {
      navigate('/app/book');
    } else if (isAuthenticated) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleGoogleLogin = async () => {
    try {
      localStorage.setItem('redirection', 'true');
      await authGoogle();

      reactGA.event({
        category: 'App',
        action: 'User Sign In',
        label: dataUser.uid,
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEmailLogin = async (data) => {
    try {
      await loginWithEmail({
        email: data.email,
        password: data.password,
      });
    } catch (err) {
      setError(err.message);
    }
  };

  if (isAuthenticated && localStorage.getItem('to') === 'cvMaker') {
    navigate('/app/cv/dashboard', { replace: true });
  } else if (isAuthenticated && localStorage.getItem('to') === 'atsc') {
    navigate('/app/cv/dashboard?tab=ats', { replace: true });
  } else if (isAuthenticated) {
    navigate('/', { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>Login - Aksel</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          width: '90%',
          maxWidth: '500px',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)',
          justifyContent: 'center',
          m: '0 auto',
          p: '60px 0',
        }}
      >
        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={30} sx={{ mb: 2 }} />
            <Typography color='textSecondary' gutterBottom variant='body2'>
              Memuat aplikasi
            </Typography>
          </div>
        ) : (
          <>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                variant='header'
                color='#1D2D5E'
                sx={{ fontWeight: 'bold' }}
              >
                Mulai Perjalanan Kariermu Bersama Aksel
              </Typography>
              <Typography variant='pD' color='#BDBDBD' sx={{ mt: '16px' }}>
                Mulai Sekarang!
              </Typography>
              {error && (
                <Alert severity='error' sx={{ mt: '16px' }}>
                  {error}
                </Alert>
              )}
            </Box>

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleEmailLogin)}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    marginTop: '32px',
                  }}
                >
                  <CustomInput
                    type='email'
                    name='email'
                    label='Alamat Email'
                    placeholder='Masukkan alamat email yang terdaftar'
                    validation={{
                      required: 'Email harus diisi',
                      pattern: {
                        value: '/^([w.%+-]+)@([w-]+.)+([w]{2,})$/i',
                        message: 'Format email tidak valid',
                      },
                    }}
                    style={{ marginTop: '16px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <PasswordInput
                      name='password'
                      label='Password'
                      placeholder='Masukkan kata sandi'
                      validation={{
                        required: 'Password harus diisi',
                        pattern: {
                          value:
                            '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/i',
                          message:
                            'Minimal 6 karakter dan mengandung kombinasi huruf kecil, huruf besar, dan angka',
                        },
                      }}
                      style={{ marginTop: '16px' }}
                    />
                    <Link
                      to='/forgot-password'
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <Typography variant='pBold' sx={{ color: '#1D2D5E' }}>
                        Lupa Password?
                      </Typography>
                    </Link>
                  </Box>
                  <CustomButton type='submit'>Masuk</CustomButton>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          borderTop: '1px solid #BDBDBD',
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant='p'
                        color='#BDBDBD'
                        sx={{ padding: '0 10px', background: 'white' }}
                      >
                        Atau
                      </Typography>
                    </Box>
                  </Box>
                  <CustomButton
                    type='button'
                    startIcon={<GoogleIcon />}
                    onClick={handleGoogleLogin}
                  >
                    Lanjutkan dengan Google
                  </CustomButton>
                  <Typography variant='p' textAlign='center'>
                    Belum memiliki akun Aksel?{' '}
                    <Link to='/register'>
                      <Typography
                        component='span'
                        variant='pBold'
                        color='#1D2D5E'
                        sx={{ display: 'inline' }}
                      >
                        Daftar disini!
                      </Typography>
                    </Link>
                  </Typography>
                </Box>
              </form>
            </FormProvider>

            <Box sx={{ mt: '39px' }}>
              <Typography
                color='textSecondary'
                gutterBottom
                variant='p'
                align='center'
              >
                Dengan klik Lanjutkan, kamu menyetujui <br />
                <b>
                  <a
                    href='/term-and-condition'
                    style={{ color: '#6b778c' }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/term-and-condition', { replace: true });
                    }}
                  >
                    Syarat dan Ketentuan Layanan
                  </a>{' '}
                  &{' '}
                  <a
                    href='/privacy-policy'
                    style={{ color: '#6b778c' }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/privacy-policy', { replace: true });
                    }}
                  >
                    Kebijakan Privasi Aksel
                  </a>
                </b>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  datauser: state.auth.dataUser,
});

export default connect(mapStateToProps)(Login);
