// import event1 from 'src/assets/img/landing/Event1.png';

// pushType: app || external
const events = [
  // {
  //   id: 1,
  //   photoUrl: event1,
  //   title: 'Manulife Business Academy',
  //   date: '25 Februari - 9 Maret 2022',
  //   time: 'TBA',
  //   pushType: 'external',
  //   pushTo: 'https://bit.ly/RegistrasiAkselxManulife',
  // },
];

export default events;
