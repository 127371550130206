import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { setCvStep } from 'src/redux/actions';

const CvNavbar = ({ cvStep, onMobileNavOpen, title, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.pathname === '/app/cv/dashboard') {
      navigate('/', { replace: true });
    } else if (location.pathname.includes('/app/cv/builder')) {
      if (cvStep === 0) {
        navigate('/app/cv/dashboard', { replace: true });
      } else {
        setCvStep(cvStep - 1);
      }
    } else if (location.pathname.includes('/app/cv/template')) {
      navigate('/app/cv/dashboard', { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      elevation={0}
      {...rest}
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Toolbar
        sx={{ height: 48, display: 'flex', justifyContent: 'space-between' }}
      >
        <IconButton
          onClick={handleBack}
          // component={RouterLink} to='/app/home'
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='sectionHeader' style={{ color: '#404040' }}>
          {title}
        </Typography>
        <div style={{ width: '24px' }}></div>
        {/* <RouterLink to='/app/home'>
          <Logo />
        </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  cvStep: state.cv.cvStep,
  title: state.app.navbar.title,
});

export default connect(mapStateToProps)(CvNavbar);
